/* eslint-disable no-extend-native */

Array.prototype.chunk = function (size: number) {
  const array = this;
  const result: (typeof this)[] = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};
