import {
  useActions,
  useActivityParams,
  useStack,
  useStepActions,
} from "@stackflow/react";
import * as React from "react";

import type {
  TypeActivityName,
  TypeActivityParams,
  TypeUseFlow,
  TypeUseStepFlow,
} from "./stack";

/**
 * `stackflow()` 함수에서 반환받은 `useFlow()` 함수를 그대로 써도 큰 문제는 없지만,
 * 해당 함수를 쓰게 되면 런타임에 Circular Dependency가 발생하게 돼요.
 * 따라서, 자동완성을 위해 타입만 가져다쓰고 런타임은 의존하지 않도록 useActions를 활용해 useFlow를 다시 생성합니다.
 * https://stackflow.so/advanced/fix-use-flow
 */
export const useFlow: TypeUseFlow = () => useActions();
export const useStepFlow = <T extends TypeActivityName>(activityName: T) => {
  const params = useActivityParams() as TypeActivityParams<T>;
  const stepFlow = (useStepActions as TypeUseStepFlow)(activityName);
  return {
    params,
    ...stepFlow,
    stepPush: (paramsToMerge: Partial<TypeActivityParams<T>>) => {
      stepFlow.stepPush({
        ...params,
        ...paramsToMerge,
      });
    },
    stepReplace: (paramsToMerge: Partial<TypeActivityParams<T>>) => {
      stepFlow.stepReplace({
        ...params,
        ...paramsToMerge,
      });
    },
  };
};
export const usePopToRoot = () => {
  const stack = useStack();
  const { pop, replace } = useFlow();

  // stack activities 중에 zIndex가 -1보다 큰 것들의 개수가 현재 떠있는 activity의 개수이고
  // (activity - 1)만큼 pop을 하게되면 root만 남고 모든 것이 pop이 되게 돼요.
  const popCount =
    stack.activities.filter((activity) => activity.zIndex > -1).length - 1;

  return React.useCallback(() => {
    pop(popCount, { animate: false });
    replace(
      "advertisements",
      {
        placementFilter: "ALL",
        statusFilter: "ALL",
      },
      { animate: false },
    );
  }, [pop, popCount, replace]);
};
