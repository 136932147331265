import {
  IconCheckFill,
  IconExpandMoreRegular,
  IconInfoRegular,
} from "@seed-design/icon";
import React from "react";

import { Sheet } from "@/src/components/_shared/bottom-sheet";
import { useBusinessUserStoreShallow } from "@/src/store/business-user";
import { color } from "@/src/styles/tokens";

import * as styles from "./advertiser-selector-bottomsheet.css";

interface AdvertiserSelectorBottomSheetProps {
  isOpen: boolean;
  onClose: () => void;
}

const AdvertiserSelectorBottomSheet = ({
  isOpen,
  onClose,
}: AdvertiserSelectorBottomSheetProps) => {
  const [expanded, setExpanded] = React.useState<boolean>(false);

  const { advertiser, advertisers, setAdvertiser } =
    useBusinessUserStoreShallow((state) => ({
      advertiser: state.advertiser,
      advertisers: state.advertisers,
      setAdvertiser: state.setAdvertiser,
    }));

  const sortedByActive = advertisers?.toSorted((a, b) => {
    if (advertiser?.id === a.id) {
      return -1;
    }
    if (advertiser?.id === b.id) {
      return 1;
    }
    return 0;
  });

  const liteAccounts = sortedByActive?.filter(
    (advertiser) => advertiser.mode === "LITE",
  );
  const proAccounts = sortedByActive?.filter(
    (advertiser) => advertiser.mode === "PRO",
  );

  return (
    <Sheet.Root
      className={styles.sheet}
      onClose={onClose}
      onOutsideClick={onClose}
      open={isOpen}
    >
      <Sheet.Title onClose={onClose}>광고계정 선택</Sheet.Title>
      <article className={styles.div}>
        {liteAccounts?.map((targetAdvertiser, index, original) => {
          const active = advertiser?.id === targetAdvertiser.id;
          return (
            <React.Fragment key={targetAdvertiser.id}>
              <section
                className={styles.item}
                onClick={() => {
                  setAdvertiser(targetAdvertiser);
                  setTimeout(() => {
                    onClose();
                  }, 0);
                }}
              >
                <div className={styles.text}>
                  <span className={styles.name({ active })}>
                    {targetAdvertiser.name}
                  </span>
                  <span className={styles.description}>
                    활성광고 {targetAdvertiser.activeAdCount.toLocaleString()}개
                    &middot; 광고캐시{" "}
                    {(
                      (targetAdvertiser.freeCash.value ?? 0) +
                      (targetAdvertiser.prepaidCash.value ?? 0)
                    ).toLocaleString()}
                    원
                  </span>
                </div>
                {active && (
                  <div>
                    <IconCheckFill
                      color={color["$semantic.primary"]}
                      size={24}
                    />
                  </div>
                )}
              </section>
              {index !== original.length - 1 && <hr className={styles.hr} />}
            </React.Fragment>
          );
        })}
        {proAccounts?.length! > 0 && (
          <>
            <section className={styles.collapseSection({ expanded })}>
              {expanded && (
                <>
                  <div className={styles.proNoticeArea}>
                    <IconInfoRegular className={styles.IconInfoRegularStyle} />
                    <p className={styles.collapseListHeaderTypography}>
                      전문가모드 광고계정은 PC를 이용해주세요.
                    </p>
                  </div>

                  {proAccounts?.map((targetAdvertiser, index, original) => {
                    return (
                      <>
                        <div className={styles.collapseListSection}>
                          <section
                            className={styles.item}
                            key={targetAdvertiser.id}
                          >
                            <div className={styles.text}>
                              <div className={styles.TitleRow}>
                                <span
                                  className={styles.name({ active: false })}
                                >
                                  {targetAdvertiser.name}
                                </span>
                                <span className={styles.ProBadge}>
                                  전문가모드
                                </span>
                              </div>
                              <span className={styles.description}>
                                ID {targetAdvertiser._id}
                              </span>
                            </div>
                          </section>
                        </div>
                        {index !== original.length - 1 && (
                          <hr className={styles.hr} />
                        )}
                      </>
                    );
                  })}
                </>
              )}
            </section>
            <div
              className={styles.CollapseHeader({ isExpanded: expanded })}
              onClick={() => setExpanded(!expanded)}
            >
              <IconExpandMoreRegular
                className={styles.IconExpandMoreRegularStyle({
                  isExpanded: expanded,
                })}
              />
              <span>{!expanded ? "광고계정 모두 보기" : "접기"}</span>
            </div>
          </>
        )}
      </article>
    </Sheet.Root>
  );
};

export default AdvertiserSelectorBottomSheet;
