import * as RadixAccordion from "@radix-ui/react-accordion";
import { clsx } from "clsx";
import React, { ComponentProps } from "react";

import * as styles from "./accordion.css";

const Item = React.forwardRef<
  HTMLDivElement,
  ComponentProps<typeof RadixAccordion.Item>
>(({ children, className, ...props }, forwardedRef) => (
  <RadixAccordion.Item
    className={clsx([styles.item, className])}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </RadixAccordion.Item>
));

const Trigger = React.forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof RadixAccordion.Trigger>
>(({ children, className, ...props }, forwardedRef) => {
  return (
    <RadixAccordion.Header>
      <RadixAccordion.Trigger
        className={className}
        {...props}
        ref={forwardedRef}
        type={undefined}
      >
        {children}
      </RadixAccordion.Trigger>
    </RadixAccordion.Header>
  );
});

const Content = React.forwardRef<
  HTMLDivElement,
  ComponentProps<typeof RadixAccordion.Content>
>(({ children, className, ...props }, forwardedRef) => (
  <RadixAccordion.Content
    className={clsx([styles.content, className])}
    {...props}
    ref={forwardedRef}
  >
    {children}
  </RadixAccordion.Content>
));

export const Accordion = {
  ...RadixAccordion,
  Content,
  Item,
  Trigger,
};
