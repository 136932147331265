/**
 * @generated SignedSource<<2ed7604bead83592b9b4c934cbfd10af>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type PlacementEnum = "HOME_FEED" | "SEARCH_RESULT";
import { FragmentRefs } from "relay-runtime";
export type liteAdPlacementByLiteAdId_LiteAdChanged$data = {
  readonly id: string;
  readonly placement: PlacementEnum;
  readonly " $fragmentType": "liteAdPlacementByLiteAdId_LiteAdChanged";
};
export type liteAdPlacementByLiteAdId_LiteAdChanged$key = {
  readonly " $data"?: liteAdPlacementByLiteAdId_LiteAdChanged$data;
  readonly " $fragmentSpreads": FragmentRefs<"liteAdPlacementByLiteAdId_LiteAdChanged">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "liteAdPlacementByLiteAdId_LiteAdChanged"
};

(node as any).hash = "4b73f22d24c5ca60807f5174247d42cb";

export default node;
