import { vars } from "@seed-design/design-token";

export type FontSize = Record<
  `$scale.${keyof typeof vars.$scale.dimension}`,
  string
>;

/**
 * 😻😻 file nesting 활용
 */

/**
 * 😻😻 folder sturucture 활용
 */

/**
 * 😻😻 _shared folder 활용
 */

/**
 * 😻😻 filename kebab-case 활용
 */

/**
 * 😻😻 최대한 seed-design token 기반으로 정의해두고 사용
 *
 * 광고 웹컴포넌트
 * https://www.figma.com/file/7XMm3lQSdCoVnzkRCY1rnp/%EA%B4%91%EA%B3%A0-%EC%9B%B9-%EC%BB%B4%ED%8F%AC%EB%84%8C%ED%8A%B8?type=design&node-id=113-3963&mode=design&t=VcVIaRsY7jOdvBFq-0
 * foundation
 * https://www.figma.com/file/x6GwR7JROD6A7aybguRG2e/Foundation-v2?node-id=978%3A655&mode=dev
 */
export const fontSize = Object.entries(vars.$scale.dimension).reduce(
  (acc, [k, v]) => ({
    ...acc,
    [`$scale.${k}`]: v,
  }),
  {} as FontSize,
);
