/**
 * @generated SignedSource<<5e38b8fa54bf1f5590dccf8040d4217a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ExternalSourceTypeEnum = "BUSINESS_POST" | "BUSINESS_PROFILE" | "CLASS" | "COMMERCE" | "FARM" | "REALTY" | "TOWN_ARTICLE" | "TOWN_JOB";
export type liteAdRegionInitQuery$variables = {
  advertiserId: CustomScalars["GraphQLIDInput"];
  externalSourceId: CustomScalars["GraphQLIDInput"];
  externalSourceType: ExternalSourceTypeEnum;
};
export type liteAdRegionInitQuery$data = {
  readonly externalSource: {
    readonly baseRegion: {
      readonly _id: string;
      readonly name: string;
    } | null | undefined;
    readonly regionRadius: {
      readonly address: string | null | undefined;
      readonly latitude: string | null | undefined;
      readonly longitude: string | null | undefined;
      readonly supported: boolean;
    } | null | undefined;
  } | null | undefined;
  readonly karrotUser: {
    readonly baseRegion: {
      readonly _id: string;
      readonly name: string;
    } | null | undefined;
  } | null | undefined;
};
export type liteAdRegionInitQuery = {
  response: liteAdRegionInitQuery$data;
  variables: liteAdRegionInitQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "advertiserId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "externalSourceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "externalSourceType"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "kind": "Variable",
  "name": "advertiserId",
  "variableName": "advertiserId"
},
v5 = [
  (v4/*: any*/),
  {
    "kind": "Variable",
    "name": "externalSourceId",
    "variableName": "externalSourceId"
  },
  {
    "kind": "Variable",
    "name": "externalSourceType",
    "variableName": "externalSourceType"
  }
],
v6 = {
  "alias": null,
  "args": [
    (v4/*: any*/)
  ],
  "concreteType": "ExternalSourceRegionRadius",
  "kind": "LinkedField",
  "name": "regionRadius",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supported",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "address",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "latitude",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "longitude",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "liteAdRegionInitQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KarrotUser",
        "kind": "LinkedField",
        "name": "karrotUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "KarrotUserBaseRegion",
            "kind": "LinkedField",
            "name": "baseRegion",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ExternalSource",
        "kind": "LinkedField",
        "name": "externalSource",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalSourceBaseRegion",
            "kind": "LinkedField",
            "name": "baseRegion",
            "plural": false,
            "selections": (v3/*: any*/),
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "liteAdRegionInitQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "KarrotUser",
        "kind": "LinkedField",
        "name": "karrotUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "KarrotUserBaseRegion",
            "kind": "LinkedField",
            "name": "baseRegion",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "ExternalSource",
        "kind": "LinkedField",
        "name": "externalSource",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalSourceBaseRegion",
            "kind": "LinkedField",
            "name": "baseRegion",
            "plural": false,
            "selections": (v8/*: any*/),
            "storageKey": null
          },
          (v6/*: any*/),
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d3c38b4a357b89f23e321bc09ac38426",
    "id": null,
    "metadata": {},
    "name": "liteAdRegionInitQuery",
    "operationKind": "query",
    "text": "query liteAdRegionInitQuery(\n  $advertiserId: GraphQLIDInput!\n  $externalSourceId: GraphQLIDInput!\n  $externalSourceType: ExternalSourceTypeEnum!\n) {\n  karrotUser {\n    baseRegion {\n      _id\n      name\n      id\n    }\n    id\n  }\n  externalSource(advertiserId: $advertiserId, externalSourceId: $externalSourceId, externalSourceType: $externalSourceType) {\n    baseRegion {\n      _id\n      name\n      id\n    }\n    regionRadius(advertiserId: $advertiserId) {\n      supported\n      address\n      latitude\n      longitude\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "e2c18d1a761615e35fd6715a42d86adb";

export default node;
