import React from "react";

import { TypeBusinessUserStore } from "@/src/store/business-user";

import { Popover } from "../../_shared/popover";
import { AdvertiserItem, AdvertiserProfile, ExtraButton } from "./advertiser";
import * as styles from "./advertiser-selector.css";

interface Props {
  advertiser: TypeBusinessUserStore["advertiser"];
  advertisers: TypeBusinessUserStore["advertisers"];
  setAdvertiser: TypeBusinessUserStore["setAdvertiser"];
}

const AdvertiserSelectorDesktop: React.FC<Props> = ({
  advertiser,
  advertisers,
  setAdvertiser,
}) => {
  return (
    <Popover.Root>
      <Popover.Trigger asChild>
        <AdvertiserProfile
          cash={
            (advertiser?.freeCash?.value ?? 0) +
            (advertiser?.prepaidCash?.value ?? 0)
          }
          name={advertiser?.name ?? ""}
        />
      </Popover.Trigger>

      <Popover.Content align="start" alignOffset={10} sideOffset={-6}>
        <div className={styles.container}>
          <div className={styles.scroll}>
            <div className={styles.accountList}>
              <span className={styles.accountLabel}>현재 광고계정</span>
              <div>
                <Popover.Item asChild>
                  <AdvertiserItem
                    activeAdCount={advertiser?.activeAdCount ?? 0}
                    badgeLabel={
                      Boolean(advertiser?.owner) ? "소유자" : "운영자"
                    }
                    cash={
                      (advertiser?.freeCash.value ?? 0) +
                      (advertiser?.prepaidCash.value ?? 0)
                    }
                    checked
                    name={advertiser?.name ?? ""}
                  />
                </Popover.Item>
              </div>
            </div>
            <div className={styles.accountList}>
              <span className={styles.accountLabel}>다른 광고계정</span>
              <div>
                {advertisers?.map((advertiser) => (
                  <Popover.Item
                    asChild
                    key={advertiser.id}
                    onClick={() => void setAdvertiser(advertiser)}
                  >
                    <AdvertiserItem
                      activeAdCount={advertiser.activeAdCount}
                      cash={
                        (advertiser.freeCash.value ?? 0) +
                        (advertiser.prepaidCash.value ?? 0)
                      }
                      name={advertiser.name}
                    />
                  </Popover.Item>
                ))}
              </div>
            </div>
          </div>
          <div className={styles.fixed}>
            <hr className={styles.divider} />
            <Popover.Item asChild>
              <ExtraButton>새 광고계정 만들기</ExtraButton>
            </Popover.Item>
            <Popover.Item asChild>
              <ExtraButton>광고계정 전체 보기</ExtraButton>
            </Popover.Item>
          </div>
        </div>
      </Popover.Content>
    </Popover.Root>
  );
};

export default AdvertiserSelectorDesktop;
