import * as Sentry from "@sentry/react";
import { fetchQuery, graphql } from "react-relay";
import { P, match } from "ts-pattern";
import { create } from "zustand";
import { devtools, subscribeWithSelector } from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { useShallow } from "zustand/react/shallow";
import { shallow } from "zustand/shallow";

import { env } from "../../environment";
import {
  businessUserInitQuery,
  businessUserInitQuery$data,
} from "../graphql/__relay__/businessUserInitQuery.graphql";
import relayEnvironment from "../graphql/relayEnvironment";
import { handleAppAction } from "../lib/webview";
import createSelectors from "./_shared/create-selectors";

type Advertisers = businessUserInitQuery$data["me"]["advertisers"];
type Advertiser = NonNullable<Advertisers>[number];
type BusinessProfiles = businessUserInitQuery$data["me"]["businessProfiles"];

type TypeBusinessUserStoreState = {
  advertiser: Advertiser | null;
  advertisers: Advertisers;
  businessProfiles: BusinessProfiles;
  // TODO: 에러상태 추가
  initializeStatus: "error" | "loading" | "success";
  karrotUserId: null | number;
  termsList: businessUserInitQuery$data["me"]["termsList"];
  termsStatus: businessUserInitQuery$data["me"]["termsStatus"];
};

type TypeBusinessUserStoreAction = {
  /**
   * @description 현재 설정되어있는 advertiser 정보
   */
  getCurrentAdvertiser: () => Advertiser;
  /**
   * @description 광고주 정보 초기 호출
   */
  initialize: () => Promise<void>;
  /**
   * @description 광고주 선택시 호출
   */
  setAdvertiser: (advertiser: Advertiser) => void;
  /**
   * @description 비즈니스 계정이 변경됐을 경우 호출
   */
  setAdvertisers: (advertisers: Advertisers) => void;
};

export type TypeBusinessUserStore = TypeBusinessUserStoreState &
  TypeBusinessUserStoreAction;

const useBusinessUserStore = createSelectors(
  create<TypeBusinessUserStore>()(
    subscribeWithSelector(
      immer(
        devtools(
          (set, get) => ({
            advertiser: null,
            advertisers: [],
            businessProfile: null,
            businessProfiles: [],
            getCurrentAdvertiser: () => {
              const { advertiser } = get();

              if (!advertiser) {
                throw new Error(
                  "useBusinessUserStore.initialize가 완료된 이후에 실행되어야 해요.",
                );
              }

              return advertiser;
            },
            initialize: async () => {
              const result = await fetchQuery<businessUserInitQuery>(
                relayEnvironment,
                graphql`
                  query businessUserInitQuery {
                    me @required(action: THROW) {
                      id
                      advertisers {
                        id
                        _id
                        name
                        activeAdCount
                        mode
                        status {
                          ... on AdvertiserActiveStatus {
                            status
                          }
                          ... on AdvertiserDeleteStatus {
                            status
                          }
                          ... on AdvertiserBanStatus {
                            status
                            reason
                            endAt
                          }
                        }
                        freeCash {
                          value
                        }
                        prepaidCash {
                          value
                        }
                        admins {
                          id
                          _id
                          status
                          karrotUserId
                        }
                        owner {
                          id
                          _id
                          status
                          karrotUserId
                        }
                      }
                      latestAdvertiser {
                        id
                        _id
                        name
                        activeAdCount
                        mode
                        status {
                          ... on AdvertiserActiveStatus {
                            status
                          }
                          ... on AdvertiserDeleteStatus {
                            status
                          }
                          ... on AdvertiserBanStatus {
                            status
                            reason
                            endAt
                          }
                        }
                        freeCash {
                          value
                        }
                        prepaidCash {
                          value
                        }
                        admins {
                          id
                          _id
                          status
                          karrotUserId
                        }
                        owner {
                          id
                          _id
                          status
                          karrotUserId
                        }
                      }
                      businessProfiles {
                        id
                      }
                      termsStatus
                      termsList {
                        type
                        agree
                      }
                    }
                  }
                `,
                {},
              ).toPromise();

              const advertisers = result?.me.advertisers ?? [];
              const liteAdvertisers = advertisers.filter(
                (advertiser) => advertiser.mode === "LITE",
              );

              if (!liteAdvertisers?.length) {
                handleAppAction({
                  onBrowserAction: () => {
                    window.location.href = advertisers.length
                      ? `${env.PRO_ADS_HOST}/?advertiserId=${advertisers[0]._id}` // 전문가모드 광고계정 존재
                      : `${env.BUSINESS_PLATFORM_HOST}`; // 광고계정이 존재하지 않음
                  },
                })();
              }

              const urlParams = new URLSearchParams(window.location.search);
              const specifiedAdvertiserId = urlParams.get("advertiserId");
              const recentFlag = Boolean(urlParams.get("recent"));

              const advertiser =
                match<{
                  recentFlag: boolean;
                  specifiedAdvertiserId: null | string;
                }>({ recentFlag, specifiedAdvertiserId })
                  .with(
                    { recentFlag: true, specifiedAdvertiserId: P._ },
                    () => result?.me?.latestAdvertiser,
                  )
                  .with(
                    {
                      recentFlag: false,
                      specifiedAdvertiserId: P.not(null),
                    },
                    ({ specifiedAdvertiserId }) =>
                      liteAdvertisers.find(
                        (advertiser) =>
                          advertiser._id === specifiedAdvertiserId,
                      ),
                  )
                  .with(
                    { recentFlag: false, specifiedAdvertiserId: null },
                    () => liteAdvertisers[0],
                  )
                  .exhaustive() ?? liteAdvertisers[0];

              set(
                {
                  advertiser,
                  advertisers,
                  businessProfiles: result?.me?.businessProfiles,
                  initializeStatus: "success",
                  karrotUserId: match(advertiser)
                    .with(
                      P.when((data) => Boolean(data?.owner)),
                      (advertiser) => advertiser?.owner?.karrotUserId,
                    )
                    .otherwise((data) => {
                      return (
                        data?.admins.find((admin) => admin.karrotUserId)
                          ?.karrotUserId ?? null
                      );
                    }),
                  termsList: result?.me?.termsList,
                  termsStatus: result?.me?.termsStatus,
                },
                false,
                "initialize",
              );
            },
            initializeStatus: "loading",
            karrotUserId: null,
            setAdvertiser: (advertiser) => {
              set(
                {
                  advertiser,
                },
                false,
                "setAdvertiser",
              );
            },
            setAdvertisers: (advertisers) => {
              set(
                {
                  advertisers,
                },
                false,
                "setAdvertisers",
              );
            },
            termsList: [],
            termsStatus: "NO_NEED",
          }),
          { name: "business-user", store: "business-user" },
        ),
      ),
    ),
  ),
);

useBusinessUserStore.subscribe(
  (state) => [state.advertiser, state.karrotUserId] as const,
  ([advertiser, karrotUserId]) => {
    if (!advertiser) {
      return;
    }

    Sentry.setUser({
      advertiser_id: advertiser._id,
      karrot_user_id: karrotUserId,
    });
  },
  { equalityFn: shallow },
);

export const useBusinessUserStoreShallow = <
  T extends (state: TypeBusinessUserStore) => ReturnType<T>,
>(
  selector: T,
) => {
  return useBusinessUserStore(useShallow(selector));
};

export default useBusinessUserStore;
