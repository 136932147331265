import dayjs, { Dayjs } from "dayjs";
import { StateCreator } from "zustand";

import {
  ExternalSourceTypeEnum,
  LiteAdBudgetTypeEnum,
  LiteAdDurationScheduleInput,
  LiteAdExposureStrategyEnum,
  PlacementEnum,
} from "@/src/graphql/__generated__/resolvers";

import useBusinessUserStore from "../business-user";
import { LiteAdInitialStatus, TypeCreateLiteAdStore } from "./lite-ad";
import liteAdMapper from "./lite-ad.mapper";
import { TypeLiteAdBudgetDurationFormSchema } from "./lite-ad.schema";
import liteAdService, {
  TypeLiteAdServiceMethodReturn,
} from "./lite-ad.service";

export type TypeBudgetDurationState = {
  budgetAmount: number;
  budgetDurationInitialStatus: LiteAdInitialStatus;
  budgetType: LiteAdBudgetTypeEnum;
  durationAmount: string;
  durationType: LiteAdExposureStrategyEnum;
  endTime: Dayjs;
  externalSourceCategory?: {
    id?: null | string;
    name?: null | string;
    suggestedBudget?: null | number;
    suggestedDuration?: null | number;
  } | null;
  isEndless: boolean;
  schedule?: LiteAdDurationScheduleInput | null;
  startTime: Dayjs;
};

export type TypeBudgetDurationAction = {
  budgetDurationExternalSourceInit: ({
    externalSourceId,
    externalSourceType,
    placement,
  }: {
    externalSourceId: string;
    externalSourceType: ExternalSourceTypeEnum;
    placement: PlacementEnum;
  }) => Promise<void>;
  budgetDurationLiteAdInit: (
    query: TypeLiteAdServiceMethodReturn<"initStoreByLiteAdId">,
  ) => void;
  budgetDurationOutlinkInit: () => void;
  budgetDurationSetStatus: (status: LiteAdInitialStatus) => void;
  submitBudgetDurationForm: (data: TypeLiteAdBudgetDurationFormSchema) => void;
};

export const budgetDurationInitialState: TypeBudgetDurationState = {
  budgetAmount: 10000,
  budgetDurationInitialStatus: "initial",
  budgetType: "DAILY",
  durationAmount: "10",
  durationType: "START_IMMEDIATE",
  endTime: dayjs()
    .add(9 + 1, "d")
    .endOf("d"),
  externalSourceCategory: null,
  isEndless: false,
  schedule: null,
  startTime: dayjs().add(1, "d").startOf("d"),
};

export const createBudgetDurationSlice: StateCreator<
  TypeCreateLiteAdStore,
  [["zustand/devtools", never]],
  [],
  TypeBudgetDurationState & TypeBudgetDurationAction
> = (_, get) => {
  return {
    ...budgetDurationInitialState,

    budgetDurationExternalSourceInit: async ({
      externalSourceId,
      externalSourceType,
      placement,
    }) => {
      const state = get();
      const { budgetDurationSetStatus, setState } = state;
      const { getCurrentAdvertiser } = useBusinessUserStore.getState();
      const advertiser = getCurrentAdvertiser();

      budgetDurationSetStatus("loading");
      const response = await liteAdService.initBudgetDurationByExternalSource({
        advertiserId: advertiser.id,
        externalSourceId,
        externalSourceType,
      });
      setState(
        liteAdMapper.toStateBudgetDurationExternalSourceInit({
          externalSourceType,
          placement,
          query: response,
          state,
        }),
        "budgetDurationExternalSourceInit",
      );
      budgetDurationSetStatus("success");
    },

    budgetDurationLiteAdInit: (query) => {
      const { budgetDurationSetStatus, mode, setState } = get();
      const response = liteAdService.initBudgetDurationByLiteAdId(query);
      budgetDurationSetStatus("loading");
      setState(
        liteAdMapper.toStateBudgetDurationByLiteAdId(mode, response),
        "budgetDurationLiteAdInit",
      );
      budgetDurationSetStatus("success");
    },

    budgetDurationOutlinkInit: () => {
      const { budgetDurationSetStatus } = get();
      budgetDurationSetStatus("success");
    },

    budgetDurationSetStatus: (status) => {
      const { setState } = get();
      setState(
        { budgetDurationInitialStatus: status },
        "budgetDurationSetStatus",
      );
    },

    submitBudgetDurationForm: (data) => {
      const { setState } = get();
      setState(data, "submitBudgetDurationForm");
    },
  };
};
