import { SeedBoxButtonProps } from "@daangn/sprout-components-button";
import clsx from "clsx";

import { BoxButton } from "../button";
import * as styles from "./button-group.css";

type DataProps = {
  [key: `data-${string}`]: string | undefined;
};
export interface ButtonGroupProps {
  className?: string;
  fullWidth?: boolean;
  orientation?: "horizontal" | "vertical";
  primary: Omit<SeedBoxButtonProps, "children"> & {
    label: string;
  } & DataProps;
  secondary?: Omit<SeedBoxButtonProps, "children"> & {
    label: string;
  } & DataProps;
}

const ButtonGroup = ({
  className,
  fullWidth = false,
  orientation = "horizontal",
  primary,
  secondary,
}: ButtonGroupProps) => {
  return (
    <div
      className={clsx(styles.container({ fullWidth, orientation }), className)}
    >
      {secondary && (
        <BoxButton variant="secondary" {...secondary}>
          <label>{secondary.label}</label>
        </BoxButton>
      )}
      <BoxButton variant="primary" {...primary}>
        <label>{primary.label}</label>
      </BoxButton>
    </div>
  );
};

export default ButtonGroup;
