import * as RadixDialog from "@radix-ui/react-dialog";
import { clsx } from "clsx";
import React, { ComponentProps } from "react";

import * as styles from "./dialog.css";

const Overlay = React.forwardRef<
  HTMLDivElement,
  ComponentProps<typeof RadixDialog.Overlay>
>(({ className, ...props }, forwardedRef) => (
  <RadixDialog.Overlay
    className={clsx(styles.overlay, className)}
    {...props}
    ref={forwardedRef}
  />
));

const Trigger = React.forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof RadixDialog.Trigger>
>(({ className, ...props }, forwardedRef) => (
  <RadixDialog.Trigger {...props} asChild ref={forwardedRef} />
));

const Content = React.forwardRef<
  HTMLDivElement,
  ComponentProps<typeof RadixDialog.Content>
>(({ children, className, ...props }, forwardedRef) => (
  <div onClick={(e) => e.stopPropagation()}>
    <RadixDialog.Content
      className={clsx(styles.content, className)}
      onOpenAutoFocus={(e) => {
        e.preventDefault();
      }}
      {...props}
      ref={forwardedRef}
    >
      {children}
    </RadixDialog.Content>
  </div>
));

const Close = React.forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof RadixDialog.Close>
>(({ className, ...props }, forwardedRef) => (
  <RadixDialog.Close {...props} asChild ref={forwardedRef} />
));

export const Dialog = {
  ...RadixDialog,
  Close,
  Content,
  Overlay,
  Trigger,
};
