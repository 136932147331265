import { SnackbarProvider } from "@daangn/sprout-components-snackbar";
import * as Sentry from "@sentry/react";
import React from "react";
import { RelayEnvironmentProvider } from "react-relay";

import { BridgeProvider } from "./bridge";
import DialogProvider from "./components/_shared/dialog/dialog-provider";
import ErrorResult from "./components/_shared/error-result";
import AdvertiserOnboardingCash from "./components/advertiser-onboarding-cash";
import relayEnvironment from "./graphql/relayEnvironment";
import useSubscribeKotisaariWebviewStream from "./hooks/use-subscribe-kotisaari-webview-stream";
import { Stack } from "./stackflow";
import useBusinessUserStore from "./store/business-user";

useBusinessUserStore.getState().initialize();

const App: React.FC = () => {
  useSubscribeKotisaariWebviewStream();
  return (
    <Sentry.ErrorBoundary
      beforeCapture={(scope) => {
        scope.setTag("location", "app");
      }}
      fallback={() => {
        return (
          <ErrorResult
            errorMessage={
              "일시적으로 오류가 발생했어요.\n잠시 후 다시 시도해주세요."
            }
            onReset={() => {
              window.location.reload();
            }}
            resetText="새로고침"
          />
        );
      }}
    >
      <BridgeProvider>
        <RelayEnvironmentProvider environment={relayEnvironment}>
          <SnackbarProvider offset="auto">
            <DialogProvider>
              <AdvertiserOnboardingCash>
                <React.Suspense fallback={null}>
                  <Stack />
                </React.Suspense>
              </AdvertiserOnboardingCash>
            </DialogProvider>
          </SnackbarProvider>
        </RelayEnvironmentProvider>
      </BridgeProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
