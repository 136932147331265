import { IconCheckRegular, IconExpandMoreRegular } from "@seed-design/icon";
import React from "react";

import * as styles from "./advertiser.css";

interface AccountItemProps {
  activeAdCount: number;
  badgeLabel?: string;
  cash: number;
  checked?: boolean;
  name: string;
}

export const AdvertiserItem = React.forwardRef<
  HTMLDivElement,
  AccountItemProps
>(
  (
    {
      activeAdCount,
      badgeLabel,
      cash,
      checked = false,
      name,
      ...props
    }: AccountItemProps,
    ref,
  ) => (
    <div {...props} className={styles.container} ref={ref}>
      <div className={styles.content}>
        <div className={styles.titleContainer}>
          <span className={styles.title}>{name}</span>
          {badgeLabel && <div className={styles.badge}>{badgeLabel}</div>}
        </div>
        <span className={styles.caption}>
          활성광고 {activeAdCount.toLocaleString()}개 &middot; 광고캐시{" "}
          {cash.toLocaleString()}원
        </span>
      </div>
      {checked && <IconCheckRegular className={styles.checkIcon} />}
    </div>
  ),
);

interface DataProps {
  [key: `data-${string}`]: unknown;
}
interface AccountProfileProps extends DataProps {
  cash: number;
  name: string;
}

export const AdvertiserProfile = React.forwardRef<
  HTMLDivElement,
  AccountProfileProps
>(({ cash, name, ...props }: AccountProfileProps, ref) => (
  <div {...props} className={styles.profileContainer} ref={ref}>
    <div className={styles.content}>
      <div className={styles.title}>{name}</div>
      <div className={styles.caption}>광고캐시 {cash.toLocaleString()}원</div>
    </div>
    <IconExpandMoreRegular
      className={styles.expandIcon}
      data-state={props["data-state"]}
    />
  </div>
));

export const ExtraButton = React.forwardRef<
  HTMLButtonElement,
  React.PropsWithChildren
>(({ children, ...props }: React.PropsWithChildren, ref) => (
  <button {...props} className={styles.buttonLabel} ref={ref}>
    {children}
  </button>
));
