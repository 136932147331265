import type { StackflowActions } from "@stackflow/core";
import type { StackflowReactPlugin } from "@stackflow/react";

import { metas } from "../__generated__/generated-activities";
import { logEvent, logShownEvent } from "../../utils/event";
import { getPrevActivity, getReferrer } from "../prev-activity-plugin";
import metaLoaders from "./metaLoaders";
import { getCommonEventParams, isActivityName } from "./utlis";

let lastPluginId = 0;
let isEntryPointInitialized = false;

const shownEventPlugin = (): StackflowReactPlugin => () => {
  let pluginId: number;

  const onInit = ({ actions }: { actions: StackflowActions }) => {
    lastPluginId += 1;
    pluginId = lastPluginId;

    const referrer = getReferrer();
    if (!isEntryPointInitialized && referrer) {
      isEntryPointInitialized = true;
      logEvent("client_clicked_adLite_entryPoint_v1", {
        screen_name: referrer,
      });
    }

    onPushed({ actions });
  };

  const onPushed = async ({
    actions: { getStack },
  }: {
    actions: StackflowActions;
  }) => {
    if (pluginId !== lastPluginId) {
      return;
    }

    const { activities } = getStack();
    const activeActivity = activities.find((item) => item.isActive);
    const activeActivityName = activeActivity?.name;

    if (!isActivityName(activeActivityName)) {
      return;
    }

    const meta = metas[activeActivityName];
    if (!meta || !meta.event) {
      return;
    }

    const prevActivity = getPrevActivity();
    const eventName = `client_shown_adLite_${meta.event.activityAlias}`;

    const commonEventParams = await getCommonEventParams();

    const activityEventParams = (await meta.event.eventParams?.(
      activeActivity as any,
      commonEventParams,
      metaLoaders,
    )) ?? {
      advertiser_id: commonEventParams.advertiser_id,
      business_user_id: commonEventParams.business_user_id,
    };

    const eventParams = {
      ...activityEventParams,
      prev_screen_name: prevActivity
        ? metas[prevActivity.name as keyof typeof metas]?.event.activityAlias
        : undefined,
    };

    logShownEvent(eventName, eventParams);
  };

  return {
    key: "ad-lite-shown-event-plugin",
    onInit,
    onPushed,
    onReplaced: onPushed,
  };
};

export default shownEventPlugin;
