import dayjs from "dayjs";
import { P, match } from "ts-pattern";

import {
  LiteAdAddContentInput,
  LiteAdAddInput,
  LiteAdAddTargetInput,
  LiteAdBudgetInput,
  LiteAdDurationInput,
  LiteAdUpdateContentInput,
  LiteAdUpdateInput,
  LiteAdUpdateTargetInput,
  LiteAdUpdateTargetRegionInput,
} from "@/src/graphql/__generated__/resolvers";
import { stripComma } from "@/src/utils/commaize";

import { TypeCreateLiteAdStore } from "./lite-ad";
import {
  TypeLiteAdBudgetDurationFormSchema,
  TypeLiteAdContentPreviewFormSchema,
  TypeLiteAdRegionFormSchema,
} from "./lite-ad.schema";

class LiteAdPayloadMapper {
  #toLiteAdAddContentInput(
    state: TypeCreateLiteAdStore,
  ): LiteAdAddContentInput {
    return {
      ...match({ syncType: state.syncType })
        .with({ syncType: "CUSTOM" }, () => ({
          customImageUrl: state.customImageUrl,
          customTitle: state.customTitle,
          ...match({
            placement: state.placement,
          })
            .with({ placement: "SEARCH_RESULT" }, () => ({
              customDescription: state.customDescription,
            }))
            .otherwise(() => ({})),
        }))
        .with({ syncType: "OUTLINK" }, () => ({
          brandName: state.brandName,
          ctaButtonType: state.ctaButtonType,
          customImageUrl: state.customImageUrl,
          customTitle: state.customTitle,
          targetUrl: state.targetUrl,
          ...match({ showPriceForm: state.showPriceForm })
            .with({ showPriceForm: true }, () => ({ price: state.price }))
            .otherwise(() => ({})),
        }))
        .otherwise(() => ({})),
      ...match({ showDeliberationCodeForm: state.showDeliberationCodeForm })
        .with({ showDeliberationCodeForm: true }, () => ({
          deliberationCode: state.deliberationCode,
          deliberationCodePrefix: state.deliberationCodePrefix,
        }))
        .otherwise(() => ({})),
      externalSourceId: state.externalSourceId,
      externalSourceType: state.externalSourceType,
      showRegionName: state.showRegionName,
      syncType: state.syncType,
    };
  }

  #toLiteAdAddTargetInput(state: TypeCreateLiteAdStore): LiteAdAddTargetInput {
    return {
      ages: state.ages,
      gender: state.gender,
      region: {
        baseRegionId: parseInt(state.baseRegionId ?? "0", 10),
        regionDepth: state.regionDepth,
        regionOption: "RECOMMENDED",
        ...match({
          radiusInfo: state.radiusInfo,
          regionType: state.regionType,
        })
          .with({ regionType: "ALL" }, (data) => ({
            regionOption: data.regionType,
          }))
          .with(
            {
              radiusInfo: {
                address: P.not(P.nullish),
                distance: P.not(P.nullish),
                latitude: P.not(P.nullish),
                longitude: P.not(P.nullish),
              },
              regionType: "RADIUS",
            },
            (data) => ({
              regionOption: "CUSTOM",
              regionRadius: {
                address: data.radiusInfo.address,
                distance: data.radiusInfo.distance,
                latitude: data.radiusInfo.latitude.toString(),
                longitude: data.radiusInfo.longitude.toString(),
              },
            }),
          )
          .with({ regionType: "SELECT" }, () => ({
            regionIds: state.regions.map((item) => item.id),
            regionOption: "CUSTOM",
          }))
          .with({ regionType: "RANGE" }, () => ({
            regionDepth: state.regionDepth,
            regionOption: "CUSTOM",
          }))
          .otherwise(() => ({})),
        type: state.regionType,
      },
    };
  }

  #toLiteAdDurationScheduleInput(
    state: TypeCreateLiteAdStore | TypeLiteAdBudgetDurationFormSchema,
  ): LiteAdDurationInput["schedule"] {
    if (!state.schedule) {
      return null;
    }

    return Object.entries(state.schedule).reduce(
      (current, [key, value]) => {
        current[key] = value.map((item) => ({
          ...item,
          end: this.#toScheduleEndTimeInput(item.end),
        }));
        return current;
      },
      {} as NonNullable<LiteAdDurationInput["schedule"]>,
    );
  }

  #toLiteAdUpdateTargetInput(
    state: TypeCreateLiteAdStore,
  ): LiteAdUpdateTargetInput {
    return {
      ages: state.ages,
      gender: state.gender[0],
      region: this.toLiteAdUpdateTargetRegionInput(state),
    };
  }

  #toScheduleEndTimeInput(end: string) {
    if (end === "23:59") {
      return end;
    }

    const [h] = end.split(":");
    return `${Number(h) - 1}:59`;
  }

  public toLiteAdAddInput(state: TypeCreateLiteAdStore): LiteAdAddInput {
    return {
      budget: this.toLiteAdBudgetInput(state),
      content: this.#toLiteAdAddContentInput(state),
      duration: this.toLiteAdDurationInput(state),
      keywords: state.keywords,
      placement: state.placement,
      target: this.#toLiteAdAddTargetInput(state),
    };
  }

  public toLiteAdBudgetInput(
    state: TypeCreateLiteAdStore | TypeLiteAdBudgetDurationFormSchema,
  ): LiteAdBudgetInput {
    return {
      amount: state.budgetAmount,
      // TODO: budget 옵션
      option: "CUSTOM" as const,
      type: state.budgetType,
    };
  }

  public toLiteAdDurationInput(
    state: TypeCreateLiteAdStore | TypeLiteAdBudgetDurationFormSchema,
  ): LiteAdDurationInput {
    return {
      endTime: match({
        durationType: state.durationType,
        isEndless: state.isEndless,
      })
        .with({ isEndless: true }, () => null)
        .with({ durationType: "FIXED_SCHEDULE" }, () =>
          state.endTime.toISOString(),
        )
        .with({ durationType: "START_IMMEDIATE" }, () =>
          dayjs()
            .add(stripComma(state.durationAmount ?? "0"), "d")
            .toISOString(),
        )
        .exhaustive(),
      schedule: this.#toLiteAdDurationScheduleInput(state),
      startTime:
        state.durationType === "START_IMMEDIATE"
          ? dayjs().toISOString()
          : state.startTime.toISOString(),
      type: state.durationType,
    };
  }

  public toLiteAdUpdateContentInput(
    state: TypeCreateLiteAdStore | TypeLiteAdContentPreviewFormSchema,
  ): LiteAdUpdateContentInput {
    return {
      brandName: state.brandName,
      customDescription: state.customDescription,
      customImageUrl: state.customImageUrl,
      customTitle: state.customTitle,
      /**
       * @description null을 보내야 삭제가 될 수 있어요.
       */
      deliberationCode: state.showDeliberationCodeForm
        ? state.deliberationCode
        : null,
      deliberationCodePrefix: state.showDeliberationCodeForm
        ? state.deliberationCodePrefix
        : null,
      /**
       * @description null을 보내야 삭제가 될 수 있어요.
       */
      price: state.showPriceForm ? state.price : null,
      showRegionName: state.showRegionName,
      syncType: state.syncType,
    };
  }

  public toLiteAdUpdateInput(state: TypeCreateLiteAdStore): LiteAdUpdateInput {
    return {
      budget: this.toLiteAdBudgetInput(state),
      duration: this.toLiteAdDurationInput(state),
      keywords: state.keywords,
      material: this.toLiteAdUpdateContentInput(state),
      target: this.#toLiteAdUpdateTargetInput(state),
    };
  }

  public toLiteAdUpdateTargetRegionInput(
    state: TypeCreateLiteAdStore | TypeLiteAdRegionFormSchema,
  ): LiteAdUpdateTargetRegionInput {
    return match(state)
      .returnType<LiteAdUpdateTargetRegionInput>()
      .with({ regionType: "SELECT" }, (values) => ({
        regionSelect: {
          regionIds: values.regions.map((item) => item.id),
        },
      }))
      .with({ regionType: "RANGE" }, (values) => ({
        regionRange: {
          baseRegionId: parseInt(values.baseRegionId ?? "", 10),
          regionDepth: values.regionDepth,
        },
      }))
      .with({ regionType: "ALL" }, (values) => ({
        regionAll: {
          baseRegionId: parseInt(values.baseRegionId ?? "", 10),
        },
      }))
      .with({ regionType: "RADIUS" }, (values) => {
        if (
          !values.radiusInfo ||
          !values.radiusInfo.address ||
          !values.radiusInfo.distance ||
          !values.radiusInfo.latitude ||
          !values.radiusInfo.longitude
        ) {
          return {};
        }

        return {
          regionRadius: {
            address: values.radiusInfo.address,
            distance: values.radiusInfo.distance,
            latitude: values.radiusInfo.latitude.toString(),
            longitude: values.radiusInfo.longitude.toString(),
            regionOption: "CUSTOM",
          },
        };
      })
      .exhaustive();
  }
}

const liteAdPayloadMapper = new LiteAdPayloadMapper();
export default liteAdPayloadMapper;
