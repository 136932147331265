import {
  IconLoudspeakerRegular,
  IconMoneyWonRegular,
  IconSellRegular,
  IconSettingRegular,
} from "@seed-design/icon";
import * as React from "react";

import { useFlow } from "@/src/stackflow";

import AccountSelectorController from "../advertiser-selector-controller";
import * as styles from "./layout-sidebar.css";
import { AccordionMenu, Menu } from "./menu";

export const Sidebar = () => {
  const { push } = useFlow();

  return (
    <nav className={styles.container}>
      <AccountSelectorController />
      <ol className={styles.navigation}>
        <Menu
          icon={<IconLoudspeakerRegular size={18} />}
          label="광고 관리"
          onClick={() => {
            if (window.location.pathname.startsWith("/advertisements")) {
              return;
            }

            return push("advertisements", {
              placementFilter: "ALL",
              statusFilter: "ALL",
            });
          }}
        />
        <Menu
          icon={<IconSellRegular size={18} />}
          label="광고 만들기"
          onClick={() => {
            if (
              window.location.pathname.startsWith(
                "/advertisements/select-placement",
              )
            ) {
              return;
            }

            return push("advertisements/select-placement", {});
          }}
        />
        <AccordionMenu
          icon={<IconMoneyWonRegular size={18} />}
          label="광고캐시 관리"
        >
          <ol>
            <Menu label="광고캐시 내역" />
            <Menu label="이달의 가계부" />
            <Menu label="세금계산서" />
          </ol>
        </AccordionMenu>
        <AccordionMenu
          icon={<IconSettingRegular size={18} />}
          label="광고계정 관리"
        >
          <ol>
            <Menu label="설정" />
            <Menu label="운영자 관리" />
          </ol>
        </AccordionMenu>
      </ol>
    </nav>
  );
};
