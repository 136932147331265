/* eslint-disable relay/must-colocate-fragment-spreads */
import { LoadQueryOptions, graphql, loadQuery } from "react-relay";

import { Index_SelectArticleType_Query } from "@/src/graphql/__relay__/Index_SelectArticleType_Query.graphql";
import relayEnvironment from "@/src/graphql/relayEnvironment";
import { createPageLoader } from "@/src/stackflow/use-preloader";

export const { pageLoader, usePagePreloadLoader } = createPageLoader({
  activityName: "advertisements/select-article-type",
  loadGraphql: () => {
    const gqlQuery = graphql`
      query Index_SelectArticleType_Query(
        $status: [BusinessProfileStatusEnum!]!
      ) {
        ...homeFeedArticleTypes_Query @arguments(status: $status)
        ...searchResultArticleTypes_Query @arguments(status: $status)
      }
    `;

    const runQuery = (
      variables: Index_SelectArticleType_Query["variables"],
      options?: LoadQueryOptions,
    ) =>
      loadQuery<Index_SelectArticleType_Query>(
        relayEnvironment,
        gqlQuery,
        variables,
        options,
      );
    const preloadedQuery = runQuery({
      status: ["ACTIVE"],
    });

    return {
      gqlQuery,
      preloadedQuery,
      runQuery,
    };
  },
  loadModule: () =>
    import("@/src/routes/advertisements/select-article-type/_index.page"),
});
