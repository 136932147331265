import "@daangn/sprout-components-spinner/index.css";

import { Spinner as SproutSpinner } from "@daangn/sprout-components-spinner";
import React from "react";

export interface SpinnerProps {}
export type BasicSpinnerProps = SpinnerProps &
  Omit<React.ComponentProps<typeof SproutSpinner>, "children">;

const Spinner: React.FC<BasicSpinnerProps> = (props) => {
  return <SproutSpinner {...props} />;
};

export default Spinner;
