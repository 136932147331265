import type { Activity, StackflowActions } from "@stackflow/core";
import type { StackflowReactPlugin } from "@stackflow/react";

import { create } from "zustand";

import createSelectors from "@/src/store/_shared/create-selectors";

import { env } from "../../environment";

const usePrevActivityStore = createSelectors(
  create<{
    currentActivity: Activity | null;
    prevActivity: Activity | null;
    referrer: null | string;
    setCurrentActivity: (currentActivity: Activity | null) => void;
    setPrevActivity: (prevActivity: Activity | null) => void;
  }>((set) => {
    return {
      currentActivity: null,
      prevActivity: null,
      referrer: new URLSearchParams(window.location.search).get("referrer"),
      setCurrentActivity: (currentActivity) => set({ currentActivity }),
      setPrevActivity: (prevActivity) => set({ prevActivity }),
    };
  }),
);

const getReferrer = () => usePrevActivityStore.getState().referrer;
const getCurrentActivity = () =>
  usePrevActivityStore.getState().currentActivity;
const setCurrentActivity = usePrevActivityStore.getState().setCurrentActivity;
const getPrevActivity = () => usePrevActivityStore.getState().prevActivity;
const setPrevActivity = usePrevActivityStore.getState().setPrevActivity;

let lastPluginId = 0;
const prevActivityPlugin = (): StackflowReactPlugin => () => {
  let pluginId: number;

  const onInit = ({ actions }: { actions: StackflowActions }) => {
    lastPluginId += 1;
    pluginId = lastPluginId;
    setPrevActivity(null);
    setCurrentActivity(null);

    const hasReferer = Boolean(getReferrer());
    if (!hasReferer && env.STAGE === "alpha") {
      alert(
        `비정상적인 레퍼러가 감지되었어요!\n이전 화면을 캡쳐한 뒤 광고실 프론트엔드 채널(#_business-ad-fe)에서 @Roger(로저)에게 전달 부탁드려요.`,
      );
    }

    setActivity({ actions });
  };

  const setActivity = ({
    actions: { getStack },
  }: {
    actions: StackflowActions;
  }) => {
    if (pluginId !== lastPluginId) {
      return;
    }

    const activeActivity = getStack().activities.find((item) => item.isActive);
    if (!activeActivity) {
      return;
    }

    setPrevActivity(getCurrentActivity());
    setCurrentActivity(activeActivity);
  };

  return {
    key: "ad-lite-prev-activity-plugin",
    onInit,
    onPopped: setActivity,
    onPushed: setActivity,
    onReplaced: setActivity,
  };
};

export {
  getCurrentActivity,
  getPrevActivity,
  getReferrer,
  setCurrentActivity,
  setPrevActivity,
};
export default prevActivityPlugin;
