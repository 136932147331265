import { IconExpandMoreRegular } from "@seed-design/icon";
import * as React from "react";

import { Accordion } from "../_shared/accordion";
import * as styles from "./menu.css";

interface MenuProps {
  expanded?: boolean;
  icon?: React.ReactNode;
  label: string;
  onClick?: () => void;
}

export const Menu = React.forwardRef<HTMLLIElement, MenuProps>(
  ({ expanded = false, icon, label, onClick, ...props }: MenuProps, ref) => (
    <li {...props} className={styles.container} onClick={onClick} ref={ref}>
      <div className={styles.content}>
        <div className={styles.icon}>{icon}</div>
        {label}
      </div>
      {expanded && (
        <IconExpandMoreRegular
          {...props}
          className={styles.expandIcon}
          size={12}
        />
      )}
    </li>
  ),
);

export const AccordionMenu = ({
  children,
  icon,
  label,
}: React.PropsWithChildren<Omit<MenuProps, "expanded">>) => {
  return (
    <li>
      <Accordion.Root collapsible type="single">
        <Accordion.Item value={label}>
          <Accordion.Trigger asChild>
            <Menu expanded icon={icon} label={label} />
          </Accordion.Trigger>
          <Accordion.Content>{children}</Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </li>
  );
};
