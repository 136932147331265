/**
 * @generated SignedSource<<954c5702266bd92455d0a58460e0b375>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ExternalSourceTypeEnum = "BUSINESS_POST" | "BUSINESS_PROFILE" | "CLASS" | "COMMERCE" | "FARM" | "REALTY" | "TOWN_ARTICLE" | "TOWN_JOB";
export type externalSourceMeta_SourceOwnerId_Query$variables = {
  advertiserId: CustomScalars["GraphQLIDInput"];
  externalSourceId: CustomScalars["GraphQLIDInput"];
  externalSourceType: ExternalSourceTypeEnum;
};
export type externalSourceMeta_SourceOwnerId_Query$data = {
  readonly externalSource: {
    readonly owner: {
      readonly _id: string;
    } | null | undefined;
  } | null | undefined;
};
export type externalSourceMeta_SourceOwnerId_Query = {
  response: externalSourceMeta_SourceOwnerId_Query$data;
  variables: externalSourceMeta_SourceOwnerId_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "advertiserId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "externalSourceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "externalSourceType"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "advertiserId",
    "variableName": "advertiserId"
  },
  {
    "kind": "Variable",
    "name": "externalSourceId",
    "variableName": "externalSourceId"
  },
  {
    "kind": "Variable",
    "name": "externalSourceType",
    "variableName": "externalSourceType"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "externalSourceMeta_SourceOwnerId_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExternalSource",
        "kind": "LinkedField",
        "name": "externalSource",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalSourceOwner",
            "kind": "LinkedField",
            "name": "owner",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "externalSourceMeta_SourceOwnerId_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExternalSource",
        "kind": "LinkedField",
        "name": "externalSource",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ExternalSourceOwner",
            "kind": "LinkedField",
            "name": "owner",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9563144f0033548e14ba759275f1a7e9",
    "id": null,
    "metadata": {},
    "name": "externalSourceMeta_SourceOwnerId_Query",
    "operationKind": "query",
    "text": "query externalSourceMeta_SourceOwnerId_Query(\n  $advertiserId: GraphQLIDInput!\n  $externalSourceId: GraphQLIDInput!\n  $externalSourceType: ExternalSourceTypeEnum!\n) {\n  externalSource(advertiserId: $advertiserId, externalSourceId: $externalSourceId, externalSourceType: $externalSourceType) {\n    owner {\n      _id\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "88764ec75174b7e1bb1db7b7d5fe1cf3";

export default node;
