import { StackflowReactPlugin } from "@stackflow/react";
import * as React from "react";

import WebLayout from "../components/layout-web/layout-web";
import { isWebview } from "../lib/webview";

const layoutPlugin = (): StackflowReactPlugin => () => {
  return {
    key: "kotisaari-lite-layout-plugin",
    wrapStack({ stack }) {
      if (isWebview) {
        return <>{stack.render()}</>;
      }

      return <WebLayout>{stack.render()}</WebLayout>;
    },
  };
};

export default layoutPlugin;
