import * as React from "react";
import { match } from "ts-pattern";

import { tokens } from "@/src/styles";

function matchMedia() {
  return match({ medium: window.matchMedia(tokens.media["(>medium)"]).matches })
    .with({ medium: true }, () => "desktop" as const)
    .otherwise(() => "mobile" as const);
}

type TypeHookReturn = "desktop" | "mobile";

function useBreakpoint(): TypeHookReturn {
  const [breakpoint, setBreakpoint] = React.useState(matchMedia);

  React.useEffect(() => {
    function updateMatchMedia() {
      setBreakpoint(matchMedia);
    }
    window.addEventListener("resize", updateMatchMedia);

    return () => window.removeEventListener("resize", updateMatchMedia);
  }, []);

  return breakpoint;
}

export default useBreakpoint;
