// @ts-nocheck
// Enum 순서 때문에 자꾸 devServer start, build시에 에러가 나고 있어요.
// 대안을 찾을 때까지 우선 ts-nocheck를 추가해요

import { U } from "ts-toolbelt";

import {
  AgeEnum,
  CtaTypeEnum,
  ExternalSourceTypeEnum,
  GenderEnum,
  LiteAdBudgetTypeEnum,
  LiteAdExposureStrategyEnum,
  LiteAdStatusFilterEnum,
  PlacementEnum,
  PlacementFilterEnum,
  TargetRegionEnum,
} from "../graphql/__generated__/resolvers";

export const LITE_AD_STATUS_FILTER_ENUM: U.ListOf<LiteAdStatusFilterEnum> = [
  "ALL",
  "BLOCKED",
  "DENIED",
  "FINISHED",
  "OFF",
  "ONGOING",
  "READY",
];

export const PLACEMENT_FILTER_ENUM: U.ListOf<PlacementFilterEnum> = [
  "ALL",
  "HOME_FEED",
  "SEARCH_RESULT",
];

export const PLACEMENT_ENUM: U.ListOf<PlacementEnum> = [
  "HOME_FEED",
  "SEARCH_RESULT",
];

export const EXTERNAL_SOURCE_TYPE_ENUM: U.ListOf<ExternalSourceTypeEnum> = [
  "BUSINESS_POST",
  "BUSINESS_PROFILE",
  "CLASS",
  "COMMERCE",
  "FARM",
  "REALTY",
  "TOWN_ARTICLE",
  "TOWN_JOB",
];

export const TARGET_REGION_ENUM: U.ListOf<TargetRegionEnum> = [
  "ALL",
  "RADIUS",
  "RANGE",
  "SELECT",
];
/**
 * @description `EntryPoint`는 kotisaari-sdk에서 정의한 명칭을 사용합니다.
 * {@link https://github.com/daangn/kotisaari-frontend/blob/8c5f42f44ede0844f2c20dd96da127aed8db94ef/packages/sdk/kotisaari-sdk/src/constants/entry.ts#L26-L72}
 */
export const EntryPointEnum = [
  "KarrotMyDaangn",
  "KarrotTownpostlist",
  "KarrotChatroom",
  "BizprofileHome",
  "BizprofilePostlist",
  "BizprofilePostdetail",
  "JobsHome",
  "JobsPostdetail",
  "JobsMypost",
  "RealtyPostdetail",
  "CommerceProductdetail",
  "CarPostdetail",
  "FarmPostDetail",
  "ClassPostDetail",
  "FarmPostlist",
  "ClassPostlist",
  "BizProfileStats",
  "BizProfilePostEditor",
] as const;

export const GENDER_ENUM: U.ListOf<GenderEnum> = ["ALL", "FEMALE", "MALE"];

export const AGE_ENUM: U.ListOf<AgeEnum> = [
  "ALL",
  "FROM0TO14",
  "FROM15TO19",
  "FROM20TO24",
  "FROM25TO29",
  "FROM30TO34",
  "FROM35TO39",
  "FROM40TO49",
  "FROM50",
];

export const BUDGET_TYPE_ENUM: U.ListOf<LiteAdBudgetTypeEnum> = [
  "DAILY",
  "TOTALLY",
];

export const EXPOSURE_STRATEGY_ENUM: U.ListOf<LiteAdExposureStrategyEnum> = [
  "FIXED_SCHEDULE",
  "START_IMMEDIATE",
];

export const CTA_TYPE_ENUM: U.ListOf<CtaTypeEnum> = [
  "APPLY",
  "CONTACTUS",
  "PURCHASING",
  "SHORTCUTS",
];
