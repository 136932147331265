import * as RadixPopover from "@radix-ui/react-popover";
import { clsx } from "clsx";
import { ComponentProps } from "react";
import React from "react";

import * as styles from "./popover.css";

const Trigger = React.forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof RadixPopover.Trigger>
>(({ children, className, ...props }, forwardRef) => (
  <RadixPopover.Trigger
    className={className}
    {...props}
    ref={forwardRef}
    type={undefined}
  >
    {children}
  </RadixPopover.Trigger>
));

const Item = React.forwardRef<
  HTMLButtonElement,
  ComponentProps<typeof RadixPopover.Close>
>(({ children, className, ...props }, forwardRef) => (
  <RadixPopover.Close
    {...props}
    className={className}
    ref={forwardRef}
    type={undefined}
  >
    {children}
  </RadixPopover.Close>
));

const Content = React.forwardRef<
  HTMLDivElement,
  ComponentProps<typeof RadixPopover.Content>
>(({ children, className, ...props }, forwardRef) => (
  <RadixPopover.Portal>
    <RadixPopover.Content
      {...props}
      className={clsx([styles.content, className])}
      ref={forwardRef}
    >
      {children}
    </RadixPopover.Content>
  </RadixPopover.Portal>
));

export const Popover = { ...RadixPopover, Content, Item, Trigger };
