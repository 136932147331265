import {
  SeedSnackbarOptions,
  useSnackbarAdapter,
} from "@daangn/sprout-components-snackbar";
import React from "react";

export * from "@daangn/sprout-components-snackbar";

export const useSnackbar = () => {
  const snackbarIdRef = React.useRef<null | string>(null);
  const snackbarAdapter = useSnackbarAdapter();
  return {
    addSnackbar: (args: Partial<SeedSnackbarOptions> & { id?: string }) => {
      if (snackbarIdRef.current === args.id) {
        return;
      }
      snackbarAdapter.dismiss();
      snackbarAdapter.create({
        onClose: () => {
          snackbarIdRef.current = null;
        },
        shouldCloseOnAction: true,
        timeout: 1500,
        type: "default",
        ...args,
      });
      if (args.id) {
        snackbarIdRef.current = args.id;
      }
    },
    dismissSnackbar: () => snackbarAdapter.dismiss(),
  };
};
