import type { makeKarrotBridge } from "@daangn/karrotbridge";

import { karrotAnalyticsPlugin } from "@daangn/stackflow-plugin-karrot-analytics";
import { StackflowReactPlugin } from "@stackflow/react";

import { bridge } from "../bridge";
import { handleAppAction } from "../lib/webview";
import { TypeStackflowActivities } from "./__generated__/generated-activities";

type TypeBridge = ReturnType<typeof makeKarrotBridge>;

function customAnalyticsPlugin(): StackflowReactPlugin<TypeStackflowActivities> {
  const logEvent = handleAppAction<Parameters<TypeBridge["logEvent"]>>({
    onBrowserAction: (_ctx, req) => {
      // TODO: 브라우저에서는 어떻게 처리할지 결정해야 함 GA?
      return new Promise((res) => res({}));
    },
    onWebviewAction: (_ctx, req) => {
      return bridge.logEvent(req);
    },
  });

  return karrotAnalyticsPlugin({
    bridge: {
      logEvent,
    } as TypeBridge,
    serviceName: "ad_lite",
  });
}

export default customAnalyticsPlugin;
