/**
 * @generated SignedSource<<81cf67e64c4dee6dd84368d3864d897a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type liteAdRegionInitRegionQuery$variables = {
  baseRegionId: number;
  hasBaseRegionId: boolean;
  regionDepth: number;
};
export type liteAdRegionInitRegionQuery$data = {
  readonly regionFixed?: ReadonlyArray<{
    readonly childrenCount: number | null | undefined;
    readonly fullName: string | null | undefined;
    readonly id: string;
    readonly level: number | null | undefined;
    readonly name: string | null | undefined;
    readonly name1: string | null | undefined;
    readonly name2: string | null | undefined;
  }> | null | undefined;
};
export type liteAdRegionInitRegionQuery = {
  response: liteAdRegionInitRegionQuery$data;
  variables: liteAdRegionInitRegionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "baseRegionId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasBaseRegionId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionDepth"
},
v3 = [
  {
    "condition": "hasBaseRegionId",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "baseRegionId",
            "variableName": "baseRegionId"
          },
          {
            "kind": "Variable",
            "name": "regionDepth",
            "variableName": "regionDepth"
          }
        ],
        "concreteType": "Region",
        "kind": "LinkedField",
        "name": "regionFixed",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name2",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "childrenCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "level",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "liteAdRegionInitRegionQuery",
    "selections": (v3/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "liteAdRegionInitRegionQuery",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "e25e7f5865fea6a8e875ba513a8b97de",
    "id": null,
    "metadata": {},
    "name": "liteAdRegionInitRegionQuery",
    "operationKind": "query",
    "text": "query liteAdRegionInitRegionQuery(\n  $baseRegionId: Int!\n  $regionDepth: Int!\n  $hasBaseRegionId: Boolean!\n) {\n  regionFixed(baseRegionId: $baseRegionId, regionDepth: $regionDepth) @include(if: $hasBaseRegionId) {\n    id\n    name\n    name1\n    name2\n    fullName\n    childrenCount\n    level\n  }\n}\n"
  }
};
})();

(node as any).hash = "5bd6e31c9b053530b9a9f4b510b82e2f";

export default node;
