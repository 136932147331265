import { TypeMeta } from "@/src/stackflow/event-plugins";
import useLiteAdStore from "@/src/store/lite-ad/lite-ad";

export const pageMeta: TypeMeta<"advertisements/create"> = {
  event: {
    activityAlias: "createComplete_v1",
    eventParams: async ({ params }, commonEventParams, loaders) => {
      const store = useLiteAdStore.getState();

      const is_edit =
        !!params.liteAdId ||
        (Boolean(params.mode) && params.mode !== "create") ||
        store.mode !== "create";
      let business_account_id;
      let post_id;

      if (is_edit) {
        const variables = {
          advertiserId: commonEventParams.advertiser_query_id!,
          liteAdId: params.liteAdId!,
        };

        [business_account_id, post_id] = await Promise.all([
          loaders.liteAd.getLiteAdSourceOwnerId(variables),
          loaders.liteAd.getLiteAdMaterialSourceId(variables),
        ]);
      } else {
        const variables = {
          advertiserId: commonEventParams.advertiser_query_id!,
          externalSourceId: store.externalSourceId!,
          externalSourceType: store.externalSourceType!,
        };

        [business_account_id, post_id] = await Promise.all([
          loaders.externalSource.getExternalSourceOwnerId(variables),
          loaders.externalSource.getExternalSourceId(variables),
        ]);
      }

      return {
        advertiser_id: commonEventParams.advertiser_id,
        business_account_id,
        business_user_id: commonEventParams.business_user_id,
        is_edit,
        post_id,
      };
    },
  },
};
