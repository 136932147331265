import authPlugin from "@daangn/plantae-plugin-auth";
import { createFetch } from "plantae";

import { env } from "../environment";
import { bridge } from "./bridge";
import { appInfo } from "./lib/webview";

const getFallbackAuthToken = () => {
  if (appInfo) {
    return undefined;
  }

  const storageKey = "Kotisaari#X_AUTH_TOKEN";
  const token =
    env.X_AUTH_TOKEN ??
    sessionStorage.getItem(storageKey) ??
    prompt("유저 토큰을 입력해주세요.") ??
    undefined;
  if (token) {
    sessionStorage.setItem(storageKey, token);
  }

  return token;
};

/**
 * Plantae에 Timeout과 Retry Plugin을 적용해 생성한 fetch 클라이언트에요.
 * @see https://github.com/daangn/plantae
 * @see https://github.com/daangn/plantae/tree/main/packages/plugin-timeout
 * @see https://github.com/daangn/plantae/tree/main/packages/plugin-retry
 */
// const baseFetch = createFetch({
//   plugins: [timeoutPlugin(5000), retryPlugin(3)],
// });

/**
 * Plantae에 Auth Plugin을 적용해 생성한 fetch 클라이언트에요.
 * @see https://github.com/daangn/webview-network/tree/main/packages/plantae-plugin-auth
 */
const customFetch = createFetch({
  /**
   * fetch 클라이언트를 생성할 때, 다른 클라이언트로부터 확장할 수도 있어요.
   */
  // client: baseFetch,
  plugins: [
    authPlugin({
      bridge,
      options: {
        fallbackAuthToken: {
          value: getFallbackAuthToken(),
        },
      },
    }),
  ],
});

export { customFetch };
