import { stackDepthChangePlugin } from "@stackflow/plugin-stack-depth-change";

import { bridge } from "../bridge";

const handleDepthChange = (depth: number) => {
  bridge.styleCurrentRouter({
    router: {
      backSwipable: depth <= 1,
      navbar: false,
      scrollable: false,
    },
  });
};

export const depthChangePlugin = () => {
  return stackDepthChangePlugin({
    onDepthChanged: ({ depth }) => {
      handleDepthChange(depth);
    },
    onInit: ({ depth }) => {
      handleDepthChange(depth);
    },
  });
};
