import { vars } from "@seed-design/design-token";
import { basicUIPlugin } from "@stackflow/plugin-basic-ui";
import { historySyncPlugin } from "@stackflow/plugin-history-sync";
import { preloadPlugin } from "@stackflow/plugin-preload";
import { basicRendererPlugin } from "@stackflow/plugin-renderer-basic";
import { ActivityComponentType, stackflow } from "@stackflow/react";

import { bridge } from "../bridge";
import { isWebview } from "../lib/webview";
import { TypeStackflowActivities } from "./__generated__/generated-activities";
import customAnalyticsPlugin from "./custom-analytics-plugin";
import { depthChangePlugin } from "./depth-plugin";
import clickedEventPlugin from "./event-plugins/clicked-event-plugin";
import shownEventPlugin from "./event-plugins/shown-event-plugin";
import layoutPlugin from "./layout-plugin";
import prevActivityPlugin from "./prev-activity-plugin";
import { activities, loaders, routes } from "./routes";

/**
 * Stackflow는 웹뷰 내에서 Stack Navigation UI를 도와주는 도구에요.
 * 아래 웹사이트를 통해, 가이드 문서를 확인하실 수 있어요.
 * 웹사이트: https://stackflow.so
 * GitHub: https://github.com/daangn/stackflow
 */
const transitionDuration = isWebview ? 270 : 0;
const theme = /iphone|ipad|ipod/i.test(window.navigator.userAgent.toLowerCase())
  ? "cupertino"
  : "android";

const { Stack, useFlow, useStepFlow } = stackflow({
  activities,
  plugins: [
    depthChangePlugin(),
    basicRendererPlugin(),
    basicUIPlugin({
      appBar: {
        borderColor:
          theme === "cupertino"
            ? vars.$semantic.color.divider3
            : vars.$semantic.color.divider2,
        closeButton: {
          onClick() {
            bridge.closeRouter({});
          },
        },
        iconColor: vars.$semantic.color.inkText,
        textColor: vars.$semantic.color.inkText,
      },
      backgroundColor: vars.$semantic.color.paperDefault,
      theme,
    }),
    historySyncPlugin({
      fallbackActivity: () => "advertisements",
      routes,
      urlPatternOptions: {
        segmentValueCharset: "a-zA-Z0-9-_~ %=",
      },
    }),
    preloadPlugin({ loaders }),
    customAnalyticsPlugin(),
    prevActivityPlugin(),
    shownEventPlugin(),
    clickedEventPlugin(),
    layoutPlugin(),
  ],
  transitionDuration,
});

export { Stack };
export type TypeUseFlow = typeof useFlow;
export type TypeUseStepFlow = typeof useStepFlow;
export type TypeActivityName = keyof TypeStackflowActivities;
export type TypeActivityParams<T extends TypeActivityName> =
  TypeStackflowActivities[T] extends
    | {
        component: ActivityComponentType<infer U>;
      }
    | ActivityComponentType<infer U>
    ? U
    : {};
