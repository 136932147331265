/* DO NOT CHANGE CODE BELOWS. IT WILL BE GENERATED */
/* eslint-disable */







/* start of generation */
import type Advertisements from "@/src/routes/advertisements/_index.page";
import { pageMeta as AdvertisementsMeta } from "@/src/routes/advertisements/_index.meta";
import type AdvertisementsSelectLiteAdPlacementFilter from "@/src/routes/advertisements/select-lite-ad-placement-filter.page";
import { pageLoader as AdvertisementsSelectLiteAdPlacementFilterLoader } from "@/src/routes/advertisements/select-lite-ad-placement-filter.loader";
import type AdvertisementsSelectLiteAdStatusFilter from "@/src/routes/advertisements/select-lite-ad-status-filter.page";
import { pageLoader as AdvertisementsSelectLiteAdStatusFilterLoader } from "@/src/routes/advertisements/select-lite-ad-status-filter.loader";
import type AdvertisementsLiteAdId from "@/src/routes/advertisements/$liteAdId/_index.page";
import { pageLoader as AdvertisementsLiteAdIdLoader } from "@/src/routes/advertisements/$liteAdId/_index.loader";
import { pageMeta as AdvertisementsLiteAdIdMeta } from "@/src/routes/advertisements/$liteAdId/_index.meta";
import type AdvertisementsLiteAdIdPreview from "@/src/routes/advertisements/$liteAdId/preview/_index.page";
import { pageLoader as AdvertisementsLiteAdIdPreviewLoader } from "@/src/routes/advertisements/$liteAdId/preview/_index.loader";
import type AdvertisementsBudgetDuration from "@/src/routes/advertisements/budget-duration/_index.page";
import { pageLoader as AdvertisementsBudgetDurationLoader } from "@/src/routes/advertisements/budget-duration/_index.loader";
import { pageMeta as AdvertisementsBudgetDurationMeta } from "@/src/routes/advertisements/budget-duration/_index.meta";
import type AdvertisementsContentPreview from "@/src/routes/advertisements/content-preview/_index.page";
import { pageLoader as AdvertisementsContentPreviewLoader } from "@/src/routes/advertisements/content-preview/_index.loader";
import { pageMeta as AdvertisementsContentPreviewMeta } from "@/src/routes/advertisements/content-preview/_index.meta";
import type AdvertisementsCreate from "@/src/routes/advertisements/create/_index.page";
import { pageLoader as AdvertisementsCreateLoader } from "@/src/routes/advertisements/create/_index.loader";
import { pageMeta as AdvertisementsCreateMeta } from "@/src/routes/advertisements/create/_index.meta";
import type AdvertisementsOutlinkUrlConfig from "@/src/routes/advertisements/outlink-url-config/_index.page";
import { pageLoader as AdvertisementsOutlinkUrlConfigLoader } from "@/src/routes/advertisements/outlink-url-config/_index.loader";
import type AdvertisementsSearchKeywords from "@/src/routes/advertisements/search-keywords/_index.page";
import { pageLoader as AdvertisementsSearchKeywordsLoader } from "@/src/routes/advertisements/search-keywords/_index.loader";
import { pageMeta as AdvertisementsSearchKeywordsMeta } from "@/src/routes/advertisements/search-keywords/_index.meta";
import type AdvertisementsSelectArticleType from "@/src/routes/advertisements/select-article-type/_index.page";
import { pageLoader as AdvertisementsSelectArticleTypeLoader } from "@/src/routes/advertisements/select-article-type/_index.loader";
import { pageMeta as AdvertisementsSelectArticleTypeMeta } from "@/src/routes/advertisements/select-article-type/_index.meta";
import type AdvertisementsSelectContent from "@/src/routes/advertisements/select-content/_index.page";
import { pageLoader as AdvertisementsSelectContentLoader } from "@/src/routes/advertisements/select-content/_index.loader";
import type AdvertisementsSelectDaySchedule from "@/src/routes/advertisements/select-day-schedule/_index.page";
import { pageLoader as AdvertisementsSelectDayScheduleLoader } from "@/src/routes/advertisements/select-day-schedule/_index.loader";
import type AdvertisementsSelectPlacement from "@/src/routes/advertisements/select-placement/_index.page";
import { pageLoader as AdvertisementsSelectPlacementLoader } from "@/src/routes/advertisements/select-placement/_index.loader";
import { pageMeta as AdvertisementsSelectPlacementMeta } from "@/src/routes/advertisements/select-placement/_index.meta";
import type AdvertisementsTarget from "@/src/routes/advertisements/target/_index.page";
import { pageLoader as AdvertisementsTargetLoader } from "@/src/routes/advertisements/target/_index.loader";
import { pageMeta as AdvertisementsTargetMeta } from "@/src/routes/advertisements/target/_index.meta";
import type AdvertisementsTargetGenderAge from "@/src/routes/advertisements/target/gender-age/_index.page";
import { pageLoader as AdvertisementsTargetGenderAgeLoader } from "@/src/routes/advertisements/target/gender-age/_index.loader";
import { pageMeta as AdvertisementsTargetGenderAgeMeta } from "@/src/routes/advertisements/target/gender-age/_index.meta";
import type AdvertisementsTargetRegion from "@/src/routes/advertisements/target/region/_index.page";
import { pageLoader as AdvertisementsTargetRegionLoader } from "@/src/routes/advertisements/target/region/_index.loader";
import { pageMeta as AdvertisementsTargetRegionMeta } from "@/src/routes/advertisements/target/region/_index.meta";
import type SearchAddress from "@/src/routes/search-address/_index.page";
import { pageLoader as SearchAddressLoader } from "@/src/routes/search-address/_index.loader";
import type CreateBusinessUser from "@/src/routes/create/business-user/_index.page";
import type CreateBusinessUserOnboarding from "@/src/routes/create/business-user/onboarding/_index.page";
import { pageLoader as CreateBusinessUserOnboardingLoader } from "@/src/routes/create/business-user/onboarding/_index.loader";
import { pageMeta as CreateBusinessUserOnboardingMeta } from "@/src/routes/create/business-user/onboarding/_index.meta";
import type CreateBusinessUserTerms from "@/src/routes/create/business-user/terms/_index.page";
import { pageLoader as CreateBusinessUserTermsLoader } from "@/src/routes/create/business-user/terms/_index.loader";

export type TypeStackflowActivities = {
  
  "advertisements": typeof Advertisements;
  "advertisements/select-lite-ad-placement-filter": typeof AdvertisementsSelectLiteAdPlacementFilter;
  "advertisements/select-lite-ad-status-filter": typeof AdvertisementsSelectLiteAdStatusFilter;
  "advertisements/:liteAdId": typeof AdvertisementsLiteAdId;
  "advertisements/:liteAdId/preview": typeof AdvertisementsLiteAdIdPreview;
  "advertisements/budget-duration": typeof AdvertisementsBudgetDuration;
  "advertisements/content-preview": typeof AdvertisementsContentPreview;
  "advertisements/create": typeof AdvertisementsCreate;
  "advertisements/outlink-url-config": typeof AdvertisementsOutlinkUrlConfig;
  "advertisements/search-keywords": typeof AdvertisementsSearchKeywords;
  "advertisements/select-article-type": typeof AdvertisementsSelectArticleType;
  "advertisements/select-content": typeof AdvertisementsSelectContent;
  "advertisements/select-day-schedule": typeof AdvertisementsSelectDaySchedule;
  "advertisements/select-placement": typeof AdvertisementsSelectPlacement;
  "advertisements/target": typeof AdvertisementsTarget;
  "advertisements/target/gender-age": typeof AdvertisementsTargetGenderAge;
  "advertisements/target/region": typeof AdvertisementsTargetRegion;
  "search-address": typeof SearchAddress;
  "create/business-user": typeof CreateBusinessUser;
  "create/business-user/onboarding": typeof CreateBusinessUserOnboarding;
  "create/business-user/terms": typeof CreateBusinessUserTerms;
}

export const loaders = {
  
  "advertisements/select-lite-ad-placement-filter": AdvertisementsSelectLiteAdPlacementFilterLoader,
  "advertisements/select-lite-ad-status-filter": AdvertisementsSelectLiteAdStatusFilterLoader,
  "advertisements/:liteAdId": AdvertisementsLiteAdIdLoader,
  "advertisements/:liteAdId/preview": AdvertisementsLiteAdIdPreviewLoader,
  "advertisements/budget-duration": AdvertisementsBudgetDurationLoader,
  "advertisements/content-preview": AdvertisementsContentPreviewLoader,
  "advertisements/create": AdvertisementsCreateLoader,
  "advertisements/outlink-url-config": AdvertisementsOutlinkUrlConfigLoader,
  "advertisements/search-keywords": AdvertisementsSearchKeywordsLoader,
  "advertisements/select-article-type": AdvertisementsSelectArticleTypeLoader,
  "advertisements/select-content": AdvertisementsSelectContentLoader,
  "advertisements/select-day-schedule": AdvertisementsSelectDayScheduleLoader,
  "advertisements/select-placement": AdvertisementsSelectPlacementLoader,
  "advertisements/target": AdvertisementsTargetLoader,
  "advertisements/target/gender-age": AdvertisementsTargetGenderAgeLoader,
  "advertisements/target/region": AdvertisementsTargetRegionLoader,
  "search-address": SearchAddressLoader,
  "create/business-user/onboarding": CreateBusinessUserOnboardingLoader,
  "create/business-user/terms": CreateBusinessUserTermsLoader,
}

export const metas = {
  
  "advertisements": AdvertisementsMeta,
  "advertisements/:liteAdId": AdvertisementsLiteAdIdMeta,
  "advertisements/budget-duration": AdvertisementsBudgetDurationMeta,
  "advertisements/content-preview": AdvertisementsContentPreviewMeta,
  "advertisements/create": AdvertisementsCreateMeta,
  "advertisements/search-keywords": AdvertisementsSearchKeywordsMeta,
  "advertisements/select-article-type": AdvertisementsSelectArticleTypeMeta,
  "advertisements/select-placement": AdvertisementsSelectPlacementMeta,
  "advertisements/target": AdvertisementsTargetMeta,
  "advertisements/target/gender-age": AdvertisementsTargetGenderAgeMeta,
  "advertisements/target/region": AdvertisementsTargetRegionMeta,
  "create/business-user/onboarding": CreateBusinessUserOnboardingMeta,
}

/* end of generation */