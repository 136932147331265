export const NEAR_REGION_VALUES = {
  BIKE: 3 as const,
  CAR: 4 as const,
  HOME: 1 as const,
  WALK: 2 as const,
};

export type TypeNearRegionValues =
  (typeof NEAR_REGION_VALUES)[keyof typeof NEAR_REGION_VALUES];

export const REGION_DEPTH_VALUES = {
  // 동 단위
  DONG: 3 as const,
  // 시/구 단위
  SIGUN: 2 as const,
};
