/**
 * @generated SignedSource<<40fe90dfd7129656162a8570d6ca75e1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BusinessProfileStatusEnum = "ACTIVE" | "INACTIVE" | "PENDING";
export type Index_SelectArticleType_Query$variables = {
  status: ReadonlyArray<BusinessProfileStatusEnum>;
};
export type Index_SelectArticleType_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"homeFeedArticleTypes_Query" | "searchResultArticleTypes_Query">;
};
export type Index_SelectArticleType_Query = {
  response: Index_SelectArticleType_Query$data;
  variables: Index_SelectArticleType_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "status"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "status",
    "variableName": "status"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Index_SelectArticleType_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "homeFeedArticleTypes_Query"
      },
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "searchResultArticleTypes_Query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Index_SelectArticleType_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessUser",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "BusinessProfile",
            "kind": "LinkedField",
            "name": "businessProfiles",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "regionName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "thumbnail",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8c564236700bdd6700e07550f84bc4e3",
    "id": null,
    "metadata": {},
    "name": "Index_SelectArticleType_Query",
    "operationKind": "query",
    "text": "query Index_SelectArticleType_Query(\n  $status: [BusinessProfileStatusEnum!]!\n) {\n  ...homeFeedArticleTypes_Query_lg5YC\n  ...searchResultArticleTypes_Query_lg5YC\n}\n\nfragment homeFeedArticleTypes_Query_lg5YC on Query {\n  me {\n    businessProfiles(status: $status) {\n      id\n      name\n      regionName\n      thumbnail\n    }\n    id\n  }\n}\n\nfragment searchResultArticleTypes_Query_lg5YC on Query {\n  me {\n    businessProfiles(status: $status) {\n      id\n      name\n      regionName\n      thumbnail\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc5bef123e1cd00f85cb1e9dbf192ee4";

export default node;
