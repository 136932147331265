import { match } from "ts-pattern";

import useBusinessUserStore from "@/src/store/business-user";

import { CommonEventParams } from ".";
import { metas } from "../__generated__/generated-activities";

export function isActivityName(name?: string): name is keyof typeof metas {
  if (!name) {
    return false;
  }
  return name in metas;
}

export async function getCommonEventParams(
  depth = 0,
): Promise<CommonEventParams> {
  const initializeStatus = useBusinessUserStore.getState().initializeStatus;

  return match(initializeStatus)
    .with(
      "loading",
      () =>
        new Promise((res) => {
          /**
           * Maximum Call Stack Size Exceeded 방지
           * 5번까지만 재귀호출
           * 5번째 호출시 빈 객체 반환
           */
          if (depth > 4) {
            return res({});
          }
          setTimeout(() => {
            getCommonEventParams(depth++).then(res);
          }, 1000);
        }),
    )
    .with("error", () => ({}))
    .with("success", () => ({
      advertiser_id: useBusinessUserStore.getState().advertiser?._id,
      advertiser_query_id: useBusinessUserStore.getState().advertiser?.id,
      // business_user_id: useBusinessUserStore.getState().businessUserId,
    }))
    .exhaustive();
}
