/* eslint-disable relay/unused-fields */
import { fetchQuery, graphql, readInlineData } from "react-relay";
import { P, match } from "ts-pattern";

import {
  AgeEnum,
  ExternalSourceTypeEnum,
  GenderEnum,
  PlacementEnum,
} from "@/src/graphql/__generated__/resolvers";
import { liteAdBudgetDurationExternalSourceQuery } from "@/src/graphql/__relay__/liteAdBudgetDurationExternalSourceQuery.graphql";
import { liteAdContentByLiteAdId_LiteAdChanged$key } from "@/src/graphql/__relay__/liteAdContentByLiteAdId_LiteAdChanged.graphql";
import { liteAdContentInitQuery } from "@/src/graphql/__relay__/liteAdContentInitQuery.graphql";
import { liteAdGenderAgeByExternalSourceQuery } from "@/src/graphql/__relay__/liteAdGenderAgeByExternalSourceQuery.graphql";
import { liteAdInitBudgetDurationByLiteAdId_LiteAdChanged$key } from "@/src/graphql/__relay__/liteAdInitBudgetDurationByLiteAdId_LiteAdChanged.graphql";
import { liteAdInitGenderAgeByLiteAdId_LiteAdChanged$key } from "@/src/graphql/__relay__/liteAdInitGenderAgeByLiteAdId_LiteAdChanged.graphql";
import { liteAdInitKeywordByLiteAdId_LiteAdChanged$key } from "@/src/graphql/__relay__/liteAdInitKeywordByLiteAdId_LiteAdChanged.graphql";
import { liteAdInitRegionByLiteAdId_Query$key } from "@/src/graphql/__relay__/liteAdInitRegionByLiteAdId_Query.graphql";
import { liteAdInitRegionByUserQuery } from "@/src/graphql/__relay__/liteAdInitRegionByUserQuery.graphql";
import { liteAdInitStoreByLiteAdIdQuery } from "@/src/graphql/__relay__/liteAdInitStoreByLiteAdIdQuery.graphql";
import { liteAdPlacementByLiteAdId_LiteAdChanged$key } from "@/src/graphql/__relay__/liteAdPlacementByLiteAdId_LiteAdChanged.graphql";
import { liteAdRegionInitQuery } from "@/src/graphql/__relay__/liteAdRegionInitQuery.graphql";
import { liteAdRegionInitRegionByIdsQuery } from "@/src/graphql/__relay__/liteAdRegionInitRegionByIdsQuery.graphql";
import { liteAdRegionInitRegionQuery } from "@/src/graphql/__relay__/liteAdRegionInitRegionQuery.graphql";
import relayEnvironment from "@/src/graphql/relayEnvironment";

import { IS_PRODUCTION } from "../../../environment";

class LiteAdService {
  async initBudgetDurationByExternalSource({
    advertiserId,
    externalSourceId,
    externalSourceType,
  }: {
    advertiserId: string;
    externalSourceId: string;
    externalSourceType: ExternalSourceTypeEnum;
  }) {
    const query = await fetchQuery<liteAdBudgetDurationExternalSourceQuery>(
      relayEnvironment,
      graphql`
        query liteAdBudgetDurationExternalSourceQuery(
          $advertiserId: GraphQLIDInput!
          $externalSourceId: GraphQLIDInput!
          $externalSourceType: ExternalSourceTypeEnum!
        ) {
          externalSource(
            advertiserId: $advertiserId
            externalSourceId: $externalSourceId
            externalSourceType: $externalSourceType
          ) {
            id
            category {
              id
              name
            }
          }
        }
      `,

      { advertiserId, externalSourceId, externalSourceType },
    ).toPromise();

    return query;
  }

  initBudgetDurationByLiteAdId(
    query: TypeLiteAdServiceMethodReturn<"initStoreByLiteAdId">,
  ) {
    if (!query?.liteAdChanged) {
      return;
    }

    const data =
      readInlineData<liteAdInitBudgetDurationByLiteAdId_LiteAdChanged$key>(
        graphql`
          fragment liteAdInitBudgetDurationByLiteAdId_LiteAdChanged on LiteAdChanged
          @inline
          @argumentDefinitions(advertiserId: { type: "GraphQLIDInput!" }) {
            duration {
              startAt
              endAt
              type
            }

            status

            material {
              source(advertiserId: $advertiserId) {
                __typename

                ... on ExternalSource {
                  id
                  type
                  category {
                    id
                    name
                  }
                }
              }
            }

            target {
              schedule {
                monday {
                  startAt
                  endAt
                }
                tuesday {
                  startAt
                  endAt
                }
                wednesday {
                  startAt
                  endAt
                }
                thursday {
                  startAt
                  endAt
                }
                friday {
                  startAt
                  endAt
                }
                saturday {
                  startAt
                  endAt
                }
                sunday {
                  startAt
                  endAt
                }
              }
            }

            budget {
              type
              amount {
                value
              }
            }
          }
        `,
        query.liteAdChanged,
      );

    return data;
  }

  async initContentByExternalSource({
    advertiserId,
    externalSourceId,
    externalSourceType,
  }: {
    advertiserId: string;
    externalSourceId: string;
    externalSourceType: ExternalSourceTypeEnum;
  }) {
    const query = await fetchQuery<liteAdContentInitQuery>(
      relayEnvironment,
      graphql`
        query liteAdContentInitQuery(
          $advertiserId: GraphQLIDInput!
          $externalSourceId: GraphQLIDInput!
          $externalSourceType: ExternalSourceTypeEnum!
        ) {
          externalSource(
            advertiserId: $advertiserId
            externalSourceId: $externalSourceId
            externalSourceType: $externalSourceType
          ) @required(action: THROW) {
            id
            title
            thumbnail
            description

            relateToDeliberationCode(advertiserId: $advertiserId) {
              isRequired
              isTarget
            }
          }
        }
      `,
      {
        advertiserId,
        externalSourceId,
        externalSourceType,
      },
    ).toPromise();

    return query;
  }

  initContentByLiteAdId(
    query: TypeLiteAdServiceMethodReturn<"initStoreByLiteAdId">,
  ) {
    if (!query?.liteAdChanged) {
      return;
    }

    const data = readInlineData<liteAdContentByLiteAdId_LiteAdChanged$key>(
      graphql`
        fragment liteAdContentByLiteAdId_LiteAdChanged on LiteAdChanged
        @inline
        @argumentDefinitions(advertiserId: { type: "GraphQLIDInput!" }) {
          id
          placement
          material {
            deliberationCode
            showRegionName
            synced
            title
            thumbnail
            description

            source(advertiserId: $advertiserId) {
              __typename

              ... on ExternalSource {
                id
                type
                relateToDeliberationCode(advertiserId: $advertiserId) {
                  isRequired
                  isTarget
                }
              }

              ... on OutLinkSource {
                brandName
                ctaType
                price
                targetUrl
              }
            }
          }
        }
      `,
      query.liteAdChanged,
    );

    return data;
  }

  async initGenderAgeByExternalSource({
    advertiserId,
    externalSourceId,
    externalSourceType,
  }: {
    advertiserId: string;
    externalSourceId: string;
    externalSourceType: ExternalSourceTypeEnum;
  }) {
    const externalSourceQuery =
      await fetchQuery<liteAdGenderAgeByExternalSourceQuery>(
        relayEnvironment,
        graphql`
          query liteAdGenderAgeByExternalSourceQuery(
            $advertiserId: GraphQLIDInput!
            $externalSourceId: GraphQLIDInput!
            $externalSourceType: ExternalSourceTypeEnum!
          ) {
            externalSource(
              advertiserId: $advertiserId
              externalSourceId: $externalSourceId
              externalSourceType: $externalSourceType
            ) {
              id
              category {
                id
                _id
              }
            }
          }
        `,
        {
          advertiserId,
          externalSourceId,
          externalSourceType,
        },
      ).toPromise();
    const externalSourceCategoryId =
      externalSourceQuery?.externalSource?.category?._id;

    if (
      !externalSourceCategoryId ||
      (externalSourceType !== "BUSINESS_POST" &&
        externalSourceType !== "BUSINESS_PROFILE")
    ) {
      return;
    }

    const ALPHA_CATEGORY_IDS_MAP = {
      beauty: [
        1055, 1056, 1057, 227, 1108, 1058, 221, 218, 216, 220, 215, 214, 219,
        217, 222, 1269, 225, 223, 1268, 1300, 224, 719, 718, 226, 265, 1164,
      ],
      education: [
        3851, 1104, 703, 1308, 1302, 1310, 1307, 696, 1795, 699, 1103, 701,
        1313, 694, 702, 1270, 705, 1312, 1311, 704,
      ],
    };

    const PROD_CATEGORY_IDS_MAP = {
      beauty: [
        214, 215, 216, 218, 219, 220, 221, 222, 223, 224, 225, 227, 265, 1055,
        1056, 1058, 1164, 1268, 1269, 1300, 2710,
      ],
      education: [
        694, 696, 699, 701, 702, 703, 704, 705, 706, 707, 708, 1103, 1104, 1105,
        1142, 1143, 1270, 1302, 1303, 1304, 1307, 1308, 1310, 1311, 1312, 1313,
        1564, 1795, 1862, 1863, 1864, 1865, 2324, 2325, 2326, 2327, 2328, 2368,
        2370, 2372, 2373, 2387, 2388, 2423, 2698, 2699, 2704, 2719,
      ],
    };

    const GENDER_AGE_RECOMMENDATION_BY_CATEGORY: Record<
      string,
      {
        age: AgeEnum[] | null;
        gender: GenderEnum;
      } | null
    > = {
      beauty: {
        age: null,
        gender: "FEMALE",
      },
      education: {
        age: ["FROM35TO39", "FROM40TO44", "FROM45TO49"],
        gender: "FEMALE",
      },
    };

    const categoryIdMap = IS_PRODUCTION
      ? PROD_CATEGORY_IDS_MAP
      : ALPHA_CATEGORY_IDS_MAP;
    const categoryName = Object.entries(categoryIdMap).find(([_, value]) => {
      return value.includes(Number(externalSourceCategoryId));
    })?.[0];

    return categoryName
      ? GENDER_AGE_RECOMMENDATION_BY_CATEGORY[categoryName]
      : null;
  }

  initGenderAgeByLiteAdId(
    query: TypeLiteAdServiceMethodReturn<"initStoreByLiteAdId">,
  ) {
    if (!query?.liteAdChanged) {
      return;
    }

    const data =
      readInlineData<liteAdInitGenderAgeByLiteAdId_LiteAdChanged$key>(
        graphql`
          fragment liteAdInitGenderAgeByLiteAdId_LiteAdChanged on LiteAdChanged
          @inline {
            target {
              gender
              ages
            }
          }
        `,
        query.liteAdChanged,
      );

    return data;
  }

  initKeywordByLiteAdId(
    query: TypeLiteAdServiceMethodReturn<"initStoreByLiteAdId">,
  ) {
    if (!query?.liteAdChanged) {
      return;
    }

    const data = readInlineData<liteAdInitKeywordByLiteAdId_LiteAdChanged$key>(
      graphql`
        fragment liteAdInitKeywordByLiteAdId_LiteAdChanged on LiteAdChanged
        @inline {
          keywords
        }
      `,
      query.liteAdChanged,
    );

    return data;
  }
  initPlacementByLiteAdId(
    query: TypeLiteAdServiceMethodReturn<"initStoreByLiteAdId">,
  ) {
    if (!query?.liteAdChanged) {
      return;
    }

    const data = readInlineData<liteAdPlacementByLiteAdId_LiteAdChanged$key>(
      graphql`
        fragment liteAdPlacementByLiteAdId_LiteAdChanged on LiteAdChanged
        @inline {
          id
          placement
        }
      `,
      query.liteAdChanged,
    );

    return data;
  }

  async initRegionByExternalSource({
    advertiserId,
    externalSourceId,
    externalSourceType,
    placement,
    regionIds,
  }: {
    advertiserId: string;
    externalSourceId: string;
    externalSourceType: ExternalSourceTypeEnum;
    placement: PlacementEnum;
    regionIds: number[];
  }) {
    const baseRegionQuery = await fetchQuery<liteAdRegionInitQuery>(
      relayEnvironment,
      graphql`
        query liteAdRegionInitQuery(
          $advertiserId: GraphQLIDInput!
          $externalSourceId: GraphQLIDInput!
          $externalSourceType: ExternalSourceTypeEnum!
        ) {
          karrotUser {
            baseRegion {
              _id
              name
            }
          }

          externalSource(
            advertiserId: $advertiserId
            externalSourceId: $externalSourceId
            externalSourceType: $externalSourceType
          ) {
            baseRegion {
              _id
              name
            }

            regionRadius(advertiserId: $advertiserId) {
              supported
              address
              latitude
              longitude
            }
          }
        }
      `,
      {
        advertiserId,
        externalSourceId,
        externalSourceType,
      },
    ).toPromise();

    const baseRegion =
      baseRegionQuery?.externalSource?.baseRegion ??
      baseRegionQuery?.karrotUser?.baseRegion;

    const [regionByIdsQuery, regionQuery] = await Promise.all([
      fetchQuery<liteAdRegionInitRegionByIdsQuery>(
        relayEnvironment,
        graphql`
          query liteAdRegionInitRegionByIdsQuery(
            $regionIds: [GraphQLIDInput!]!
            $hasRegionIds: Boolean!
          ) {
            regionByIds(regionIds: $regionIds) @include(if: $hasRegionIds) {
              id
              name
              name1
              name2
              fullName
              childrenCount
              level
            }
          }
        `,
        {
          hasRegionIds: regionIds.length > 0,
          regionIds: regionIds.map((item) => item.toString()),
        },
      ).toPromise(),
      fetchQuery<liteAdRegionInitRegionQuery>(
        relayEnvironment,
        graphql`
          query liteAdRegionInitRegionQuery(
            $baseRegionId: Int!
            $regionDepth: Int!
            $hasBaseRegionId: Boolean!
          ) {
            regionFixed(baseRegionId: $baseRegionId, regionDepth: $regionDepth)
              @include(if: $hasBaseRegionId) {
              id
              name
              name1
              name2
              fullName
              childrenCount
              level
            }
          }
        `,
        {
          baseRegionId: parseInt(baseRegion?._id ?? "", 10),
          hasBaseRegionId: Boolean(baseRegion?._id),
          regionDepth: match({ externalSourceType, placement })
            .with(
              P.union(
                { placement: "SEARCH_RESULT" },
                { externalSourceType: "COMMERCE" },
              ),
              () => 2,
            )
            .otherwise(() => 3),
        },
      ).toPromise(),
    ]);

    return {
      baseRegionQuery,
      regionByIdsQuery,
      regionQuery,
    };
  }

  initRegionByLiteAdId(
    query: TypeLiteAdServiceMethodReturn<"initStoreByLiteAdId">,
  ) {
    if (!query) {
      return;
    }

    const data = readInlineData<liteAdInitRegionByLiteAdId_Query$key>(
      graphql`
        fragment liteAdInitRegionByLiteAdId_Query on Query
        @inline
        @argumentDefinitions(
          advertiserId: { type: "GraphQLIDInput!" }
          liteAdId: { type: "GraphQLIDInput!" }
        ) {
          karrotUser {
            baseRegion {
              _id
              name
            }
          }

          liteAdChanged(advertiserId: $advertiserId, liteAdId: $liteAdId) {
            id

            material {
              source(advertiserId: $advertiserId) {
                __typename
                ... on ExternalSource {
                  id
                  type
                  baseRegion {
                    _id
                    name
                  }
                  regionRadius(advertiserId: $advertiserId) {
                    address
                    latitude
                    longitude
                    supported
                  }
                }
              }
            }

            target {
              region {
                __typename

                ... on AllRegion {
                  type
                }

                ... on RadiusRegion {
                  address
                  latitude
                  longitude
                  meterRadiusDistance
                }

                ... on RangeRegion {
                  range
                }

                ... on SelectRegion {
                  regions {
                    name
                    fullName
                    childrenCount
                    id
                    level
                    name
                    name1
                    name2
                  }
                }
              }
            }
          }
        }
      `,
      query,
    );

    return data;
  }

  async initRegionByUser() {
    const query = await fetchQuery<liteAdInitRegionByUserQuery>(
      relayEnvironment,
      graphql`
        query liteAdInitRegionByUserQuery {
          karrotUser {
            baseRegion {
              _id
              name
            }
          }
        }
      `,
      {},
    ).toPromise();

    return query;
  }

  async initStoreByLiteAdId({
    advertiserId,
    liteAdId,
  }: {
    advertiserId: string;
    liteAdId: string;
  }) {
    const query = await fetchQuery<liteAdInitStoreByLiteAdIdQuery>(
      relayEnvironment,
      graphql`
        query liteAdInitStoreByLiteAdIdQuery(
          $advertiserId: GraphQLIDInput!
          $liteAdId: GraphQLIDInput!
        ) {
          ...liteAdInitRegionByLiteAdId_Query
            @arguments(advertiserId: $advertiserId, liteAdId: $liteAdId)

          liteAdChanged(advertiserId: $advertiserId, liteAdId: $liteAdId)
            @required(action: THROW) {
            ...liteAdContentByLiteAdId_LiteAdChanged
              @arguments(advertiserId: $advertiserId)
            ...liteAdInitBudgetDurationByLiteAdId_LiteAdChanged
              @arguments(advertiserId: $advertiserId)
            ...liteAdInitGenderAgeByLiteAdId_LiteAdChanged
            ...liteAdPlacementByLiteAdId_LiteAdChanged
            ...liteAdInitKeywordByLiteAdId_LiteAdChanged

            status

            material {
              id
            }
          }
        }
      `,
      {
        advertiserId,
        liteAdId,
      },
    ).toPromise();

    return query;
  }
}

const liteAdService = new LiteAdService();

export type TypeLiteAdServiceMethodReturn<
  T extends keyof typeof liteAdService,
> = Awaited<ReturnType<(typeof liteAdService)[T]>>;
export default liteAdService;
