/**
 * @generated SignedSource<<24b171662c42bc93a3c279fb4cffab7f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type ExternalSourceTypeEnum = "BUSINESS_POST" | "BUSINESS_PROFILE" | "CLASS" | "COMMERCE" | "FARM" | "REALTY" | "TOWN_ARTICLE" | "TOWN_JOB";
export type TargetRegionEnum = "ALL" | "RADIUS" | "RANGE" | "SELECT";
export type TargetRegionRangeEnum = "BIKE" | "CAR" | "HOME" | "WALK";
import { FragmentRefs } from "relay-runtime";
export type liteAdInitRegionByLiteAdId_Query$data = {
  readonly karrotUser: {
    readonly baseRegion: {
      readonly _id: string;
      readonly name: string;
    } | null | undefined;
  } | null | undefined;
  readonly liteAdChanged: {
    readonly id: string;
    readonly material: {
      readonly source: {
        readonly __typename: "ExternalSource";
        readonly baseRegion: {
          readonly _id: string;
          readonly name: string;
        } | null | undefined;
        readonly id: string;
        readonly regionRadius: {
          readonly address: string | null | undefined;
          readonly latitude: string | null | undefined;
          readonly longitude: string | null | undefined;
          readonly supported: boolean;
        } | null | undefined;
        readonly type: ExternalSourceTypeEnum;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      } | null | undefined;
    };
    readonly target: {
      readonly region: {
        readonly __typename: "AllRegion";
        readonly type: TargetRegionEnum;
      } | {
        readonly __typename: "RadiusRegion";
        readonly address: string;
        readonly latitude: string;
        readonly longitude: string;
        readonly meterRadiusDistance: number;
      } | {
        readonly __typename: "RangeRegion";
        readonly range: TargetRegionRangeEnum;
      } | {
        readonly __typename: "SelectRegion";
        readonly regions: ReadonlyArray<{
          readonly childrenCount: number | null | undefined;
          readonly fullName: string | null | undefined;
          readonly id: string;
          readonly level: number | null | undefined;
          readonly name: string | null | undefined;
          readonly name1: string | null | undefined;
          readonly name2: string | null | undefined;
        }>;
      } | {
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        readonly __typename: "%other";
      };
    };
  } | null | undefined;
  readonly " $fragmentType": "liteAdInitRegionByLiteAdId_Query";
};
export type liteAdInitRegionByLiteAdId_Query$key = {
  readonly " $data"?: liteAdInitRegionByLiteAdId_Query$data;
  readonly " $fragmentSpreads": FragmentRefs<"liteAdInitRegionByLiteAdId_Query">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "liteAdInitRegionByLiteAdId_Query"
};

(node as any).hash = "59ca7d5fc79fa558a600ececbe61a19a";

export default node;
