import { StateCreator } from "zustand";

import {
  AgeEnum,
  ExternalSourceTypeEnum,
  GenderEnum,
} from "@/src/graphql/__generated__/resolvers";

import useBusinessUserStore from "../business-user";
import { LiteAdInitialStatus, TypeCreateLiteAdStore } from "./lite-ad";
import { TypeLiteAdGenderAgeFormSchema } from "./lite-ad.schema";
import liteAdService, {
  TypeLiteAdServiceMethodReturn,
} from "./lite-ad.service";

export type TypeGenderAgeState = {
  ageRecommendation?: AgeEnum[] | null;
  ages: AgeEnum[];
  gender: GenderEnum[];
  genderAgeInitialStatus: LiteAdInitialStatus;
  genderRecommendation?: GenderEnum[] | null;
};

export type TypeGenderAgeAction = {
  genderAgeExternalSourceInit: (
    externalSourceId: string,
    externalSourceType: ExternalSourceTypeEnum,
  ) => void;
  genderAgeLiteAdInit: (
    query: TypeLiteAdServiceMethodReturn<"initStoreByLiteAdId">,
  ) => void;
  genderAgeOutlinkInit: () => void;
  genderAgeSetStatus: (status: LiteAdInitialStatus) => void;
  submitGenderAgeForm: (data: TypeLiteAdGenderAgeFormSchema) => void;
};

export const genderAgeInitialState: TypeGenderAgeState = {
  ageRecommendation: null,
  ages: ["ALL"],
  gender: ["ALL"],
  genderAgeInitialStatus: "initial",
  genderRecommendation: null,
};

export const createGenderAgeSlice: StateCreator<
  TypeCreateLiteAdStore,
  [["zustand/devtools", never]],
  [],
  TypeGenderAgeState & TypeGenderAgeAction
> = (_, get) => {
  return {
    ...genderAgeInitialState,

    genderAgeExternalSourceInit: async (
      externalSourceId,
      externalSourceType,
    ) => {
      const { genderAgeSetStatus, setState } = get();
      const { getCurrentAdvertiser } = useBusinessUserStore.getState();
      const advertiser = getCurrentAdvertiser();
      const response = await liteAdService.initGenderAgeByExternalSource({
        advertiserId: advertiser.id,
        externalSourceId,
        externalSourceType,
      });
      setState(
        {
          ...(response?.age && {
            ageRecommendation: response.age,
            ages: response.age,
          }),
          ...(response?.gender && {
            gender: [response.gender],
            genderRecommendation: [response.gender],
          }),
        },
        "genderAgeExternalSourceInit",
      );
      genderAgeSetStatus("success");
    },

    genderAgeLiteAdInit: (query) => {
      const { genderAgeSetStatus, setState } = get();
      const response = liteAdService.initGenderAgeByLiteAdId(query);

      genderAgeSetStatus("loading");
      setState(
        {
          ages: [...(response?.target.ages || [])],
          gender: response?.target.gender ? [response.target.gender] : ["ALL"],
        },
        "genderAgeLiteAdInit",
      );
      genderAgeSetStatus("success");
    },

    genderAgeOutlinkInit: () => {
      const { genderAgeSetStatus } = get();
      genderAgeSetStatus("success");
    },

    genderAgeSetStatus: (status) => {
      const { setState } = get();
      setState(
        {
          genderAgeInitialStatus: status,
        },
        "genderAgeSetStatus",
      );
    },

    submitGenderAgeForm: (data) => {
      const { setState } = get();
      setState(data, "submitGenderAgeForm");
    },
  };
};
