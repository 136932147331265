import { StateCreator, create } from "zustand";
import {
  createJSONStorage,
  devtools,
  persist,
  subscribeWithSelector,
} from "zustand/middleware";
import { immer } from "zustand/middleware/immer";
import { useShallow } from "zustand/react/shallow";
import { shallow } from "zustand/shallow";

import {
  ExternalSourceTypeEnum,
  LiteAdAddInput,
  LiteAdStatusEnum,
} from "@/src/graphql/__generated__/resolvers";

import createSelectors from "../_shared/create-selectors";
import useBusinessUserStore from "../business-user";
import {
  TypeBudgetDurationAction,
  TypeBudgetDurationState,
  createBudgetDurationSlice,
} from "./lite-ad-budget-duration.slice";
import {
  TypeCreateContentAction,
  TypeCreateContentState,
  createContentSlice,
} from "./lite-ad-content.slice";
import {
  TypeGenderAgeAction,
  TypeGenderAgeState,
  createGenderAgeSlice,
} from "./lite-ad-gender-age.slice";
import liteAdPayloadMapper from "./lite-ad-payload.mapper";
import {
  TypeCreatePlacementAction,
  TypeCreatePlacementState,
  createPlacementSlice,
} from "./lite-ad-placement.slice";
import {
  TypeRegionAction,
  TypeRegionState,
  createRegionSlice,
} from "./lite-ad-region.slice";
import {
  SearchKeywordAction,
  SearchKeywordState,
  createSearchKeywordSlice,
} from "./lite-ad-search-keyword.slice";
import liteAdMapper from "./lite-ad.mapper";
import liteAdService, {
  TypeLiteAdServiceMethodReturn,
} from "./lite-ad.service";
import storage from "./lite-ad.storage";

// shared slice
export type LiteAdInitialStatus = "initial" | "loading" | "success";
export const LITE_AD_MODE_ENUM = ["create", "edit", "editAll"] as const;
export type LiteAdMode = (typeof LITE_AD_MODE_ENUM)[number];

type TypeSharedState = {
  liteAdId?: null | string;
  liteAdStatus?: LiteAdStatusEnum | null;
  /**
   * @description 광고 수정시 필요해요
   */
  materialId?: null | string;
  mode: LiteAdMode;
  /**
   * @description 비즈프로필에서 진입시에 초기 세팅할 regionId 리스트
   */
  regionIds: number[];
};

export type TypeSharedAction = {
  /**
   * @description persistStorage에서 초기 params 값으로 initialize해요
   */
  initialize: () => void;
  /**
   * @description externalSource 값으로 initialize해요
   */
  initializeByExternalSource: (
    externalSourceId: string,
    externalSourceType: ExternalSourceTypeEnum,
  ) => Promise<void>;
  /**
   * @description externalSource 값으로 initialize해요
   */
  initializeByLiteAdId: (liteAdId: string) => Promise<void>;
  /**
   * @description store를 초기화해요
   */
  reset: () => void;
  /**
   * @description 광고생성시 state값을 payload로 serialize해요
   */
  serialize: () => LiteAdAddInput;
  /**
   * @description liteAd store의 mode와 필요한 데이터를 처리해요
   */
  setMode: (
    data:
      | {
          liteAdId: string;
          mode: "editAll";
        }
      | {
          mode: "create";
        }
      | { liteAdId: string; mode: "edit" },
  ) => void;
  /**
   * @description replace하지 않는 set 함수
   */
  setState: (state: Partial<TypeCreateLiteAdStore>, fnName: string) => void;
  /**
   * @description
   */
  sharedLiteAdInit: (
    query: TypeLiteAdServiceMethodReturn<"initStoreByLiteAdId">,
  ) => void;
};

export const sharedInitialState: TypeSharedState = {
  liteAdId: null,
  liteAdStatus: null,
  materialId: null,
  mode: "create",
  regionIds: [],
};

const createSharedSlice: StateCreator<
  TypeCreateLiteAdStore,
  [["zustand/devtools", never]],
  [],
  TypeSharedState & TypeSharedAction
> = (set, get) => ({
  ...sharedInitialState,
  initialize: async () => {
    const {
      externalSourceId,
      externalSourceType,
      initializeByExternalSource,
      initializeByLiteAdId,
      liteAdId,
    } = get();

    if (externalSourceId && externalSourceType) {
      initializeByExternalSource(externalSourceId, externalSourceType);
    }

    if (liteAdId) {
      initializeByLiteAdId(liteAdId);
    }
  },
  initializeByExternalSource: async (externalSourceId, externalSourceType) => {
    const {
      budgetDurationExternalSourceInit,
      contentExternalSourceInit,
      genderAgeExternalSourceInit,
      placement,
      regionExternalSourceInit,
      searchKeywordExternalSourceInit,
    } = get();

    await Promise.all([
      contentExternalSourceInit(externalSourceId, externalSourceType),
      regionExternalSourceInit(externalSourceId, externalSourceType),
      budgetDurationExternalSourceInit({
        externalSourceId,
        externalSourceType,
        placement,
      }),
      genderAgeExternalSourceInit(externalSourceId, externalSourceType),
      searchKeywordExternalSourceInit(),
    ]);
  },
  initializeByLiteAdId: async (liteAdId) => {
    const {
      budgetDurationLiteAdInit,
      contentLiteAdInit,
      genderAgeLiteAdInit,
      placementLiteAdInit,
      regionLiteAdInit,
      searchKeywordLiteAdInit,
      sharedLiteAdInit,
    } = get();
    const { getCurrentAdvertiser } = useBusinessUserStore.getState();
    const advertiser = getCurrentAdvertiser();
    const query = await liteAdService.initStoreByLiteAdId({
      advertiserId: advertiser.id,
      liteAdId,
    });

    sharedLiteAdInit(query);
    placementLiteAdInit(query);
    contentLiteAdInit(query);
    regionLiteAdInit(query);
    genderAgeLiteAdInit(query);
    budgetDurationLiteAdInit(query);
    searchKeywordLiteAdInit(query);
  },
  reset: () => {
    const { setState } = get();
    setState(liteAdMapper.toStateReset(), "reset");
  },
  serialize: () => liteAdPayloadMapper.toLiteAdAddInput(get()),
  setMode: (data) => {
    const { reset, setState } = get();

    reset();
    setState(liteAdMapper.toStateSetMode(data), "setMode");
  },
  setState: (state, fnName) => {
    set(state, false, fnName);
  },
  sharedLiteAdInit: (query) => {
    const { setState } = get();
    setState(
      {
        liteAdStatus: query?.liteAdChanged.status,
        materialId: query?.liteAdChanged.material.id,
      },
      "sharedLiteAdInit",
    );
  },
});

export type TypeCreateLiteAdStoreState = TypeCreatePlacementState &
  TypeSharedState &
  TypeCreateContentState &
  TypeRegionState &
  TypeGenderAgeState &
  TypeBudgetDurationState &
  SearchKeywordState;
type TypeCreateLiteAdStoreAction = TypeCreatePlacementAction &
  TypeSharedAction &
  TypeCreateContentAction &
  TypeRegionAction &
  TypeGenderAgeAction &
  TypeBudgetDurationAction &
  SearchKeywordAction;
export type TypeCreateLiteAdStore = TypeCreateLiteAdStoreState &
  TypeCreateLiteAdStoreAction;

const useLiteAdStore = createSelectors(
  create<TypeCreateLiteAdStore>()(
    subscribeWithSelector(
      persist(
        immer(
          devtools(
            (...args) => ({
              ...createSharedSlice(...args),
              ...createPlacementSlice(...args),
              ...createContentSlice(...args),
              ...createRegionSlice(...args),
              ...createGenderAgeSlice(...args),
              ...createBudgetDurationSlice(...args),
              ...createSearchKeywordSlice(...args),
            }),
            { name: "create-lite-ad", store: "create-lite-ad" },
          ),
        ),
        {
          name: "createLiteAdStore",
          storage: createJSONStorage<TypeCreateLiteAdStore>(() => storage),
        },
      ),
    ),
  ),
);

const initStore = () => {
  const { advertiser } = useBusinessUserStore.getState();
  const { initialize } = useLiteAdStore.getState();

  if (advertiser) {
    initialize();
    return;
  }

  const unsubscribe = useBusinessUserStore.subscribe(({ advertiser }) => {
    if (!advertiser) {
      return;
    }

    initialize();
    unsubscribe();
  });
};

// url에서 초기값 세팅시에 체크해서 initialize해요
initStore();

// liteAdId가 달라지면 store 상태들을 수정해요
useLiteAdStore.subscribe(
  (state) => [state.liteAdId],
  async ([liteAdId]) => {
    const { initializeByLiteAdId } = useLiteAdStore.getState();
    if (!liteAdId) {
      return;
    }

    initializeByLiteAdId(liteAdId);
  },
  { equalityFn: shallow },
);

// externalSource가 변경되었을 때 초기 값을 세팅해요
useLiteAdStore.subscribe(
  (state) => [state.liteAdId, state.externalSourceId, state.externalSourceType],
  ([liteAdId, externalSourceId, externalSourceType]) => {
    const { initializeByExternalSource } = useLiteAdStore.getState();

    // liteAdId가 있을 때에는 liteAdId 기준으로 initialize해요
    if (liteAdId) {
      return;
    }

    if (!externalSourceId || !externalSourceType) {
      return;
    }

    initializeByExternalSource(
      externalSourceId,
      externalSourceType as ExternalSourceTypeEnum,
    );
  },
  { equalityFn: shallow },
);

export const useLiteAdStoreShallow = <
  T extends (state: TypeCreateLiteAdStore) => ReturnType<T>,
>(
  selector: T,
) => {
  return useLiteAdStore(useShallow(selector));
};

export default useLiteAdStore;
