/**
 * @generated SignedSource<<2c7a74d19eed83e04a6a677b55a25851>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type liteAdRegionInitRegionByIdsQuery$variables = {
  hasRegionIds: boolean;
  regionIds: ReadonlyArray<CustomScalars["GraphQLIDInput"]>;
};
export type liteAdRegionInitRegionByIdsQuery$data = {
  readonly regionByIds?: ReadonlyArray<{
    readonly childrenCount: number | null | undefined;
    readonly fullName: string | null | undefined;
    readonly id: string;
    readonly level: number | null | undefined;
    readonly name: string | null | undefined;
    readonly name1: string | null | undefined;
    readonly name2: string | null | undefined;
  }> | null | undefined;
};
export type liteAdRegionInitRegionByIdsQuery = {
  response: liteAdRegionInitRegionByIdsQuery$data;
  variables: liteAdRegionInitRegionByIdsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "hasRegionIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "regionIds"
},
v2 = [
  {
    "condition": "hasRegionIds",
    "kind": "Condition",
    "passingValue": true,
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Variable",
            "name": "regionIds",
            "variableName": "regionIds"
          }
        ],
        "concreteType": "Region",
        "kind": "LinkedField",
        "name": "regionByIds",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name1",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name2",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "fullName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "childrenCount",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "level",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "liteAdRegionInitRegionByIdsQuery",
    "selections": (v2/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "liteAdRegionInitRegionByIdsQuery",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "b7d7f8a09c420d4f060323ca0e93a915",
    "id": null,
    "metadata": {},
    "name": "liteAdRegionInitRegionByIdsQuery",
    "operationKind": "query",
    "text": "query liteAdRegionInitRegionByIdsQuery(\n  $regionIds: [GraphQLIDInput!]!\n  $hasRegionIds: Boolean!\n) {\n  regionByIds(regionIds: $regionIds) @include(if: $hasRegionIds) {\n    id\n    name\n    name1\n    name2\n    fullName\n    childrenCount\n    level\n  }\n}\n"
  }
};
})();

(node as any).hash = "99e393ec3763c38726b3ce63ba532d74";

export default node;
