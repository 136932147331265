import reactRoutes from "~react-pages";

import {
  TypeStackflowActivities,
  loaders,
} from "./__generated__/generated-activities";

const activities: TypeStackflowActivities = reactRoutes.reduce(
  (current, next) => {
    current[next.path] = next.element;

    return current;
  },
  {},
);

const routes = reactRoutes.reduce(
  (current, next) => {
    current[next.path] = next.path === "/" ? next.path : `/${next.path}`;
    return current;
  },
  {} as Record<string, string>,
);

export { activities, loaders, routes };
