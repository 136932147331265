import React from "react";

import { useBusinessUserStoreShallow } from "@/src/store/business-user";

import Responsive from "../_shared/responsive";
import AdvertiserSelectorDesktop from "./desktop/advertiser-selector-desktop";
import AccountSelectorMobile from "./mobile/advertiser-selector-mobile";

const AccountSelectorController = () => {
  const { advertiser, advertisers, setAdvertiser } =
    useBusinessUserStoreShallow((state) => ({
      advertiser: state.advertiser,
      advertisers: state.advertisers,
      setAdvertiser: state.setAdvertiser,
    }));

  return (
    <Responsive
      desktop={
        <AdvertiserSelectorDesktop
          advertiser={advertiser}
          advertisers={advertisers}
          setAdvertiser={setAdvertiser}
        />
      }
      mobile={<AccountSelectorMobile advertiser={advertiser} />}
    />
  );
};

export default AccountSelectorController;
