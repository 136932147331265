import { env } from "../../environment";
import { bridge } from "../bridge";
import { handleAppAction } from "../lib/webview";

export async function logShownEvent(
  eventName: string,
  payload: Record<string, boolean | number | string | undefined>,
) {
  if (env.STAGE === "development") {
    console.log(
      `%c [ShownEvent] ${eventName}\n${JSON.stringify(payload)}`,
      "background: #222; color: #c1d8f5",
    );
    return;
  }

  logEvent(eventName, payload);
}

export async function logClickedEvent(
  eventName: string,
  payload: Record<string, boolean | number | string | undefined>,
) {
  if (env.STAGE === "development") {
    console.log(
      `%c [ClickedEvent] ${eventName}\n${JSON.stringify(payload)}`,
      "background: #222; color: #ffcea1",
    );
    return;
  }

  logEvent(eventName, payload);
}
export function logEvent(
  eventName: string,
  payload: Record<string, boolean | number | string | undefined>,
) {
  const action = handleAppAction({
    onBrowserAction: () => {
      // TODO: 브라우저에서는 어떻게 처리할지 결정해야 함 GA?
      return;
    },
    onWebviewAction: () => {
      return bridge.logEvent({
        analytics: {
          name: eventName,
          params: JSON.stringify(payload),
          target: "KARROT",
        },
      });
    },
  });

  return action();
}
