import { fetchQuery, graphql } from "react-relay";

import { liteAdMeta_Ad_Id_Query } from "@/src/graphql/__relay__/liteAdMeta_Ad_Id_Query.graphql";
import { liteAdMeta_Material_Source_Id_Query } from "@/src/graphql/__relay__/liteAdMeta_Material_Source_Id_Query.graphql";
import { liteAdMeta_Material_Source_Owner_Id_Query } from "@/src/graphql/__relay__/liteAdMeta_Material_Source_Owner_Id_Query.graphql";
import relayEnvironment from "@/src/graphql/relayEnvironment";

const getLiteAdRealAdId = async (variables: {
  advertiserId: string;
  liteAdId: string;
}) => {
  const result = await fetchQuery<liteAdMeta_Ad_Id_Query>(
    relayEnvironment,
    graphql`
      query liteAdMeta_Ad_Id_Query(
        $liteAdId: GraphQLIDInput!
        $advertiserId: GraphQLIDInput!
      ) {
        liteAd(advertiserId: $advertiserId, liteAdId: $liteAdId) {
          _id
        }
      }
    `,
    variables,
    {
      fetchPolicy: "store-or-network",
    },
  ).toPromise();

  return result?.liteAd?._id;
};

const getLiteAdMaterialSourceId = async (variables: {
  advertiserId: string;
  liteAdId: string;
}) => {
  const result = await fetchQuery<liteAdMeta_Material_Source_Id_Query>(
    relayEnvironment,
    graphql`
      query liteAdMeta_Material_Source_Id_Query(
        $liteAdId: GraphQLIDInput!
        $advertiserId: GraphQLIDInput!
      ) {
        liteAd(advertiserId: $advertiserId, liteAdId: $liteAdId) {
          material {
            source(advertiserId: $advertiserId) {
              ... on ExternalSource {
                _id
              }
            }
          }
        }
      }
    `,
    variables,
    {
      fetchPolicy: "store-or-network",
    },
  ).toPromise();

  return result?.liteAd?.material.source?._id;
};

const getLiteAdSourceOwnerId = async (variables: {
  advertiserId: string;
  liteAdId: string;
}) => {
  const result = await fetchQuery<liteAdMeta_Material_Source_Owner_Id_Query>(
    relayEnvironment,
    graphql`
      query liteAdMeta_Material_Source_Owner_Id_Query(
        $liteAdId: GraphQLIDInput!
        $advertiserId: GraphQLIDInput!
      ) {
        liteAd(advertiserId: $advertiserId, liteAdId: $liteAdId) {
          _id
          material {
            source(advertiserId: $advertiserId) {
              ... on ExternalSource {
                owner {
                  _id
                }
              }
            }
          }
        }
      }
    `,
    variables,
    {
      fetchPolicy: "store-or-network",
    },
  ).toPromise();

  return result?.liteAd?.material.source?.owner?._id;
};

const metaLoaders = {
  getLiteAdMaterialSourceId,
  getLiteAdRealAdId,
  getLiteAdSourceOwnerId,
};

export default metaLoaders;
