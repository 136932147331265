/**
 * @generated SignedSource<<b17bd794642d1cedee480ce7e6cf8e4f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type CtaTypeEnum = "APPLY" | "CONTACTUS" | "PURCHASING" | "SHORTCUTS";
export type ExternalSourceTypeEnum = "BUSINESS_POST" | "BUSINESS_PROFILE" | "CLASS" | "COMMERCE" | "FARM" | "REALTY" | "TOWN_ARTICLE" | "TOWN_JOB";
export type PlacementEnum = "HOME_FEED" | "SEARCH_RESULT";
import { FragmentRefs } from "relay-runtime";
export type liteAdContentByLiteAdId_LiteAdChanged$data = {
  readonly id: string;
  readonly material: {
    readonly deliberationCode: string | null | undefined;
    readonly description: string | null | undefined;
    readonly showRegionName: boolean | null | undefined;
    readonly source: {
      readonly __typename: "ExternalSource";
      readonly id: string;
      readonly relateToDeliberationCode: {
        readonly isRequired: boolean;
        readonly isTarget: boolean;
      } | null | undefined;
      readonly type: ExternalSourceTypeEnum;
    } | {
      readonly __typename: "OutLinkSource";
      readonly brandName: string | null | undefined;
      readonly ctaType: CtaTypeEnum;
      readonly price: number | null | undefined;
      readonly targetUrl: any;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
    readonly synced: boolean | null | undefined;
    readonly thumbnail: any;
    readonly title: string;
  };
  readonly placement: PlacementEnum;
  readonly " $fragmentType": "liteAdContentByLiteAdId_LiteAdChanged";
};
export type liteAdContentByLiteAdId_LiteAdChanged$key = {
  readonly " $data"?: liteAdContentByLiteAdId_LiteAdChanged$data;
  readonly " $fragmentSpreads": FragmentRefs<"liteAdContentByLiteAdId_LiteAdChanged">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "liteAdContentByLiteAdId_LiteAdChanged"
};

(node as any).hash = "4970e9c05c6d1987ceeffa4da515992e";

export default node;
