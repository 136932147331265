/**
 * @generated SignedSource<<09210cece85adb692a0c9d1836fe2352>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type liteAdMeta_Material_Source_Id_Query$variables = {
  advertiserId: CustomScalars["GraphQLIDInput"];
  liteAdId: CustomScalars["GraphQLIDInput"];
};
export type liteAdMeta_Material_Source_Id_Query$data = {
  readonly liteAd: {
    readonly material: {
      readonly source: {
        readonly _id?: string;
      } | null | undefined;
    };
  } | null | undefined;
};
export type liteAdMeta_Material_Source_Id_Query = {
  response: liteAdMeta_Material_Source_Id_Query$data;
  variables: liteAdMeta_Material_Source_Id_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "advertiserId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "liteAdId"
},
v2 = {
  "kind": "Variable",
  "name": "advertiserId",
  "variableName": "advertiserId"
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "liteAdId",
    "variableName": "liteAdId"
  }
],
v4 = [
  (v2/*: any*/)
],
v5 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "_id",
      "storageKey": null
    }
  ],
  "type": "ExternalSource",
  "abstractKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "liteAdMeta_Material_Source_Id_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "LiteAd",
        "kind": "LinkedField",
        "name": "liteAd",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Material",
            "kind": "LinkedField",
            "name": "material",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "source",
                "plural": false,
                "selections": [
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "liteAdMeta_Material_Source_Id_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "LiteAd",
        "kind": "LinkedField",
        "name": "liteAd",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Material",
            "kind": "LinkedField",
            "name": "material",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "source",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "07837b4e66e6e486a4a5edcc90ea4903",
    "id": null,
    "metadata": {},
    "name": "liteAdMeta_Material_Source_Id_Query",
    "operationKind": "query",
    "text": "query liteAdMeta_Material_Source_Id_Query(\n  $liteAdId: GraphQLIDInput!\n  $advertiserId: GraphQLIDInput!\n) {\n  liteAd(advertiserId: $advertiserId, liteAdId: $liteAdId) {\n    material {\n      source(advertiserId: $advertiserId) {\n        __typename\n        ... on ExternalSource {\n          _id\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "791f34669bd31fd0978a268a61938fae";

export default node;
