/**
 * @generated SignedSource<<b7391aa4a6ec8358500765d8eef6bc86>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type AdvertiserModeEnum = "LITE" | "PRO";
export type AdvertiserStatusEnum = "ACTIVE" | "BAN" | "DELETE";
export type BusinessUserTermsCommandTypeEnum = "MARKETING_CHAT" | "MARKETING_EMAIL" | "MARKETING_PUSH" | "PRIVACY" | "UNIFIED";
export type ManagerStatusEnum = "ACCEPT" | "DELETE" | "DENY" | "PENDING";
export type TermsStatusEnum = "NEED_RENEW" | "NEED_SIGNUP" | "NO_NEED";
export type businessUserInitQuery$variables = Record<PropertyKey, never>;
export type businessUserInitQuery$data = {
  readonly me: {
    readonly advertisers: ReadonlyArray<{
      readonly _id: string;
      readonly activeAdCount: number;
      readonly admins: ReadonlyArray<{
        readonly _id: string;
        readonly id: string;
        readonly karrotUserId: number | null | undefined;
        readonly status: ManagerStatusEnum;
      }>;
      readonly freeCash: {
        readonly value: number;
      };
      readonly id: string;
      readonly mode: AdvertiserModeEnum;
      readonly name: string;
      readonly owner: {
        readonly _id: string;
        readonly id: string;
        readonly karrotUserId: number | null | undefined;
        readonly status: ManagerStatusEnum;
      } | null | undefined;
      readonly prepaidCash: {
        readonly value: number;
      };
      readonly status: {
        readonly endAt?: string | null | undefined;
        readonly reason?: string | null | undefined;
        readonly status?: AdvertiserStatusEnum;
      } | null | undefined;
    }> | null | undefined;
    readonly businessProfiles: ReadonlyArray<{
      readonly id: string;
    }> | null | undefined;
    readonly id: string;
    readonly latestAdvertiser: {
      readonly _id: string;
      readonly activeAdCount: number;
      readonly admins: ReadonlyArray<{
        readonly _id: string;
        readonly id: string;
        readonly karrotUserId: number | null | undefined;
        readonly status: ManagerStatusEnum;
      }>;
      readonly freeCash: {
        readonly value: number;
      };
      readonly id: string;
      readonly mode: AdvertiserModeEnum;
      readonly name: string;
      readonly owner: {
        readonly _id: string;
        readonly id: string;
        readonly karrotUserId: number | null | undefined;
        readonly status: ManagerStatusEnum;
      } | null | undefined;
      readonly prepaidCash: {
        readonly value: number;
      };
      readonly status: {
        readonly endAt?: string | null | undefined;
        readonly reason?: string | null | undefined;
        readonly status?: AdvertiserStatusEnum;
      } | null | undefined;
    } | null | undefined;
    readonly termsList: ReadonlyArray<{
      readonly agree: boolean;
      readonly type: BusinessUserTermsCommandTypeEnum;
    }> | null | undefined;
    readonly termsStatus: TermsStatusEnum;
  };
};
export type businessUserInitQuery = {
  response: businessUserInitQuery$data;
  variables: businessUserInitQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "activeAdCount",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mode",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = [
  (v5/*: any*/)
],
v7 = {
  "kind": "InlineFragment",
  "selections": (v6/*: any*/),
  "type": "AdvertiserActiveStatus",
  "abstractKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": (v6/*: any*/),
  "type": "AdvertiserDeleteStatus",
  "abstractKey": null
},
v9 = {
  "kind": "InlineFragment",
  "selections": [
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "reason",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endAt",
      "storageKey": null
    }
  ],
  "type": "AdvertiserBanStatus",
  "abstractKey": null
},
v10 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "FreeCash",
  "kind": "LinkedField",
  "name": "freeCash",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Prepaid",
  "kind": "LinkedField",
  "name": "prepaidCash",
  "plural": false,
  "selections": (v10/*: any*/),
  "storageKey": null
},
v13 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "karrotUserId",
    "storageKey": null
  }
],
v14 = {
  "alias": null,
  "args": null,
  "concreteType": "Admin",
  "kind": "LinkedField",
  "name": "admins",
  "plural": true,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "concreteType": "Owner",
  "kind": "LinkedField",
  "name": "owner",
  "plural": false,
  "selections": (v13/*: any*/),
  "storageKey": null
},
v16 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "status",
    "plural": false,
    "selections": [
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "storageKey": null
  },
  (v11/*: any*/),
  (v12/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/)
],
v17 = {
  "alias": null,
  "args": null,
  "concreteType": "BusinessProfile",
  "kind": "LinkedField",
  "name": "businessProfiles",
  "plural": true,
  "selections": [
    (v0/*: any*/)
  ],
  "storageKey": null
},
v18 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "termsStatus",
  "storageKey": null
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "BusinessUserTermsCommand",
  "kind": "LinkedField",
  "name": "termsList",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "agree",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v20 = [
  (v0/*: any*/),
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/),
  (v4/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": null,
    "kind": "LinkedField",
    "name": "status",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "__typename",
        "storageKey": null
      },
      (v7/*: any*/),
      (v8/*: any*/),
      (v9/*: any*/)
    ],
    "storageKey": null
  },
  (v11/*: any*/),
  (v12/*: any*/),
  (v14/*: any*/),
  (v15/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "businessUserInitQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "BusinessUser",
          "kind": "LinkedField",
          "name": "me",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Advertiser",
              "kind": "LinkedField",
              "name": "advertisers",
              "plural": true,
              "selections": (v16/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Advertiser",
              "kind": "LinkedField",
              "name": "latestAdvertiser",
              "plural": false,
              "selections": (v16/*: any*/),
              "storageKey": null
            },
            (v17/*: any*/),
            (v18/*: any*/),
            (v19/*: any*/)
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "me"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "businessUserInitQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "BusinessUser",
        "kind": "LinkedField",
        "name": "me",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Advertiser",
            "kind": "LinkedField",
            "name": "advertisers",
            "plural": true,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Advertiser",
            "kind": "LinkedField",
            "name": "latestAdvertiser",
            "plural": false,
            "selections": (v20/*: any*/),
            "storageKey": null
          },
          (v17/*: any*/),
          (v18/*: any*/),
          (v19/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f38474436a0f439741aa410f36e2aed2",
    "id": null,
    "metadata": {},
    "name": "businessUserInitQuery",
    "operationKind": "query",
    "text": "query businessUserInitQuery {\n  me {\n    id\n    advertisers {\n      id\n      _id\n      name\n      activeAdCount\n      mode\n      status {\n        __typename\n        ... on AdvertiserActiveStatus {\n          status\n        }\n        ... on AdvertiserDeleteStatus {\n          status\n        }\n        ... on AdvertiserBanStatus {\n          status\n          reason\n          endAt\n        }\n      }\n      freeCash {\n        value\n      }\n      prepaidCash {\n        value\n      }\n      admins {\n        id\n        _id\n        status\n        karrotUserId\n      }\n      owner {\n        id\n        _id\n        status\n        karrotUserId\n      }\n    }\n    latestAdvertiser {\n      id\n      _id\n      name\n      activeAdCount\n      mode\n      status {\n        __typename\n        ... on AdvertiserActiveStatus {\n          status\n        }\n        ... on AdvertiserDeleteStatus {\n          status\n        }\n        ... on AdvertiserBanStatus {\n          status\n          reason\n          endAt\n        }\n      }\n      freeCash {\n        value\n      }\n      prepaidCash {\n        value\n      }\n      admins {\n        id\n        _id\n        status\n        karrotUserId\n      }\n      owner {\n        id\n        _id\n        status\n        karrotUserId\n      }\n    }\n    businessProfiles {\n      id\n    }\n    termsStatus\n    termsList {\n      type\n      agree\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "80c2c00fbd075691ea4a90e52a99bc5e";

export default node;
