import { parseAppInfoSafe } from "@daangn/webview-ua-parser";

import { env } from "../../environment";
import useBusinessUserStore from "../store/business-user";
import { createWebviewLinkHandler } from "../utils/link";

export const appInfo = parseAppInfoSafe(window.navigator.userAgent);
export const isWebview = Boolean(appInfo);

export const handleAppAction =
  <T extends unknown[]>({
    onBrowserAction,
    onWebviewAction,
  }: {
    onBrowserAction?: (ctx: undefined, ...args: T) => void;
    onWebviewAction?: (ctx: NonNullable<typeof appInfo>, ...args: T) => void;
  }) =>
  (...args: T) => {
    if (appInfo) {
      onWebviewAction?.(appInfo, ...args);
      return;
    }
    onBrowserAction?.(appInfo, ...args);
  };

type KotisaariWebviewPaths =
  /* 캐시 내역 */
  | "/account/submit-document"
  /* 메뉴 */
  | "/finances"
  /* 자동충전 설정 */
  | "/finances/auto-reload"
  /* 캐시 충전 */
  | "/finances/auto-reload/register"
  /* 트렌드 나우 */
  | "/finances/cash"
  /* 자동충전 관리  */
  | "/menu-drawer"
  /* 심사서류 제출 */
  | "/popular-advertisements";

const KOTISAARI_WEBVIEW_PARAMS_BY_PATH: Partial<
  Record<KotisaariWebviewPaths, Record<string, string>>
> = {
  "/popular-advertisements": {
    showCTA: "true",
    targetType: "BUSINESS_ACCOUNT",
  },
};

// TODO: 사용처들에 대한 desktop 처리 추후에 필요
export const moveToKotisaariWebview = (path: KotisaariWebviewPaths) => {
  const advertiser = useBusinessUserStore.getState().getCurrentAdvertiser();
  const parmas = new URLSearchParams({
    advertiserId: advertiser._id,
    referrer: "kotisaari-lite",
    ...(KOTISAARI_WEBVIEW_PARAMS_BY_PATH[path] ?? {}),
  }).toString();

  return createWebviewLinkHandler(
    `${env.KOTISAARI_WEBVIEW_HOST}${path}?${parmas}`,
  );
};
