import { StateCreator } from "zustand";

import { LiteAdInitialStatus, TypeCreateLiteAdStore } from "./lite-ad";
import { LiteAdSearchKeywordsFormSchema } from "./lite-ad.schema";
import liteAdService, {
  TypeLiteAdServiceMethodReturn,
} from "./lite-ad.service";

export type SearchKeywordState = {
  keywords: string[];
  searchKeywordInitialState: LiteAdInitialStatus;
};

export type SearchKeywordAction = {
  searchKeywordExternalSourceInit: () => void;
  searchKeywordLiteAdInit: (
    query: TypeLiteAdServiceMethodReturn<"initStoreByLiteAdId">,
  ) => void;
  searchKeywordSetStatus: (status: LiteAdInitialStatus) => void;
  submitSearchKeywordForm: (data: LiteAdSearchKeywordsFormSchema) => void;
};

export const searchKeywordInitialState: SearchKeywordState = {
  keywords: [],
  searchKeywordInitialState: "initial",
};

export const createSearchKeywordSlice: StateCreator<
  TypeCreateLiteAdStore,
  [["zustand/devtools", never]],
  [],
  SearchKeywordState & SearchKeywordAction
> = (_, get) => {
  return {
    ...searchKeywordInitialState,
    searchKeywordExternalSourceInit: () => {
      const { searchKeywordSetStatus } = get();
      searchKeywordSetStatus("success");
    },
    searchKeywordLiteAdInit: (query) => {
      const { searchKeywordSetStatus, setState } = get();
      searchKeywordSetStatus("loading");
      const response = liteAdService.initKeywordByLiteAdId(query);
      setState(
        { keywords: [...(response?.keywords ?? [])] },
        "searchKeywordLiteAdInit",
      );
      searchKeywordSetStatus("success");
    },
    searchKeywordSetStatus: (status) => {
      const { setState } = get();
      setState({ searchKeywordInitialState: status }, "searchKeywordSetStatus");
    },
    submitSearchKeywordForm: (data) => {
      const { setState } = get();
      setState(data, "submitSearchkeywordForm");
    },
  };
};
