import * as React from "react";
import { graphql, useMutation } from "react-relay";
import { P, match } from "ts-pattern";

import { advertiserOnboardingCashMutation } from "@/src/graphql/__relay__/advertiserOnboardingCashMutation.graphql";
import useBusinessUserStore from "@/src/store/business-user";
import { commaize } from "@/src/utils/commaize";

import { useAddDialog } from "../_shared/dialog/dialog-provider";
import PageLayout from "../_shared/page-layout";
import { useSnackbar } from "../_shared/snackbar";

// @see https://daangn.slack.com/archives/C047ZEYCGNQ/p1671505305489029?thread_ts=1671502318.828489&cid=C047ZEYCGNQ
enum CouponID {
  // Alpha
  BIZPRFOBM1 = "BIZPRFOBM1",
  BIZPRFOBM2 = "BIZPRFOBM2",
  // ALPHA, PROD 공통
  BIZPRFOBM3 = "BIZPRFOBM3",
  MH37E262 = "MH37E262",

  // Prod
  Z2EKABZE = "Z2EKABZE",
}

const AVAILABLE_COUPONS = Object.values(CouponID);

const AdvertiserOnboardingCash = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const params = new URLSearchParams(window.location.search);
  const couponId = params.get("couponId") as CouponID | null;
  const bcCouponId = params.get("bcCouponId") as null | string;
  const businessProfileId = params.get("businessProfileId");
  const { addSnackbar } = useSnackbar();
  const advertiser = useBusinessUserStore.use.advertiser();
  const addDialog = useAddDialog();
  const [mutate] = useMutation<advertiserOnboardingCashMutation>(graphql`
    mutation advertiserOnboardingCashMutation(
      $advertiserId: GraphQLIDInput!
      $input: AdvertiserRedeemCouponInput!
    ) {
      advertiserRedeemCoupon(advertiserId: $advertiserId, input: $input) {
        amount

        query {
          advertiser(advertiserId: $advertiserId) {
            ...advertiserCashBox_advertiser
          }
        }
      }
    }
  `);
  const shouldRedeemCouponId =
    couponId && AVAILABLE_COUPONS.includes(couponId) && businessProfileId;
  const shouldReedemBcCouponId = Boolean(bcCouponId);
  const [isPending, setIsPending] = React.useState(() => {
    return shouldRedeemCouponId || shouldReedemBcCouponId;
  });

  React.useEffect(() => {
    if (
      !advertiser?.id ||
      !isPending ||
      (!shouldRedeemCouponId && !shouldReedemBcCouponId)
    ) {
      return;
    }

    const onError = (errorMessage?: string) => {
      addDialog({
        description: errorMessage ?? "쿠폰 지급에 실패했어요.",
        primary: {
          label: "확인",
        },
      });
      setIsPending(false);
    };

    const onCompleted = (successMessage: string) => {
      addSnackbar({
        message: successMessage,
        type: "success",
      });
      setIsPending(false);
    };

    if (couponId && AVAILABLE_COUPONS.includes(couponId) && businessProfileId) {
      mutate({
        onCompleted: (_, errors) => {
          const errMessage = errors && errors[0].message;
          if (errMessage) {
            onError(errMessage);
            return;
          }

          onCompleted(
            match(couponId)
              .with(
                P.union(CouponID.BIZPRFOBM1, CouponID.Z2EKABZE),
                () => '"비즈프로필 미션" 캐시 지급이 완료되었어요',
              )
              .with(
                P.union(CouponID.BIZPRFOBM2, CouponID.MH37E262),
                () => '"첫 소식 작성 미션" 캐시 지급이 완료되었어요',
              )
              .with(
                CouponID.BIZPRFOBM3,
                () => '"첫 광고 체험 응원" 캐시 지급이 완료됐어요',
              )
              .exhaustive(),
          );
        },
        onError: () => onError(),
        variables: {
          advertiserId: advertiser.id,
          input: {
            code: couponId,
            referrer: `BIZ_PROFILE:${businessProfileId}`,
          },
        },
      });

      window.history.replaceState(null, "", window.location.pathname);
    }

    if (bcCouponId) {
      mutate({
        onCompleted: (data, errors) => {
          const errMessage = errors && errors[0].message;
          if (errMessage) {
            onError(errMessage);
            return;
          }

          onCompleted(
            `광고캐시 ${commaize(
              data.advertiserRedeemCoupon?.amount ?? 0,
            )}원이 적립됐어요`,
          );
        },
        onError: () => onError(),
        variables: {
          advertiserId: advertiser.id,
          input: {
            code: bcCouponId,
            referrer: `BIZ_PROFILE:${businessProfileId}`,
          },
        },
      });

      window.history.replaceState(null, "", window.location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    advertiser?.id,
    bcCouponId,
    businessProfileId,
    couponId,
    shouldRedeemCouponId,
    shouldReedemBcCouponId,
  ]);

  if (isPending) {
    return <PageLayout.Loading />;
  }

  return <>{children}</>;
};

export default AdvertiserOnboardingCash;
