import { StateCreator } from "zustand";

import {
  CtaTypeEnum,
  ExternalSourceTypeEnum,
  LiteAdMaterialSyncTypeEnum,
} from "../../graphql/__generated__/resolvers";
import useBusinessUserStore from "../business-user";
import { LiteAdInitialStatus, TypeCreateLiteAdStore } from "./lite-ad";
import { budgetDurationInitialState } from "./lite-ad-budget-duration.slice";
import { genderAgeInitialState } from "./lite-ad-gender-age.slice";
import { regionInitialState } from "./lite-ad-region.slice";
import liteAdMapper from "./lite-ad.mapper";
import {
  LiteAdOutlinkFormSchema,
  TypeLiteAdContentPreviewFormSchema,
} from "./lite-ad.schema";
import liteAdService, {
  TypeLiteAdServiceMethodReturn,
} from "./lite-ad.service";

export type TypeCreateContentState = {
  brandName?: null | string;
  contentInitialStatus: LiteAdInitialStatus;
  ctaButtonType: CtaTypeEnum;
  customDescription?: null | string;
  customImageUrl?: null | string;
  customTitle?: null | string;
  deliberationCode?: null | string;
  deliberationCodePrefix: "의" | "치" | "한";
  externalSourceId?: null | string;
  externalSourceType?: ExternalSourceTypeEnum | null;
  price?: null | number;
  shouldRenderDeliberationForm: boolean;
  showDeliberationCodeForm?: boolean | null;
  showPriceForm: boolean;
  showRegionName: boolean;
  syncType: LiteAdMaterialSyncTypeEnum;
  targetUrl?: null | string;
};

export type TypeCreateContentAction = {
  contentExternalSourceInit: (
    externalSourceId: string,
    externalSourceType: ExternalSourceTypeEnum,
  ) => Promise<void>;
  contentLiteAdInit: (
    query: TypeLiteAdServiceMethodReturn<"initStoreByLiteAdId">,
  ) => void;
  contentOutlinkInit: () => void;
  contentSetStatus: (status: LiteAdInitialStatus) => void;
  selectExternalSource: ({
    externalSourceId,
    externalSourceType,
  }: {
    externalSourceId?: string;
    externalSourceType?: ExternalSourceTypeEnum;
  }) => void;
  submitContentPreviewForm: (data: TypeLiteAdContentPreviewFormSchema) => void;
  submitOutlinkEditAllForm: (data: LiteAdOutlinkFormSchema) => void;
  submitOutlinkForm: (data: LiteAdOutlinkFormSchema) => void;
};

export const contentInitialState: TypeCreateContentState = {
  brandName: null,
  contentInitialStatus: "initial",
  ctaButtonType: "SHORTCUTS",
  customDescription: null,
  customImageUrl: null,
  customTitle: null,
  deliberationCode: null,
  deliberationCodePrefix: "의",
  externalSourceId: null,
  externalSourceType: null,
  price: null,
  shouldRenderDeliberationForm: false,
  showDeliberationCodeForm: null,
  showPriceForm: false,
  showRegionName: true,
  syncType: "SYNC",
  targetUrl: null,
};

export const createContentSlice: StateCreator<
  TypeCreateLiteAdStore,
  [["zustand/devtools", never]],
  [],
  TypeCreateContentState & TypeCreateContentAction
> = (_, get) => ({
  ...contentInitialState,

  contentExternalSourceInit: async (externalSourceId, externalSourceType) => {
    const { contentSetStatus, placement, setState } = get();
    const { getCurrentAdvertiser } = useBusinessUserStore.getState();
    const advertiser = getCurrentAdvertiser();

    contentSetStatus("loading");
    const response = await liteAdService.initContentByExternalSource({
      advertiserId: advertiser.id,
      externalSourceId,
      externalSourceType,
    });
    setState(
      liteAdMapper.toStateContentExternalSourceInit(placement, response),
      "contentExternalSourceInit",
    );
    contentSetStatus("success");
  },

  contentLiteAdInit: (query) => {
    const { contentSetStatus, setState } = get();
    contentSetStatus("loading");
    const response = liteAdService.initContentByLiteAdId(query);
    setState(
      liteAdMapper.toStateContentLiteAdInit(response),
      "contentLiteAdInit",
    );
    contentSetStatus("success");
  },

  contentOutlinkInit: () => {
    const { contentSetStatus, setState } = get();
    setState({ syncType: "OUTLINK" }, "contentOutlinkInit");
    contentSetStatus("success");
  },

  contentSetStatus: (status) => {
    const { setState } = get();
    setState({ contentInitialStatus: status }, "contentSetStatus");
  },

  selectExternalSource: ({ externalSourceId, externalSourceType }) => {
    const { setState, ...state } = get();

    if (
      state.externalSourceId === externalSourceId &&
      state.externalSourceType === externalSourceType
    ) {
      return;
    }

    setState(
      liteAdMapper.toStateSelectExternalSource({
        externalSourceId,
        externalSourceType,
      }),
      "selectExternalSource",
    );
  },

  submitContentPreviewForm: (data) => {
    const { setState } = get();
    setState(data, "submitContentPreviewForm");
  },

  submitOutlinkEditAllForm: (data) => {
    const { setState } = get();
    setState(data, "submitOutlinkEditAllForm");
  },

  submitOutlinkForm: (data) => {
    const {
      budgetDurationOutlinkInit,
      contentOutlinkInit,
      genderAgeOutlinkInit,
      regionOutlinkInit,
      setState,
    } = get();

    setState(
      {
        ...contentInitialState,
        ...regionInitialState,
        ...genderAgeInitialState,
        ...budgetDurationInitialState,
        ...data,
      },
      "submitOutlinkForm",
    );

    contentOutlinkInit();
    regionOutlinkInit();
    genderAgeOutlinkInit();
    budgetDurationOutlinkInit();
  },
});
