/**
 * @generated SignedSource<<1c78dc8a08e4b43b6339b1aade5dd85a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type liteAdMeta_Material_Source_Owner_Id_Query$variables = {
  advertiserId: CustomScalars["GraphQLIDInput"];
  liteAdId: CustomScalars["GraphQLIDInput"];
};
export type liteAdMeta_Material_Source_Owner_Id_Query$data = {
  readonly liteAd: {
    readonly _id: string;
    readonly material: {
      readonly source: {
        readonly owner?: {
          readonly _id: string;
        } | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
};
export type liteAdMeta_Material_Source_Owner_Id_Query = {
  response: liteAdMeta_Material_Source_Owner_Id_Query$data;
  variables: liteAdMeta_Material_Source_Owner_Id_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "advertiserId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "liteAdId"
},
v2 = {
  "kind": "Variable",
  "name": "advertiserId",
  "variableName": "advertiserId"
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "liteAdId",
    "variableName": "liteAdId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
},
v5 = [
  (v2/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "liteAdMeta_Material_Source_Owner_Id_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "LiteAd",
        "kind": "LinkedField",
        "name": "liteAd",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Material",
            "kind": "LinkedField",
            "name": "material",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "source",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExternalSourceOwner",
                        "kind": "LinkedField",
                        "name": "owner",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "ExternalSource",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "liteAdMeta_Material_Source_Owner_Id_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "LiteAd",
        "kind": "LinkedField",
        "name": "liteAd",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Material",
            "kind": "LinkedField",
            "name": "material",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": null,
                "kind": "LinkedField",
                "name": "source",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ExternalSourceOwner",
                        "kind": "LinkedField",
                        "name": "owner",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v6/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "ExternalSource",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v6/*: any*/)
                    ],
                    "type": "Node",
                    "abstractKey": "__isNode"
                  }
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b39cd8e12d443449abb5434b50bcd15b",
    "id": null,
    "metadata": {},
    "name": "liteAdMeta_Material_Source_Owner_Id_Query",
    "operationKind": "query",
    "text": "query liteAdMeta_Material_Source_Owner_Id_Query(\n  $liteAdId: GraphQLIDInput!\n  $advertiserId: GraphQLIDInput!\n) {\n  liteAd(advertiserId: $advertiserId, liteAdId: $liteAdId) {\n    _id\n    material {\n      source(advertiserId: $advertiserId) {\n        __typename\n        ... on ExternalSource {\n          owner {\n            _id\n            id\n          }\n        }\n        ... on Node {\n          __isNode: __typename\n          id\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "7be146c7de1af2211ab75fca9d4a6e95";

export default node;
