import { vars } from "@seed-design/design-token";

export type SemanticTypography = Record<
  `$semantic.${keyof typeof vars.$semantic.typography}`,
  {
    fontSize: string;
    fontWeight: string;
    letterSpacing: string;
    lineHeight: string;
  }
>;

const semanticTypography = Object.entries(vars.$semantic.typography).reduce(
  (acc, [k, v]) => {
    return {
      ...acc,
      [`$semantic.${k}`]: v,
    };
  },
  {} as SemanticTypography,
);

export const typography = {
  ...semanticTypography,
};
