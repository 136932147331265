/**
 * @generated SignedSource<<741e819f4f45ad1fd1a82fbb110c4eea>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ExternalSourceTypeEnum = "BUSINESS_POST" | "BUSINESS_PROFILE" | "CLASS" | "COMMERCE" | "FARM" | "REALTY" | "TOWN_ARTICLE" | "TOWN_JOB";
export type externalSourceMeta_Source_Id_Query$variables = {
  advertiserId: CustomScalars["GraphQLIDInput"];
  externalSourceId: CustomScalars["GraphQLIDInput"];
  externalSourceType: ExternalSourceTypeEnum;
};
export type externalSourceMeta_Source_Id_Query$data = {
  readonly externalSource: {
    readonly _id: string;
  } | null | undefined;
};
export type externalSourceMeta_Source_Id_Query = {
  response: externalSourceMeta_Source_Id_Query$data;
  variables: externalSourceMeta_Source_Id_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "advertiserId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "externalSourceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "externalSourceType"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "advertiserId",
    "variableName": "advertiserId"
  },
  {
    "kind": "Variable",
    "name": "externalSourceId",
    "variableName": "externalSourceId"
  },
  {
    "kind": "Variable",
    "name": "externalSourceType",
    "variableName": "externalSourceType"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "externalSourceMeta_Source_Id_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExternalSource",
        "kind": "LinkedField",
        "name": "externalSource",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "externalSourceMeta_Source_Id_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ExternalSource",
        "kind": "LinkedField",
        "name": "externalSource",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "739b4dbc7c875cec39ec32ab84a00580",
    "id": null,
    "metadata": {},
    "name": "externalSourceMeta_Source_Id_Query",
    "operationKind": "query",
    "text": "query externalSourceMeta_Source_Id_Query(\n  $advertiserId: GraphQLIDInput!\n  $externalSourceId: GraphQLIDInput!\n  $externalSourceType: ExternalSourceTypeEnum!\n) {\n  externalSource(advertiserId: $advertiserId, externalSourceId: $externalSourceId, externalSourceType: $externalSourceType) {\n    _id\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "6adf6a19e31c8236cea273287803eae3";

export default node;
