import { fetchQuery, graphql } from "react-relay";

import { externalSourceMeta_Source_Id_Query } from "@/src/graphql/__relay__/externalSourceMeta_Source_Id_Query.graphql";
import {
  ExternalSourceTypeEnum,
  externalSourceMeta_SourceOwnerId_Query,
} from "@/src/graphql/__relay__/externalSourceMeta_SourceOwnerId_Query.graphql";
import relayEnvironment from "@/src/graphql/relayEnvironment";

const getExternalSourceId = async (variables: {
  advertiserId: string;
  externalSourceId: string;
  externalSourceType: ExternalSourceTypeEnum;
}) => {
  const result = await fetchQuery<externalSourceMeta_Source_Id_Query>(
    relayEnvironment,
    graphql`
      query externalSourceMeta_Source_Id_Query(
        $advertiserId: GraphQLIDInput!
        $externalSourceId: GraphQLIDInput!
        $externalSourceType: ExternalSourceTypeEnum!
      ) {
        externalSource(
          advertiserId: $advertiserId
          externalSourceId: $externalSourceId
          externalSourceType: $externalSourceType
        ) {
          _id
        }
      }
    `,
    variables,
    {
      fetchPolicy: "store-or-network",
    },
  ).toPromise();

  return result?.externalSource?._id;
};

const getExternalSourceOwnerId = async (variables: {
  advertiserId: string;
  externalSourceId: string;
  externalSourceType: ExternalSourceTypeEnum;
}) => {
  const result = await fetchQuery<externalSourceMeta_SourceOwnerId_Query>(
    relayEnvironment,
    graphql`
      query externalSourceMeta_SourceOwnerId_Query(
        $advertiserId: GraphQLIDInput!
        $externalSourceId: GraphQLIDInput!
        $externalSourceType: ExternalSourceTypeEnum!
      ) {
        externalSource(
          advertiserId: $advertiserId
          externalSourceId: $externalSourceId
          externalSourceType: $externalSourceType
        ) {
          owner {
            _id
          }
        }
      }
    `,
    variables,
    {
      fetchPolicy: "store-or-network",
    },
  ).toPromise();

  return result?.externalSource?.owner?._id;
};

const metaLoaders = {
  getExternalSourceId,
  getExternalSourceOwnerId,
};

export default metaLoaders;
