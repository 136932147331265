/**
 * @generated SignedSource<<a79fbafe4ad9940bcfa253824c6eac1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Index_SearchKeywords_Query$variables = {
  businessAccountId: CustomScalars["GraphQLIDInput"];
};
export type Index_SearchKeywords_Query$data = {
  readonly " $fragmentSpreads": FragmentRefs<"recommendedSearchKeywords_Query">;
};
export type Index_SearchKeywords_Query = {
  response: Index_SearchKeywords_Query$data;
  variables: Index_SearchKeywords_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "businessAccountId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "businessAccountId",
    "variableName": "businessAccountId"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Index_SearchKeywords_Query",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "recommendedSearchKeywords_Query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Index_SearchKeywords_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "kind": "ScalarField",
        "name": "keywordRecommended",
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "620788e90e5928b056eb34d1ea4a550d",
    "id": null,
    "metadata": {},
    "name": "Index_SearchKeywords_Query",
    "operationKind": "query",
    "text": "query Index_SearchKeywords_Query(\n  $businessAccountId: GraphQLIDInput!\n) {\n  ...recommendedSearchKeywords_Query_2xLCS6\n}\n\nfragment recommendedSearchKeywords_Query_2xLCS6 on Query {\n  keywordRecommended(businessAccountId: $businessAccountId)\n}\n"
  }
};
})();

(node as any).hash = "f52a07f0ca0c3f01e743a685f6ee086a";

export default node;
