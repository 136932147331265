/**
 * @generated SignedSource<<3af9328441bf28357be615d92257d404>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type BudgetEnum = "DAILY" | "TOTAL";
export type ExternalSourceTypeEnum = "BUSINESS_POST" | "BUSINESS_PROFILE" | "CLASS" | "COMMERCE" | "FARM" | "REALTY" | "TOWN_ARTICLE" | "TOWN_JOB";
export type LiteAdExposureStrategyEnum = "FIXED_SCHEDULE" | "START_IMMEDIATE";
export type LiteAdStatusEnum = "BLOCKED" | "DAILY_BUDGET_EXCEED" | "DENIED" | "ONGOING" | "OUTDATED" | "PAUSED" | "READY" | "START_OUTDATED" | "TOTAL_BUDGET_EXCEED" | "UNKNOWN";
import { FragmentRefs } from "relay-runtime";
export type liteAdInitBudgetDurationByLiteAdId_LiteAdChanged$data = {
  readonly budget: {
    readonly amount: {
      readonly value: number;
    };
    readonly type: BudgetEnum;
  };
  readonly duration: {
    readonly endAt: string | null | undefined;
    readonly startAt: string;
    readonly type: LiteAdExposureStrategyEnum;
  };
  readonly material: {
    readonly source: {
      readonly __typename: "ExternalSource";
      readonly category: {
        readonly id: string;
        readonly name: string;
      } | null | undefined;
      readonly id: string;
      readonly type: ExternalSourceTypeEnum;
    } | {
      // This will never be '%other', but we need some
      // value in case none of the concrete values match.
      readonly __typename: "%other";
    } | null | undefined;
  };
  readonly status: LiteAdStatusEnum;
  readonly target: {
    readonly schedule: {
      readonly friday: ReadonlyArray<{
        readonly endAt: string;
        readonly startAt: string;
      }>;
      readonly monday: ReadonlyArray<{
        readonly endAt: string;
        readonly startAt: string;
      }>;
      readonly saturday: ReadonlyArray<{
        readonly endAt: string;
        readonly startAt: string;
      }>;
      readonly sunday: ReadonlyArray<{
        readonly endAt: string;
        readonly startAt: string;
      }>;
      readonly thursday: ReadonlyArray<{
        readonly endAt: string;
        readonly startAt: string;
      }>;
      readonly tuesday: ReadonlyArray<{
        readonly endAt: string;
        readonly startAt: string;
      }>;
      readonly wednesday: ReadonlyArray<{
        readonly endAt: string;
        readonly startAt: string;
      }>;
    } | null | undefined;
  };
  readonly " $fragmentType": "liteAdInitBudgetDurationByLiteAdId_LiteAdChanged";
};
export type liteAdInitBudgetDurationByLiteAdId_LiteAdChanged$key = {
  readonly " $data"?: liteAdInitBudgetDurationByLiteAdId_LiteAdChanged$data;
  readonly " $fragmentSpreads": FragmentRefs<"liteAdInitBudgetDurationByLiteAdId_LiteAdChanged">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "liteAdInitBudgetDurationByLiteAdId_LiteAdChanged"
};

(node as any).hash = "bc3771aa82606c8f257dd6a513ac3146";

export default node;
