import { useEffect } from "react";

import { bridge } from "../bridge";
import { BRIDGE_STREAM_EVENT_NAME } from "../constants/stream-event";
import { handleAppAction } from "../lib/webview";

const useSubscribeKotisaariWebviewStream = () => {
  useEffect(() => {
    const handleUnsubscribeFunctions: Array<() => void> = [];

    handleAppAction({
      onWebviewAction() {
        const unsusbscribeKotisaariWebviewDestroy =
          bridge.driver.compat.subscribeStream(
            BRIDGE_STREAM_EVENT_NAME.KotisaariWebviewDestroy,
            () => {
              bridge.closeRouter({});
            },
          );
        handleUnsubscribeFunctions.push(unsusbscribeKotisaariWebviewDestroy);
      },
    })();

    return () => {
      handleUnsubscribeFunctions.forEach((unsubscribe) => {
        unsubscribe();
      });
    };
  }, []);
};

export default useSubscribeKotisaariWebviewStream;
