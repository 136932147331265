import dayjs from "dayjs";
import { P, match } from "ts-pattern";

import categoryBudgetV2Data from "@/src/assets/json/category-budget-v2.json";
import { NEAR_REGION_VALUES } from "@/src/constants/region";
import {
  ExternalSourceTypeEnum,
  PlacementEnum,
} from "@/src/graphql/__generated__/resolvers";
import { liteAdRegionInitQuery$data } from "@/src/graphql/__relay__/liteAdRegionInitQuery.graphql";
import { liteAdRegionInitRegionByIdsQuery$data } from "@/src/graphql/__relay__/liteAdRegionInitRegionByIdsQuery.graphql";
import { liteAdRegionInitRegionQuery$data } from "@/src/graphql/__relay__/liteAdRegionInitRegionQuery.graphql";
import { isLiteAdOnGoingStatus } from "@/src/lib/lite-ad";
import { commaize } from "@/src/utils/commaize";
import { fromTimeShceduleToHHmm } from "@/src/utils/schedule";

import {
  LiteAdMode,
  TypeCreateLiteAdStore,
  TypeCreateLiteAdStoreState,
  TypeSharedAction,
  sharedInitialState,
} from "./lite-ad";
import { budgetDurationInitialState } from "./lite-ad-budget-duration.slice";
import {
  TypeCreateContentState,
  contentInitialState,
} from "./lite-ad-content.slice";
import { genderAgeInitialState } from "./lite-ad-gender-age.slice";
import { placementInitialState } from "./lite-ad-placement.slice";
import { regionInitialState } from "./lite-ad-region.slice";
import { searchKeywordInitialState } from "./lite-ad-search-keyword.slice";
import { TypeLiteAdServiceMethodReturn } from "./lite-ad.service";

class LiteAdMapper {
  public toStateBudgetDurationByLiteAdId(
    mode: LiteAdMode,
    query: TypeLiteAdServiceMethodReturn<"initBudgetDurationByLiteAdId">,
  ): Partial<TypeCreateLiteAdStoreState> {
    const categoryId =
      query?.material.source?.__typename === "ExternalSource"
        ? query.material.source.category?.id
        : null;
    const categoryName =
      query?.material.source?.__typename === "ExternalSource"
        ? query.material.source.category?.name
        : null;
    const suggestedBudget = categoryName
      ? categoryBudgetV2Data[categoryName as keyof typeof categoryBudgetV2Data]
          ?.suggestedBudget
      : null;
    const suggestedDuration = categoryName
      ? categoryBudgetV2Data[categoryName as keyof typeof categoryBudgetV2Data]
          ?.suggestedDuration
      : null;

    const suggestedDurationWithDefault = suggestedDuration ?? 10;
    const startTime = dayjs(query?.duration.startAt);
    const endTime = query?.duration.endAt
      ? dayjs(query.duration.endAt)
      : dayjs(query?.duration.startAt).add(suggestedDurationWithDefault, "d");
    const durationAmount = Math.ceil(endTime.diff(startTime, "d", true));
    return {
      ...match({
        budgetAmount: query?.budget.amount.value ?? suggestedBudget ?? 10000,
        budgetType: match(query?.budget.type ?? "DAILY")
          .with("DAILY", () => "DAILY" as const)
          .otherwise(() => "TOTALLY" as const),
        durationType: query?.duration.type ?? "FIXED_SCHEDULE",
        status: query?.status,
      })
        .returnType<Partial<TypeCreateLiteAdStoreState>>()
        .with(
          {
            budgetType: "TOTALLY",
            status: P.when((value) => value && isLiteAdOnGoingStatus(value)),
          },
          (values) => {
            return {
              budgetAmount: Math.floor(values.budgetAmount / durationAmount),
              budgetType: "DAILY",
            };
          },
        )
        .otherwise((values) => {
          return {
            budgetAmount: values.budgetAmount,
            budgetType: values.budgetType,
          };
        }),
      ...match({
        durationType: query?.duration.type ?? "FIXED_SCHEDULE",
        status: query?.status,
      })
        .returnType<Partial<TypeCreateLiteAdStoreState>>()
        .with(
          {
            durationType: "START_IMMEDIATE",
            status: P.when((value) => value && isLiteAdOnGoingStatus(value)),
          },
          () => {
            return {
              durationType: "FIXED_SCHEDULE",
            };
          },
        )
        .otherwise((values) => {
          return {
            durationType: values.durationType,
          };
        }),
      durationAmount: commaize(durationAmount),
      endTime: match({
        mode,
        startTime,
      })
        .with(
          {
            mode: "editAll",
            startTime: P.when((value) => value.isBefore(dayjs())),
          },
          () => dayjs().add(suggestedDurationWithDefault, "d"),
        )
        .otherwise(() => endTime),
      externalSourceCategory: {
        id: categoryId,
        name: categoryName,
        suggestedBudget,
        suggestedDuration,
      },
      isEndless: !query?.duration.endAt,
      schedule: query?.target.schedule
        ? {
            friday: query.target.schedule.friday.map(fromTimeShceduleToHHmm),
            monday: query.target.schedule.monday.map(fromTimeShceduleToHHmm),
            saturday: query.target.schedule.saturday.map(
              fromTimeShceduleToHHmm,
            ),
            sunday: query.target.schedule.sunday.map(fromTimeShceduleToHHmm),
            thursday: query.target.schedule.thursday.map(
              fromTimeShceduleToHHmm,
            ),
            tuesday: query.target.schedule.tuesday.map(fromTimeShceduleToHHmm),
            wednesday: query.target.schedule.wednesday.map(
              fromTimeShceduleToHHmm,
            ),
          }
        : null,

      startTime: match({ mode, startTime })
        .with(
          {
            mode: "editAll",
            startTime: P.when((value) => value.isBefore(dayjs())),
          },
          () => dayjs(),
        )
        .otherwise(() => startTime),
    };
  }

  public toStateBudgetDurationExternalSourceInit({
    externalSourceType,
    placement,
    query,
    state,
  }: {
    externalSourceType: ExternalSourceTypeEnum;
    placement: PlacementEnum;
    query: TypeLiteAdServiceMethodReturn<"initBudgetDurationByExternalSource">;
    state: TypeCreateLiteAdStore;
  }): Partial<TypeCreateLiteAdStoreState> {
    const categoryName = query?.externalSource?.category?.name;
    const suggestedBudget = categoryName
      ? categoryBudgetV2Data[categoryName as keyof typeof categoryBudgetV2Data]
          ?.suggestedBudget
      : null;
    const suggestedDuration = categoryName
      ? categoryBudgetV2Data[categoryName as keyof typeof categoryBudgetV2Data]
          ?.suggestedDuration
      : null;

    return {
      budgetAmount: match({
        externalSourceType,
        placement,
      })
        .with({ placement: "SEARCH_RESULT" }, () => 5000)
        .with(
          {
            externalSourceType: P.union("TOWN_ARTICLE", "REALTY"),
          },
          () => 10000,
        )
        .otherwise(() => suggestedBudget ?? 10000),
      durationAmount: commaize(suggestedDuration ?? 10),
      endTime: match(externalSourceType)
        .with(P.union("REALTY", "TOWN_ARTICLE"), () =>
          state.startTime.add(10 - 1, "d").endOf("d"),
        )
        .otherwise(() =>
          state.startTime.add((suggestedDuration ?? 10) - 1, "d"),
        )
        .endOf("d"),
      externalSourceCategory: {
        id: query?.externalSource?.category?.id,
        name: query?.externalSource?.category?.name,
        suggestedBudget,
        suggestedDuration,
      },
      isEndless: placement === "SEARCH_RESULT",
    };
  }

  public toStateContentExternalSourceInit(
    placement: PlacementEnum,
    query?: TypeLiteAdServiceMethodReturn<"initContentByExternalSource">,
  ) {
    return {
      customDescription: query?.externalSource?.description?.slice(0, 50),
      customImageUrl: query?.externalSource?.thumbnail,
      customTitle:
        placement === "HOME_FEED"
          ? query?.externalSource?.title.slice(0, 25)
          : query?.externalSource.title.slice(0, 20),
      shouldRenderDeliberationForm:
        query?.externalSource?.relateToDeliberationCode?.isTarget,
      showDeliberationCodeForm:
        query?.externalSource?.relateToDeliberationCode?.isRequired,
      syncType: "CUSTOM" as const,
    };
  }

  public toStateContentLiteAdInit(
    data?: TypeLiteAdServiceMethodReturn<"initContentByLiteAdId">,
  ): Partial<TypeCreateLiteAdStore> {
    return {
      ...match({
        source: data?.material.source,
        synced: data?.material.synced,
      })
        .with({ source: { __typename: "ExternalSource" } }, (data) => ({
          externalSourceId: data?.source.id,
          externalSourceType: data?.source.type,
          shouldRenderDeliberationForm:
            data?.source.relateToDeliberationCode?.isTarget,
          syncType: data.synced ? ("SYNC" as const) : ("CUSTOM" as const),
        }))
        .with({ source: { __typename: "OutLinkSource" } }, (data) => ({
          brandName: data.source.brandName,
          ctaButtonType: data.source.ctaType,
          price: data.source.price,
          shouldRenderDeliberationForm: true,
          showPriceForm: typeof data.source.price === "number",
          syncType: "OUTLINK",
          targetUrl: data.source.targetUrl,
        }))
        .otherwise(() => ({})),
      customDescription: data?.material.description?.slice(0, 50),
      customImageUrl: data?.material.thumbnail,
      customTitle:
        data?.placement === "HOME_FEED"
          ? data?.material.title.slice(0, 25)
          : data?.material.title.slice(0, 20),
      deliberationCode: data?.material.deliberationCode
        ?.split(" ")[1]
        .slice(0, -1),
      deliberationCodePrefix: (data?.material.deliberationCode?.split(" ")[0] ??
        "의") as TypeCreateContentState["deliberationCodePrefix"],
      showDeliberationCodeForm: Boolean(data?.material.deliberationCode),
      showRegionName: data?.material.showRegionName ?? false,
    };
  }

  public toStateRegionExternalSourceInit(
    placement: PlacementEnum,
    baseRegionQuery?: liteAdRegionInitQuery$data,
    regionQuery?: liteAdRegionInitRegionQuery$data,
    regionByIdsQuery?: liteAdRegionInitRegionByIdsQuery$data,
  ): Partial<TypeCreateLiteAdStore> {
    const baseRegion =
      baseRegionQuery?.externalSource?.baseRegion ??
      baseRegionQuery?.karrotUser?.baseRegion;
    const isRadiusSupported =
      placement === "HOME_FEED" &&
      baseRegionQuery?.externalSource?.regionRadius?.supported;

    return {
      baseRegionId: baseRegion?._id,
      baseRegionName: baseRegion?.name,
      radiusInfo: isRadiusSupported
        ? {
            address: baseRegionQuery.externalSource.regionRadius.address,
            distance: 1500,
            latitude: baseRegionQuery.externalSource.regionRadius.latitude
              ? parseFloat(baseRegionQuery.externalSource.regionRadius.latitude)
              : null,
            longitude: baseRegionQuery.externalSource.regionRadius.longitude
              ? parseFloat(
                  baseRegionQuery.externalSource.regionRadius.longitude,
                )
              : null,
            supported: baseRegionQuery?.externalSource?.regionRadius?.supported,
          }
        : null,
      regionIds: [],
      regions: match({
        regionByIds: regionByIdsQuery?.regionByIds ?? [],
        regionFixed: regionQuery?.regionFixed ?? [],
      })
        .with(
          { regionByIds: P.when((values) => values.length >= 1) },
          (values) => values.regionByIds,
        )
        .otherwise((values) => values.regionFixed)
        .slice(0, 3)
        .map((item) => ({
          childrenCount: item.childrenCount,
          fullName: item.fullName,
          id: item.id,
          level: item.level,
          name: item.name,
          name1: item.name1,
          name2: item.name2,
        })),
      regionType: match({ isRadiusSupported })
        .with({ isRadiusSupported: true }, () => "RADIUS" as const)
        .otherwise(() => "SELECT" as const),
    };
  }

  public toStateRegionLiteAdInit(
    data?: TypeLiteAdServiceMethodReturn<"initRegionByLiteAdId">,
  ): Partial<TypeCreateLiteAdStore> {
    return {
      ...match(data)
        .returnType<Partial<TypeCreateLiteAdStore>>()
        .with(
          {
            liteAdChanged: {
              material: { source: { __typename: "ExternalSource" } },
            },
          },
          (data) => ({
            baseRegionId: data.liteAdChanged.material.source.baseRegion?._id,
            baseRegionName: data.liteAdChanged.material.source.baseRegion?.name,
            radiusInfo:
              data.liteAdChanged.material.source.regionRadius &&
              data.liteAdChanged.material.source.regionRadius.latitude &&
              data.liteAdChanged.material.source.regionRadius.longitude
                ? {
                    address:
                      data.liteAdChanged.material.source.regionRadius.address,
                    distance: 1500,
                    latitude: parseFloat(
                      data.liteAdChanged.material.source.regionRadius.latitude,
                    ),
                    longitude: parseFloat(
                      data.liteAdChanged.material.source.regionRadius.longitude,
                    ),
                    supported:
                      data.liteAdChanged.material.source.regionRadius.supported,
                  }
                : null,
          }),
        )
        .otherwise((data) => ({
          baseRegionId: data?.karrotUser?.baseRegion?._id,
          baseRegionName: data?.karrotUser?.baseRegion?.name,
        })),
      ...match(data?.liteAdChanged?.target.region)
        .returnType<Partial<TypeCreateLiteAdStore>>()
        .with({ __typename: "AllRegion" }, () => ({
          regionType: "ALL",
        }))
        .with({ __typename: "SelectRegion" }, (data) => ({
          regions: data.regions.map((item) => ({
            childrenCount: item.childrenCount,
            fullName: item.fullName,
            id: item.id,
            level: item.level,
            name: item.name,
            name1: item.name1,
            name2: item.name2,
          })),
          regionType: "SELECT",
        }))
        .with({ __typename: "RadiusRegion" }, (values) => ({
          radiusInfo: {
            address: values.address,
            distance: values.meterRadiusDistance,
            latitude: parseFloat(values.latitude),
            longitude: parseFloat(values.longitude),
            supported:
              data?.liteAdChanged?.material.source?.__typename ===
                "ExternalSource" &&
              data.liteAdChanged.material.source.regionRadius?.supported,
          },
          regionType: "RADIUS",
        }))
        .with({ __typename: "RangeRegion" }, (data) => ({
          regionDepth:
            NEAR_REGION_VALUES[data.range as keyof typeof NEAR_REGION_VALUES],
          regionType: "RANGE",
        }))
        .otherwise(() => ({})),
    };
  }

  public toStateReset() {
    return {
      ...sharedInitialState,
      ...placementInitialState,
      ...contentInitialState,
      ...regionInitialState,
      ...genderAgeInitialState,
      ...budgetDurationInitialState,
      ...searchKeywordInitialState,
    };
  }

  public toStateSelectExternalSource({
    externalSourceId,
    externalSourceType,
  }: {
    externalSourceId?: string;
    externalSourceType?: ExternalSourceTypeEnum;
  }) {
    return {
      ...contentInitialState,
      ...regionInitialState,
      ...genderAgeInitialState,
      ...budgetDurationInitialState,
      ...searchKeywordInitialState,
      externalSourceId,
      externalSourceType,
    };
  }

  public toStateSelectPlacement(placement: PlacementEnum) {
    return {
      ...this.toStateReset(),
      placement,
    };
  }

  public toStateSetMode(...args: Parameters<TypeSharedAction["setMode"]>) {
    const [data] = args;
    return {
      ...this.toStateReset(),
      ...match(data)
        .with({ mode: "create" }, () => ({}))
        .with({ mode: "edit" }, (value) => ({
          liteAdId: value.liteAdId,
          mode: value.mode,
        }))
        .with({ mode: "editAll" }, (value) => ({
          liteAdId: value.liteAdId,
          mode: value.mode,
        }))
        .exhaustive(),
    };
  }
}

const liteAdMapper = new LiteAdMapper();
export default liteAdMapper;
