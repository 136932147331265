/**
 * @generated SignedSource<<1384ca61daea629143c6513700802df6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AdvertiserRedeemCouponInput = {
  code: string;
  referrer?: string | null | undefined;
};
export type advertiserOnboardingCashMutation$variables = {
  advertiserId: CustomScalars["GraphQLIDInput"];
  input: AdvertiserRedeemCouponInput;
};
export type advertiserOnboardingCashMutation$data = {
  readonly advertiserRedeemCoupon: {
    readonly amount: number;
    readonly query: {
      readonly advertiser: {
        readonly " $fragmentSpreads": FragmentRefs<"advertiserCashBox_advertiser">;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export type advertiserOnboardingCashMutation = {
  response: advertiserOnboardingCashMutation$data;
  variables: advertiserOnboardingCashMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "advertiserId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = {
  "kind": "Variable",
  "name": "advertiserId",
  "variableName": "advertiserId"
},
v2 = [
  (v1/*: any*/),
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "amount",
  "storageKey": null
},
v4 = [
  (v1/*: any*/)
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "advertiserOnboardingCashMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AdvertiserRedeemCouponPayload",
        "kind": "LinkedField",
        "name": "advertiserRedeemCoupon",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "Advertiser",
                "kind": "LinkedField",
                "name": "advertiser",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "advertiserCashBox_advertiser"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "advertiserOnboardingCashMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "AdvertiserRedeemCouponPayload",
        "kind": "LinkedField",
        "name": "advertiserRedeemCoupon",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v4/*: any*/),
                "concreteType": "Advertiser",
                "kind": "LinkedField",
                "name": "advertiser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cashAutoChargeEnabled",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "walletStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FreeCash",
                    "kind": "LinkedField",
                    "name": "freeCash",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Prepaid",
                    "kind": "LinkedField",
                    "name": "prepaidCash",
                    "plural": false,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": "activeAdvertisementCount",
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "status",
                        "value": [
                          "READY",
                          "START_OUTDATED",
                          "ONGOING",
                          "DAILY_BUDGET_EXCEED"
                        ]
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "advertisementCount",
                    "storageKey": "advertisementCount(status:[\"READY\",\"START_OUTDATED\",\"ONGOING\",\"DAILY_BUDGET_EXCEED\"])"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "40c683b86a274b19b7c3b6313c0d7437",
    "id": null,
    "metadata": {},
    "name": "advertiserOnboardingCashMutation",
    "operationKind": "mutation",
    "text": "mutation advertiserOnboardingCashMutation(\n  $advertiserId: GraphQLIDInput!\n  $input: AdvertiserRedeemCouponInput!\n) {\n  advertiserRedeemCoupon(advertiserId: $advertiserId, input: $input) {\n    amount\n    query {\n      advertiser(advertiserId: $advertiserId) {\n        ...advertiserCashBox_advertiser\n        id\n      }\n    }\n  }\n}\n\nfragment advertiserCashBox_advertiser on Advertiser {\n  cashAutoChargeEnabled\n  ...useAdvertiserCheckWalletStatus_advertiser\n  freeCash {\n    value\n  }\n  prepaidCash {\n    value\n  }\n  activeAdvertisementCount: advertisementCount(status: [READY, START_OUTDATED, ONGOING, DAILY_BUDGET_EXCEED])\n}\n\nfragment useAdvertiserCheckWalletStatus_advertiser on Advertiser {\n  walletStatus\n}\n"
  }
};
})();

(node as any).hash = "7dba301d3e969c0dd71328a680538edf";

export default node;
