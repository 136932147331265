/**
 * @generated SignedSource<<df9bd2a9d7d120d4a2cdb46ffc6f8823>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type AgeEnum = "ALL" | "FROM0TO14" | "FROM15TO19" | "FROM20TO24" | "FROM25TO29" | "FROM30TO34" | "FROM35TO39" | "FROM40TO44" | "FROM40TO49" | "FROM45TO49" | "FROM50" | "FROM50TO54" | "FROM55TO59" | "FROM60";
export type GenderEnum = "ALL" | "FEMALE" | "MALE";
import { FragmentRefs } from "relay-runtime";
export type liteAdInitGenderAgeByLiteAdId_LiteAdChanged$data = {
  readonly target: {
    readonly ages: ReadonlyArray<AgeEnum>;
    readonly gender: GenderEnum;
  };
  readonly " $fragmentType": "liteAdInitGenderAgeByLiteAdId_LiteAdChanged";
};
export type liteAdInitGenderAgeByLiteAdId_LiteAdChanged$key = {
  readonly " $data"?: liteAdInitGenderAgeByLiteAdId_LiteAdChanged$data;
  readonly " $fragmentSpreads": FragmentRefs<"liteAdInitGenderAgeByLiteAdId_LiteAdChanged">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "liteAdInitGenderAgeByLiteAdId_LiteAdChanged"
};

(node as any).hash = "6d1ce98590a1b4530d2a2f2ce854caba";

export default node;
