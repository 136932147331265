import "@daangn/business-center-web-sdk/dist/esm/styles.css";

import type { navConfigProps } from "@daangn/business-center-web-sdk";

import { GlobalHeader } from "@daangn/business-center-web-sdk";
import React from "react";

import { env } from "../../../environment";
import { useFlow } from "../../stackflow";

export const BusinessCenterHeader = React.memo(() => {
  const { push } = useFlow();

  const config: navConfigProps = {
    ads: {
      callback: function () {
        if (window.location.pathname.startsWith("/advertisements")) {
          return;
        }

        push(
          "advertisements",
          {
            placementFilter: "ALL",
            statusFilter: "ALL",
          },
          { animate: false },
        );
      },
      preventDefault: true,
    },
    home: {
      callback: () => {},
    },
    logoUrl:
      "https://business.karroter.net/daangn-business/images/rebrand_logo.png",
    profile: {},
    service: "ads",
  };

  /** @description 로그인되어 있지 않을 때 실행 */
  const handleLogin = () => {
    window.location.href = `${
      env.BUSINESS_PLATFORM_HOST
    }/login?redirect=${encodeURIComponent(window.location.href)}`;
  };

  /** @description 로그아웃 버튼을 눌렀을 때 실행 */
  const handleLogoutClick = () => {
    window.location.href = `${env.BUSINESS_PLATFORM_HOST}/logout`;
  };

  return (
    <GlobalHeader
      ENVIRONMENT={env.STAGE}
      loginRedirect={handleLogin}
      logoutWithFunc={handleLogoutClick}
      navConfig={config}
      onLoadUserInfo={(user) => {
        console.log(
          "🚀 ~ file: activity-screen.tsx:41 ~ Desktop ~ user.id:",
          user.id, //hoian_id
        );
      }}
    />
  );
});
