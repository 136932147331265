import Responsive from "../_shared/responsive";
import { BusinessCenterHeader } from "../layout-business-center-header";
import { Sidebar } from "../layout-sidebar";
import * as styles from "./layout-web.css";

const WebLayout = (props: React.PropsWithChildren) => {
  return (
    <Responsive
      desktop={
        <div className={styles.container}>
          <BusinessCenterHeader />
          <div className={styles.content}>
            <div className={styles.sidebar}>
              <Sidebar />
            </div>
            <div className={styles.mainContent}>{props.children}</div>
          </div>
        </div>
      }
      mobile={<div>{props.children}</div>}
    />
  );
};

export default WebLayout;
