import {
  createPreloader,
  useActivityPreloadRef,
} from "@stackflow/plugin-preload";
import React from "react";
import {
  LoadQueryOptions,
  PreloadedQuery,
  usePreloadedQuery,
} from "react-relay";
import { GraphQLTaggedNode, fetchQuery } from "relay-runtime";
import { OperationType } from "relay-runtime";

import type { TypeStackflowActivities } from "./__generated__/generated-activities";

import { TypeActivityName, TypeActivityParams } from ".";
import relayEnvironment from "../graphql/relayEnvironment";

export const { usePreloader } = createPreloader<TypeStackflowActivities>();

export const createPageLoader = <
  TActivityName extends TypeActivityName,
  TQuery extends OperationType,
  TVariables extends Record<string, unknown>,
>({
  activityName,
  loadGraphql,
  loadModule,
}: {
  activityName: TActivityName;
  loadGraphql?: (
    ...args: [
      {
        activityContext: unknown;
        activityParams: TypeActivityParams<TActivityName>;
        initialContext?: any;
        isInitialActivity?: boolean;
      },
    ]
  ) => {
    gqlQuery: GraphQLTaggedNode;
    preloadedQuery: PreloadedQuery<TQuery>;
    runQuery: (
      variables: TVariables,
      options?: LoadQueryOptions,
    ) => PreloadedQuery<TQuery>;
  };
  loadModule?: () => Promise<unknown>;
}) => {
  return {
    pageLoader: (...args: Parameters<NonNullable<typeof loadGraphql>>) => {
      loadModule?.();
      return loadGraphql?.(...args);
    },
    usePagePreloadLoader: () => {
      if (!loadGraphql) {
        throw new Error(
          "usePageLoader를 사용하기 위해서는 loadGraphql params을 정의해주셔야 해요.",
        );
      }

      const [isRefetching, setIsRefetching] = React.useState(false);
      const { gqlQuery, preloadedQuery, runQuery } =
        useActivityPreloadRef<ReturnType<typeof loadGraphql>>();
      const query = usePreloadedQuery(gqlQuery, preloadedQuery);

      const refetch = (...args: Parameters<typeof runQuery>) => {
        if (isRefetching) {
          return;
        }

        setIsRefetching(true);
        fetchQuery(relayEnvironment, gqlQuery, args[0]).subscribe({
          complete: () => {
            setIsRefetching(false);
            runQuery(...args);
          },
          error: () => {
            setIsRefetching(false);
          },
        });
      };

      return {
        isRefetching,
        query,
        refetch,
      };
    },
  };
};
