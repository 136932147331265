/**
 * @generated SignedSource<<af62f8583e804bae869cfea2a4194bd5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type liteAdMeta_Ad_Id_Query$variables = {
  advertiserId: CustomScalars["GraphQLIDInput"];
  liteAdId: CustomScalars["GraphQLIDInput"];
};
export type liteAdMeta_Ad_Id_Query$data = {
  readonly liteAd: {
    readonly _id: string;
  } | null | undefined;
};
export type liteAdMeta_Ad_Id_Query = {
  response: liteAdMeta_Ad_Id_Query$data;
  variables: liteAdMeta_Ad_Id_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "advertiserId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "liteAdId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "advertiserId",
    "variableName": "advertiserId"
  },
  {
    "kind": "Variable",
    "name": "liteAdId",
    "variableName": "liteAdId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "liteAdMeta_Ad_Id_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "LiteAd",
        "kind": "LinkedField",
        "name": "liteAd",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "liteAdMeta_Ad_Id_Query",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "LiteAd",
        "kind": "LinkedField",
        "name": "liteAd",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c29914b013a9867ef966c8f4d30c6c17",
    "id": null,
    "metadata": {},
    "name": "liteAdMeta_Ad_Id_Query",
    "operationKind": "query",
    "text": "query liteAdMeta_Ad_Id_Query(\n  $liteAdId: GraphQLIDInput!\n  $advertiserId: GraphQLIDInput!\n) {\n  liteAd(advertiserId: $advertiserId, liteAdId: $liteAdId) {\n    _id\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "4717d96322b4c1f31f55fb8db5b52e83";

export default node;
