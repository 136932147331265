import * as React from "react";
import { match } from "ts-pattern";

import useBreakpoint from "@/src/hooks/_shared/use-breakpoint";

type TypeResponsiveProps = {
  desktop?: React.ReactNode;
  mobile?: React.ReactNode;
};

export const Mobile = ({ children }: { children: React.ReactNode }) => {
  const breakpoint = useBreakpoint();
  return breakpoint === "mobile" ? <>{children}</> : null;
};

export const Desktop = ({ children }: { children: React.ReactNode }) => {
  const breakpoint = useBreakpoint();
  return breakpoint === "desktop" ? <>{children}</> : null;
};

/**
 * 😻😻 하나의 맥락으로 묶일 수 있다는 장점에서 Responsive를 쓰는 방식으로 하는게 좋지 않을까 생각했어요.
 * @param desktop desktop에서만 render될 컴포넌트
 * @param mobile mobile에서만 render될 컴포넌트
 * @returns responsive한 컴포넌트
 */
export const Responsive = ({ desktop, mobile }: TypeResponsiveProps) => {
  const breakpoint = useBreakpoint();

  return (
    <>
      {match(breakpoint)
        .with("desktop", () => desktop)
        .otherwise(() => mobile)}
    </>
  );
};
