import { StackflowActions } from "@stackflow/core";
import { StackflowReactPlugin } from "@stackflow/react";
import * as changeKeys from "change-case/keys";

import { logClickedEvent } from "@/src/utils/event";

import { metas } from "../__generated__/generated-activities";
import metaLoaders from "./metaLoaders";
import { getCommonEventParams, isActivityName } from "./utlis";

function getTraceFromDataset(element: HTMLElement): Record<string, any> {
  let obj: Record<string, any> = {};
  for (let prop in element.dataset) {
    const splitProp =
      prop.match(/^[a-z]+|[A-Z]?[a-z]*[0-9]*/g)?.filter(Boolean) || [];
    if (splitProp[0] !== "trace") {
      continue;
    }

    const key = splitProp
      .slice(1)
      .reduce((str, key) => `${str}-${key.toLowerCase()}`, "")
      .slice(1);

    obj[key] = element.dataset[prop];
  }
  return obj;
}

const clickedEventPlugin = (): StackflowReactPlugin => () => {
  const clickHandler = async (
    getStack: StackflowActions["getStack"],
    event: MouseEvent,
  ) => {
    const { activities } = getStack();
    const activeActivity = activities.find((item) => item.isActive);
    const activeActivityName = activeActivity?.name;

    if (!isActivityName(activeActivityName)) {
      return;
    }

    const path = event.composedPath();
    const payload = path.reduce<Record<string, string>>((acc, cur) => {
      const ele = cur as HTMLElement;
      const trace = getTraceFromDataset(ele);
      if (trace) {
        return { ...acc, ...trace };
      }
      return acc;
    }, {});

    if (Object.keys(payload).length === 0) {
      return;
    }

    const meta = metas[activeActivityName];
    if (!meta || !meta.event) {
      return;
    }

    const commonEventParams = await getCommonEventParams();

    const activityEventParams = (await meta.event.eventParams?.(
      activeActivity as any,
      commonEventParams,
      metaLoaders,
    )) ?? {
      advertiser_id: commonEventParams.advertiser_id,
      business_user_id: commonEventParams.business_user_id,
    };

    const snakeCasePayload = changeKeys.snakeCase(payload) as Record<
      string,
      string
    >;

    const eventParams = {
      ...activityEventParams,
      ...snakeCasePayload,
    };

    const eventName = `client_clicked_adLite_${meta.event.activityAlias}`;

    logClickedEvent(eventName, eventParams);
  };

  const onInit = ({ actions: { getStack } }: { actions: StackflowActions }) => {
    document.addEventListener("click", clickHandler.bind(null, getStack), true);
  };

  return {
    key: "ad-lite-clicked-event-plugin",
    onInit,
  };
};

export default clickedEventPlugin;
