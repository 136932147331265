import { match } from "ts-pattern";
import z from "zod";

function getDefaultEnvValue({
  alpha,
  production,
}: {
  alpha: string;
  production: string;
}): string {
  return match(process.env.STAGE === "production")
    .with(true, () => production)
    .otherwise(() => alpha);
}

const schema = z.object({
  BUSINESS_PLATFORM_HOST: z
    .string()
    .describe("비즈니스 플랫폼 호스트")
    .default(
      getDefaultEnvValue({
        alpha: "https://business.alpha.daangn.com",
        production: "https://business.daangn.com",
      }),
    ),
  BUSINESS_PROFILE_WEBVIEW_HOST: z
    .string()
    .default(
      getDefaultEnvValue({
        alpha: "https://bizprofile.alpha.karrotwebview.com",
        production: "https://bizprofile.karrotwebview.com",
      }),
    )
    .describe("비즈니스프로필 웹뷰 호스트"),
  GRAPHQL_ENDPOINT: z
    .string()
    .describe("GraphQL 엔드포인트")
    .default(
      getDefaultEnvValue({
        alpha: "https://ads-bff.alpha.daangn.com/v2/graphql",
        production: "https://ads-bff.daangn.com/v2/graphql",
      }),
    ),
  JOBS_WEBVIEW_HOST: z.string().default(
    getDefaultEnvValue({
      alpha: "https://jobs.alpha.karrotwebview.com",
      production: "https://jobs.karrotwebview.com",
    }),
  ),
  KOTISAARI_WEBVIEW_HOST: z
    .string()
    .default(
      getDefaultEnvValue({
        alpha: "https://kotisaari.alpha.karrotwebview.com",
        production: "https://kotisaari.karrotwebview.com",
      }),
    )
    .describe("비즈니스프로필 웹뷰 호스트"),
  PRO_ADS_HOST: z
    .string()
    .describe("전문가모드 호스트")
    .default(
      getDefaultEnvValue({
        alpha: "https://ads-pro.business.alpha.daangn.com",
        production: "https://ads-pro.business.daangn.com",
      }),
    ),
  REALTY_WEBVIEW_HOST: z
    .string()
    .default(
      getDefaultEnvValue({
        alpha: "https://realty.alpha.karrotwebview.com",
        production: "https://realty.karrotwebview.com",
      }),
    )
    .describe("부동산 웹뷰 호스트"),
  STAGE: z
    .enum(["development", "production", "alpha", "preview"])
    .default("development")
    .describe("스테이지"),
  X_AUTH_TOKEN: z.string().optional().describe("인증 토큰"),
});

export const env = schema.parse(process.env);
export const IS_PRODUCTION = env.STAGE === "production";
