import { StateCreator } from "zustand";

import { PlacementEnum } from "../../graphql/__generated__/resolvers";
import { TypeCreateLiteAdStore } from "./lite-ad";
import liteAdMapper from "./lite-ad.mapper";
import liteAdService, {
  TypeLiteAdServiceMethodReturn,
} from "./lite-ad.service";

// placement slice
export type TypeCreatePlacementState = {
  placement: PlacementEnum;
};

export type TypeCreatePlacementAction = {
  placementLiteAdInit: (
    query: TypeLiteAdServiceMethodReturn<"initStoreByLiteAdId">,
  ) => void;
  selectPlacement: (placement: PlacementEnum) => void;
};

export const placementInitialState: TypeCreatePlacementState = {
  placement: "HOME_FEED",
};

export const createPlacementSlice: StateCreator<
  TypeCreateLiteAdStore,
  [["zustand/devtools", never]],
  [],
  TypeCreatePlacementState & TypeCreatePlacementAction
> = (_, get) => ({
  ...placementInitialState,
  placementLiteAdInit: (query) => {
    const { setState } = get();
    const result = liteAdService.initPlacementByLiteAdId(query);
    setState(
      { placement: result?.placement ?? "HOME_FEED" },
      "placementLiteAdInit",
    );
  },
  selectPlacement: (placement) => {
    const { setState } = get();
    setState(liteAdMapper.toStateSelectPlacement(placement), "selectPlacement");
  },
});
