import { IconExpandMoreFill } from "@seed-design/icon";
import React from "react";

import { useStepFlow } from "@/src/stackflow";
import { TypeBusinessUserStore } from "@/src/store/business-user";

import AdvertiserSelectorBottomSheet from "./advertiser-selector-bottomsheet";
import * as styles from "./advertiser-selector-mobile.css";

interface Props {
  advertiser: TypeBusinessUserStore["advertiser"];
}

const AccountSelectorMobile: React.FC<Props> = ({ advertiser }) => {
  const { params, stepPop, stepPush } = useStepFlow("advertisements");

  return (
    <>
      <div
        className={styles.div}
        data-trace-button-name="accountChange"
        onClick={() => {
          stepPush({
            isMobileAccountSelectorOpen: "true",
          });
        }}
      >
        <span className={styles.currentAccountName}>{advertiser?.name}</span>
        <IconExpandMoreFill size={15} />
      </div>
      <AdvertiserSelectorBottomSheet
        isOpen={Boolean(params.isMobileAccountSelectorOpen)}
        onClose={() => stepPop()}
      />
    </>
  );
};

export default AccountSelectorMobile;
