import { match } from "ts-pattern";
import { z } from "zod";
import { StateStorage } from "zustand/middleware";

import {
  EXTERNAL_SOURCE_TYPE_ENUM,
  PLACEMENT_ENUM,
} from "@/src/constants/enum-const";

import { LITE_AD_MODE_ENUM } from "./lite-ad";

const storage: StateStorage = {
  getItem: () => {
    const urlSearch = window.location.search.slice(1);
    const searchParams = new URLSearchParams(urlSearch);
    const initialState = {
      externalSourceId:
        searchParams.get("externalSourceId") ??
        searchParams.get("sourceId") ??
        undefined,
      externalSourceType:
        searchParams.get("externalSourceType") ??
        searchParams.get("articleType") ??
        undefined,
      liteAdId: searchParams.get("liteAdId") ?? undefined,
      mode: searchParams.get("mode") ?? undefined,
      placement: searchParams.get("placement") ?? undefined,
      regionIds: searchParams.get("regionIds") ?? undefined,
    };

    const schema = z.object({
      externalSourceId: z.string().optional(),
      externalSourceType: z.enum(EXTERNAL_SOURCE_TYPE_ENUM).optional(),
      liteAdId: z.string().optional(),
      mode: z.enum(LITE_AD_MODE_ENUM).optional().default("create"),
      placement: z
        .string()
        .optional()
        .transform((value): (typeof PLACEMENT_ENUM)[number] | undefined => {
          if (value === "home-feed" || value === "HOME_FEED") {
            return "HOME_FEED";
          }

          if (value === "search-result" || value === "SEARCH_RESULT") {
            return "SEARCH_RESULT";
          }

          return "HOME_FEED";
        }),
      regionIds: z
        .string()
        .optional()
        .transform((value) =>
          (value?.split(",") ?? [])
            .map((item) => parseInt(item, 10))
            .filter((item) => !isNaN(item)),
        ),
    });
    const parsedState = schema.safeParse(initialState);

    return JSON.stringify({
      state: match(parsedState)
        .with({ success: true }, ({ data }) => data)
        .otherwise(() => {}),
      version: 0,
    });
  },
  removeItem: () => {},
  setItem: () => {},
};

export default storage;
