import * as React from "react";
import { match } from "ts-pattern";

import { isPromise } from "@/src/utils/promise";

import { Dialog } from ".";
import { ButtonGroup } from "../button-group";
import * as styles from "./alert-dialog.css";

type TypeAlertDialogProps = React.CSSProperties & {
  description: string;
  onClose?: () => void;
  onOutsideClick?: () => void;
  open?: boolean;
  primary: {
    label: string;
    onAction?: () => void;
  };
  secondary?: {
    actionIntent?: "alternative" | "neutral" | "nonpreferred";
    label: string;
    onAction?: () => void;
  };
  title?: string;
};

export const AlertDialog = ({
  description,
  onClose,
  onOutsideClick,
  open,
  primary: { label: primaryActionLabel, onAction: onPrimaryAction },
  secondary,
  title,
  ...style
}: TypeAlertDialogProps) => {
  const [loading, setLoading] = React.useState(false);

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay onClick={onOutsideClick ?? onClose}>
          <Dialog.Content asChild>
            <div className={styles.modal} style={style}>
              <div className={styles.content}>
                {title && <h2 className={styles.title}>{title}</h2>}
                <p className={styles.description}>{description}</p>
              </div>
              <ButtonGroup
                className={styles.bottom}
                fullWidth
                primary={{
                  isDisabled: loading,
                  label: primaryActionLabel,
                  onClick: async () => {
                    const result = onPrimaryAction?.();
                    if (isPromise(result)) {
                      setLoading(true);
                      await result;
                      setLoading(false);
                    }

                    onClose?.();
                  },
                }}
                secondary={
                  !secondary || secondary.actionIntent === "nonpreferred"
                    ? undefined
                    : {
                        label: secondary.label,
                        onClick: () => {
                          secondary?.onAction?.();
                          onClose?.();
                        },
                        ...match(secondary.actionIntent ?? "alternative")
                          .with("alternative", () => ({
                            variant: "primaryLow" as const,
                          }))
                          .with("neutral", () => ({}))
                          .exhaustive(),
                      }
                }
              />
              {/* @TODO: secondary nonpreferred일 때 TextButton 추가하기  */}
            </div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
