import {
  Environment,
  FetchFunction,
  Network,
  RecordSource,
  Store,
} from "relay-runtime";

import { env } from "../../environment";
import { customFetch } from "../fetch";

const fetchFn: FetchFunction = async (
  operation,
  variables,
  cacheConfig,
  uploadables,
) => {
  if (!operation.text) {
    throw new Error("No operation.text is provided");
  }

  const requestInit: RequestInit = {
    credentials: "same-origin",
    method: "post",
    signal: cacheConfig.metadata?.signal as AbortSignal,
  };

  if (uploadables) {
    const formData = new FormData();
    formData.append(
      "operations",
      JSON.stringify({ query: operation.text, variables }),
    );

    Object.values(uploadables).forEach((upload, index) => {
      formData.append(index.toString(), upload);
    });

    formData.append(
      "map",
      JSON.stringify(
        Object.keys(uploadables).reduce(
          (current, next, index) => {
            current[index.toString()] = [`variables.${next}`];
            return current;
          },
          {} as Record<string, [string]>,
        ),
      ),
    );

    requestInit.body = formData;
  } else {
    requestInit.body = JSON.stringify({
      query: operation.text,
      variables,
    });
    requestInit.headers = {
      "Content-Type": "application/json",
    };
  }
  const result = await customFetch(env.GRAPHQL_ENDPOINT, requestInit).then(
    (response) => response.json(),
  );

  return result;
};

const relayEnvironment = new Environment({
  network: Network.create(fetchFn),
  store: new Store(new RecordSource()),
});

export default relayEnvironment;
