import * as Sentry from "@sentry/react";

import { env } from "../environment";

if (env.STAGE !== "development") {
  Sentry.init({
    dsn: "https://616daee2b6bdfbe21a67e4f78c7caf3c@o24217.ingest.sentry.io/4506767653928960",
    environment: env.STAGE,
    integrations: [
      Sentry.replayIntegration({
        blockAllMedia: false,
        maskAllInputs: false,
        maskAllText: false,
        networkDetailAllowUrls: [
          window.location.origin,
          /.*\.karrotmarket\.com/g,
        ],
        networkRequestHeaders: ["x-auth-token", "x-advertiser-id"],
      }),
    ],
    replaysOnErrorSampleRate: 1.0,
    replaysSessionSampleRate: 0,
  });
}
