/* eslint-disable relay/must-colocate-fragment-spreads */
import { LoadQueryOptions, graphql, loadQuery } from "react-relay";

import { Index_SearchKeywords_Query } from "@/src/graphql/__relay__/Index_SearchKeywords_Query.graphql";
import relayEnvironment from "@/src/graphql/relayEnvironment";
import { createPageLoader } from "@/src/stackflow/use-preloader";

export const { pageLoader, usePagePreloadLoader } = createPageLoader({
  activityName: "advertisements/search-keywords",
  loadGraphql: ({ activityParams: { businessAccountId } }) => {
    const gqlQuery = graphql`
      query Index_SearchKeywords_Query($businessAccountId: GraphQLIDInput!) {
        ...recommendedSearchKeywords_Query
          @arguments(businessAccountId: $businessAccountId)
      }
    `;

    const runQuery = (
      variables: Index_SearchKeywords_Query["variables"],
      options?: LoadQueryOptions,
    ) =>
      loadQuery<Index_SearchKeywords_Query>(
        relayEnvironment,
        gqlQuery,
        variables,
        options,
      );
    const preloadedQuery = runQuery({
      businessAccountId,
    });

    return {
      gqlQuery,
      preloadedQuery,
      runQuery,
    };
  },
  loadModule: () =>
    import("@/src/routes/advertisements/search-keywords/_index.page"),
});
