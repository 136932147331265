import { IconCloseRegular } from "@seed-design/icon";
import clsx from "clsx";
import React from "react";

import { Dialog } from "../dialog";
import * as styles from "./sheet.css";

type SheetProps = {
  className?: string;
  onClose?: () => void;
  onOpen?: () => void;
  onOutsideClick?: () => void;
  open?: boolean;
  side?: "bottom" | "left" | "right" | "top";
};

export const Root = ({
  children,
  className,
  onClose,
  onOpen,
  onOutsideClick,
  open,
  side = "bottom",
}: React.PropsWithChildren<SheetProps>) => {
  React.useEffect(() => {
    if (open && !!onOpen) {
      onOpen?.();
    }
  }, [onOpen, open]);

  return (
    <Dialog.Root open={open}>
      <Dialog.Portal>
        <Dialog.Overlay
          className={styles.overlay}
          onClick={onOutsideClick ?? onClose}
        />
        <Dialog.Content className={clsx(styles.content({ side }), className)}>
          {children}
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};

export const Title = ({
  children,
  className,
  onClose,
}: {
  children: React.ReactNode;
  className?: string;
  onClose?: () => void;
}) => {
  return (
    <div className={clsx(styles.title, className)}>
      {children}
      {onClose && <IconCloseRegular onClick={onClose} size={24} />}
    </div>
  );
};

export const Content = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={clsx(styles.contentContainer, className)}>{children}</div>
  );
};

export const Sheet = {
  Content,
  Root,
  Title,
};
