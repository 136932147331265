import { SeedBoxButtonProps } from "@daangn/sprout-components-button";
import clsx from "clsx";
import React, { useLayoutEffect } from "react";

import { BoxButton } from "@/src/components/_shared/button";

import Responsive from "../responsive";
import { AvoidSnackbarOverlap } from "../snackbar";
import * as styles from "./page-submit-button.css";

type TypePageSubmitButtonProps = Omit<SeedBoxButtonProps, "children"> & {
  children: React.ReactNode;
  extra?: string;
};

const PageSubmitButton = ({
  children,
  ...props
}: TypePageSubmitButtonProps) => {
  return (
    <Responsive
      desktop={<Desktop {...props}>{children}</Desktop>}
      mobile={
        <AvoidSnackbarOverlap>
          <Mobile {...props}>{children}</Mobile>
        </AvoidSnackbarOverlap>
      }
    />
  );
};

const Desktop = React.forwardRef<HTMLDivElement, TypePageSubmitButtonProps>(
  ({ children, extra, ...props }, ref) => {
    return (
      <div className={styles.desktopButtonContentContainer}>
        <div className={styles.desktopButtonContainer} ref={ref}>
          <BoxButton {...props} size="xlarge">
            {children}
          </BoxButton>
        </div>
        {extra && <div className={styles.extra}>{extra}</div>}
      </div>
    );
  },
);

const Mobile = React.forwardRef<HTMLDivElement, TypePageSubmitButtonProps>(
  ({ children, extra, ...props }, parentRef) => {
    const [buttonHeight, setButtonHeight] = React.useState(0);
    const ref = React.useRef<HTMLDivElement>();

    useLayoutEffect(() => {
      setButtonHeight(ref.current?.offsetHeight ?? 0);
    }, []);

    return (
      <>
        <div style={{ height: buttonHeight }} />
        <div
          className={clsx(
            styles.mobileButtonContainerRoot,
            styles.mobileButtonContainer,
          )}
          ref={(node) => {
            if (ref) {
              ref.current = node ?? undefined;
            }

            if (parentRef && typeof parentRef === "object") {
              parentRef.current = node;
            }

            if (typeof parentRef === "function") {
              parentRef(node);
            }
          }}
        >
          <BoxButton {...props} size="xlarge" width="100%">
            {children}
          </BoxButton>
          {extra && <div className={styles.extra}>{extra}</div>}
        </div>
      </>
    );
  },
);

export default PageSubmitButton;
