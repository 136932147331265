import { BoxButton, TextButton } from "../button";
import * as styles from "./error-result.css";

type Props = {
  actionText?: string;
  errorMessage: React.ReactNode;
  onAction?: () => void;
  onReset: () => void;
  resetText: string;
};

const ErrorResult = ({
  actionText,
  errorMessage,
  onAction,
  onReset,
  resetText,
}: Props) => {
  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        <div className={styles.errorMessage}>{errorMessage}</div>
        <BoxButton
          UNSAFE_className={styles.backButton}
          onClick={onReset}
          variant="secondary"
        >
          {resetText}
        </BoxButton>
        {actionText && onAction && (
          <TextButton
            UNSAFE_className={styles.actionButton}
            onClick={onAction}
            size="small"
            variant="secondary"
          >
            {actionText}
          </TextButton>
        )}
      </div>
    </div>
  );
};

export default ErrorResult;
