import { match } from "ts-pattern";

import { TimeSchedule } from "../graphql/__generated__/resolvers";

export const DAY_ARRAY: Array<{ label: string; value: keyof typeof DAY }> = [
  { label: "월", value: "monday" },
  { label: "화", value: "tuesday" },
  { label: "수", value: "wednesday" },
  { label: "목", value: "thursday" },
  { label: "금", value: "friday" },
  { label: "토", value: "saturday" },
  { label: "일", value: "sunday" },
];

export const DAY_VALUE_ARRAY = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
] as const;

export type DayValue = keyof typeof DAY;

export const DAY = {
  friday: "금요일",
  monday: "월요일",
  saturday: "토요일",
  sunday: "일요일",
  thursday: "목요일",
  tuesday: "화요일",
  wednesday: "수요일",
};

export const SCHEDULE_TYPE = ["NONE", "EVERYDAY", "EACHDAY"] as const;

export const SCHEDULE_START_TIME_ARRAY = [
  { label: "00:00", value: "00:00" },
  { label: "01:00", value: "01:00" },
  { label: "02:00", value: "02:00" },
  { label: "03:00", value: "03:00" },
  { label: "04:00", value: "04:00" },
  { label: "05:00", value: "05:00" },
  { label: "06:00", value: "06:00" },
  { label: "07:00", value: "07:00" },
  { label: "08:00", value: "08:00" },
  { label: "09:00", value: "09:00" },
  { label: "10:00", value: "10:00" },
  { label: "11:00", value: "11:00" },
  { label: "12:00", value: "12:00" },
  { label: "13:00", value: "13:00" },
  { label: "14:00", value: "14:00" },
  { label: "15:00", value: "15:00" },
  { label: "16:00", value: "16:00" },
  { label: "17:00", value: "17:00" },
  { label: "18:00", value: "18:00" },
  { label: "19:00", value: "19:00" },
  { label: "20:00", value: "20:00" },
  { label: "21:00", value: "21:00" },
  { label: "22:00", value: "22:00" },
  { label: "23:00", value: "23:00" },
];

export const SCHEDULE_END_TIME_ARRAY = [
  { label: "01:00", value: "01:00" },
  { label: "02:00", value: "02:00" },
  { label: "03:00", value: "03:00" },
  { label: "04:00", value: "04:00" },
  { label: "05:00", value: "05:00" },
  { label: "06:00", value: "06:00" },
  { label: "07:00", value: "07:00" },
  { label: "08:00", value: "08:00" },
  { label: "09:00", value: "09:00" },
  { label: "10:00", value: "10:00" },
  { label: "11:00", value: "11:00" },
  { label: "12:00", value: "12:00" },
  { label: "13:00", value: "13:00" },
  { label: "14:00", value: "14:00" },
  { label: "15:00", value: "15:00" },
  { label: "16:00", value: "16:00" },
  { label: "17:00", value: "17:00" },
  { label: "18:00", value: "18:00" },
  { label: "19:00", value: "19:00" },
  { label: "20:00", value: "20:00" },
  { label: "21:00", value: "21:00" },
  { label: "22:00", value: "22:00" },
  { label: "23:00", value: "23:00" },
  { label: "23:59", value: "23:59" },
];

export function SCHEDULE(
  schedule: Record<keyof typeof DAY, ReadonlyArray<TimeSchedule>>,
) {
  const schedules = Object.entries(schedule)
    .filter(([_day, times]) => times.length > 0)
    .slice()
    .sort(([d1, _t1], [d2, _t2]) => sorter[d1] - sorter[d2])
    .map(([day, times]) => {
      const dayTimes = times.map(TIME);
      return { day: DAY[day], time: dayTimes.join(", ") };
    });

  if (
    schedules.length === 7 &&
    schedules.every(({ time }) => time === schedules[0].time)
  ) {
    return [{ day: "매일", time: schedules[0].time }];
  }
  return schedules;
}

function TIME(t: Readonly<TimeSchedule>) {
  const time = {
    end: t.endAt.split(":").slice(0, 2).join(":"),
    start: t.startAt.split(":").slice(0, 2).join(":"),
  };

  return match(time)
    .with(
      {
        end: "23:59",
        start: "00:00",
      },
      () => "전체 시간",
    )
    .otherwise(() => `${time.start} - ${time.end}`);
}

const sorter = {
  friday: 5,
  monday: 1,
  saturday: 6,
  sunday: 7,
  thursday: 4,
  tuesday: 2,
  wednesday: 3,
};

export const DAY_SCHEDULE_TEXT = (
  data: { end: string; start: string }[],
  separator = "~",
  timeSeparator = "\n",
) => {
  const result = data.reduce(
    (current, next) => {
      if (current.length === 0) {
        current.push({
          end: next.end,
          start: next.start,
        });

        return current;
      }

      current.forEach((prevItem) => {
        if (prevItem.start >= next.start && prevItem.end <= next.end) {
          prevItem.start = next.start;
          prevItem.end = next.end;
          return;
        }

        if (
          prevItem.start >= next.start &&
          prevItem.start <= next.end &&
          prevItem.end >= next.end
        ) {
          prevItem.start = next.start;
          return;
        }

        if (
          prevItem.start <= next.start &&
          prevItem.end >= next.start &&
          prevItem.end <= next.end
        ) {
          prevItem.end = next.end;
          return;
        }

        if (prevItem.start <= next.start && prevItem.end >= next.end) {
          return;
        }

        current.push({ end: next.end, start: next.start });
      });

      return current;
    },
    [] as { end: string; start: string }[],
  );

  if (
    result.length === 1 &&
    result[0].start === "00:00" &&
    result[0].end === "23:59"
  ) {
    return "전체 시간";
  }

  return result
    .map((item) => `${item.start} ${separator} ${item.end}`)
    .join(timeSeparator);
};
