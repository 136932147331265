/**
 * @generated SignedSource<<8bc3983c2e5ad90a9fe017ee2bba104c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type liteAdInitKeywordByLiteAdId_LiteAdChanged$data = {
  readonly keywords: ReadonlyArray<string> | null | undefined;
  readonly " $fragmentType": "liteAdInitKeywordByLiteAdId_LiteAdChanged";
};
export type liteAdInitKeywordByLiteAdId_LiteAdChanged$key = {
  readonly " $data"?: liteAdInitKeywordByLiteAdId_LiteAdChanged$data;
  readonly " $fragmentSpreads": FragmentRefs<"liteAdInitKeywordByLiteAdId_LiteAdChanged">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "liteAdInitKeywordByLiteAdId_LiteAdChanged"
};

(node as any).hash = "ae5ad0d1fd98af4fd627460a32edde61";

export default node;
