export const commaize = (n: number | string): string => {
  return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const commaizeKRW = (n: number): string => {
  return commaize(n) + "원";
};

export const stripComma = (value: string): number => {
  return Number(value.replace(/,/g, ""));
};
