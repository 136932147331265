import {
  openInAppBrowser,
  openMinikarrotWebview,
} from "@daangn/webview-link-router";

import { IS_PRODUCTION } from "../../environment";
import { handleAppAction } from "../lib/webview";

export const formatUrlSearchParams = (params?: Record<string, string>) => {
  return params ? `?${new URLSearchParams(params).toString()}` : "";
};

export function setUrlSearchParams(key: string, value: string) {
  const searchParams = new URLSearchParams(window.location.search);
  searchParams.set(key, value);

  window.history.replaceState(
    null,
    "",
    `${window.location.pathname}?${searchParams.toString()}`,
  );
}

export const createWebviewLinkHandler = (
  url: string,
  options?: Partial<Parameters<typeof openMinikarrotWebview>[0]>,
) => {
  return () =>
    openMinikarrotWebview({
      navbar: false,
      stage: IS_PRODUCTION ? "production" : "alpha",
      url: url,
      ...options,
    });
};

export const createWebLinkHandler = (
  url: string,
  options?: { newTab?: boolean },
) => {
  return () => {
    if (options?.newTab) {
      window.open(url, "_blank");
      return;
    }

    window.location.href = url;
  };
};

export const createLinkHandler = (
  url: string,
  options?: { newTab?: boolean } & Partial<
    Parameters<typeof openMinikarrotWebview>[0]
  >,
) => {
  return handleAppAction({
    onBrowserAction: createWebLinkHandler(url, options),
    onWebviewAction: createWebviewLinkHandler(url, options),
  });
};

export const createNewBrowserLinkHandler = (url: string) => {
  return handleAppAction({
    onBrowserAction: () => window.open(url, "_blank"),
    onWebviewAction: () => {
      openInAppBrowser({
        stage: IS_PRODUCTION ? "production" : "alpha",
        url,
      });
    },
  });
};
