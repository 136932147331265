const primitiveSpacing = {
  "0": 0,
  "0px": 0,
  "10px": 10,
  "128px": 128,
  "12px": 12,
  "15px": 15,
  "16px": 16,
  "18px": 18,
  "1px": 1,
  "20px": 20,
  "24px": 24,
  "28px": 28,
  "2px": 2,
  "32px": 32,
  "36px": 36,
  "40px": 40,
  "44px": 44,
  "48px": 48,
  "4px": 4,
  "64px": 64,
  "6px": 6,
  "80px": 80,
  "8px": 8,
  "96px": 96,
};

export type Spacing = {
  [key in keyof typeof primitiveSpacing]: string;
};

export const spacing: Spacing = Object.entries(primitiveSpacing).reduce(
  (acc, [k, v]) => ({
    ...acc,
    [k]: `${v / 16}rem`,
  }),
  {} as Spacing,
);
