import { DAY_SCHEDULE_TEXT } from "../constants/schedule";
import { TimeSchedule } from "../graphql/__generated__/resolvers";

export function mergeTimeRangeIntersected(
  timeRange: { end: string; start: string },
  compareTimeRange: { end: string; start: string },
) {
  const isEndSameAsStart = timeRange.end === compareTimeRange.start;
  const isWithinCompareRange =
    timeRange.start >= compareTimeRange.start &&
    timeRange.end >= compareTimeRange.end &&
    timeRange.start <= compareTimeRange.end;
  const overlapsCompareStart =
    timeRange.start <= compareTimeRange.start &&
    timeRange.end >= compareTimeRange.start &&
    timeRange.end <= compareTimeRange.end;
  if (isEndSameAsStart || isWithinCompareRange || overlapsCompareStart) {
    return {
      end:
        timeRange.end >= compareTimeRange.end
          ? timeRange.end
          : compareTimeRange.end,
      start:
        timeRange.start >= compareTimeRange.start
          ? compareTimeRange.start
          : timeRange.start,
    };
  }
}

export function formatScheduleFormValues(
  values: { end: string; start: string }[],
) {
  return [...values]
    .slice()
    .sort((a, b) => (b.start >= a.start ? -1 : 1))
    .reduce(
      (acc, curr, index) => {
        if (acc.length === 0) {
          acc.push(curr);
          return acc;
        }

        const merged = mergeTimeRangeIntersected(acc[index - 1], curr);
        if (merged) {
          acc[index - 1] = merged;
          return acc;
        }

        acc.push(curr);
        return acc;
      },
      [] as { end: string; start: string }[],
    );
}
export function formatScheduleDisplayText(
  data: {
    friday: { end: string; start: string }[];
    monday: { end: string; start: string }[];
    saturday: { end: string; start: string }[];
    sunday: { end: string; start: string }[];
    thursday: { end: string; start: string }[];
    tuesday: { end: string; start: string }[];
    wednesday: { end: string; start: string }[];
  },
  options?: {
    separator?: string;
    timeSeparator?: string;
  },
) {
  return [
    data.monday.length > 0 && [
      "월요일",
      DAY_SCHEDULE_TEXT(
        data.monday,
        options?.separator,
        options?.timeSeparator,
      ),
    ],
    data.tuesday.length > 0 && [
      "화요일",
      DAY_SCHEDULE_TEXT(
        data.tuesday,
        options?.separator,
        options?.timeSeparator,
      ),
    ],
    data.wednesday.length > 0 && [
      "수요일",
      DAY_SCHEDULE_TEXT(
        data.wednesday,
        options?.separator,
        options?.timeSeparator,
      ),
    ],
    data.thursday.length > 0 && [
      "목요일",
      DAY_SCHEDULE_TEXT(
        data.thursday,
        options?.separator,
        options?.timeSeparator,
      ),
    ],
    data.friday.length > 0 && [
      "금요일",
      DAY_SCHEDULE_TEXT(
        data.friday,
        options?.separator,
        options?.timeSeparator,
      ),
    ],
    data.saturday.length > 0 && [
      "토요일",
      DAY_SCHEDULE_TEXT(
        data.saturday,
        options?.separator,
        options?.timeSeparator,
      ),
    ],
    data.sunday.length > 0 && [
      "일요일",
      DAY_SCHEDULE_TEXT(
        data.sunday,
        options?.separator,
        options?.timeSeparator,
      ),
    ],
  ].filter((item) => Boolean(item)) as string[][];
}

export function hasAllDaySameGroupSchedule(data: {
  friday: { end: string; start: string }[];
  monday: { end: string; start: string }[];
  saturday: { end: string; start: string }[];
  sunday: { end: string; start: string }[];
  thursday: { end: string; start: string }[];
  tuesday: { end: string; start: string }[];
  wednesday: { end: string; start: string }[];
}) {
  const entries = Object.entries(data);
  const formattedData = entries
    .map(([key, value]) => {
      return [key, DAY_SCHEDULE_TEXT(value, "-")];
    })
    .filter((item): item is string[] => Boolean(item));

  return (
    Array.from(new Set(formattedData.map(([, value]) => value))).length === 1
  );
}

export function fromTimeShceduleToHHmm(t: TimeSchedule) {
  return {
    end: t.endAt.split(":").slice(0, 2).join(":"),
    start: t.startAt.split(":").slice(0, 2).join(":"),
  };
}
