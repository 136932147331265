/**
 * @generated SignedSource<<c4e65062087f9d46d68a5d63b8d66f76>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ExternalSourceTypeEnum = "BUSINESS_POST" | "BUSINESS_PROFILE" | "CLASS" | "COMMERCE" | "FARM" | "REALTY" | "TOWN_ARTICLE" | "TOWN_JOB";
export type liteAdContentInitQuery$variables = {
  advertiserId: CustomScalars["GraphQLIDInput"];
  externalSourceId: CustomScalars["GraphQLIDInput"];
  externalSourceType: ExternalSourceTypeEnum;
};
export type liteAdContentInitQuery$data = {
  readonly externalSource: {
    readonly description: string | null | undefined;
    readonly id: string;
    readonly relateToDeliberationCode: {
      readonly isRequired: boolean;
      readonly isTarget: boolean;
    } | null | undefined;
    readonly thumbnail: string;
    readonly title: string;
  };
};
export type liteAdContentInitQuery = {
  response: liteAdContentInitQuery$data;
  variables: liteAdContentInitQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "advertiserId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "externalSourceId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "externalSourceType"
  }
],
v1 = {
  "kind": "Variable",
  "name": "advertiserId",
  "variableName": "advertiserId"
},
v2 = {
  "alias": null,
  "args": [
    (v1/*: any*/),
    {
      "kind": "Variable",
      "name": "externalSourceId",
      "variableName": "externalSourceId"
    },
    {
      "kind": "Variable",
      "name": "externalSourceType",
      "variableName": "externalSourceType"
    }
  ],
  "concreteType": "ExternalSource",
  "kind": "LinkedField",
  "name": "externalSource",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thumbnail",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        (v1/*: any*/)
      ],
      "concreteType": "ExternalSourceRelateToDeliberationCode",
      "kind": "LinkedField",
      "name": "relateToDeliberationCode",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isRequired",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isTarget",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "liteAdContentInitQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": (v2/*: any*/),
        "action": "THROW",
        "path": "externalSource"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "liteAdContentInitQuery",
    "selections": [
      (v2/*: any*/)
    ]
  },
  "params": {
    "cacheID": "9a3c19f54e85f8514f6dd641bbe8c9a5",
    "id": null,
    "metadata": {},
    "name": "liteAdContentInitQuery",
    "operationKind": "query",
    "text": "query liteAdContentInitQuery(\n  $advertiserId: GraphQLIDInput!\n  $externalSourceId: GraphQLIDInput!\n  $externalSourceType: ExternalSourceTypeEnum!\n) {\n  externalSource(advertiserId: $advertiserId, externalSourceId: $externalSourceId, externalSourceType: $externalSourceType) {\n    id\n    title\n    thumbnail\n    description\n    relateToDeliberationCode(advertiserId: $advertiserId) {\n      isRequired\n      isTarget\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "83a3821e8f7be4e53434cd186713f7c5";

export default node;
