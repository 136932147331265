import { rem } from "polished";
import * as React from "react";

import { AlertDialog } from ".";

type ModalState = Pick<
  React.ComponentProps<typeof AlertDialog>,
  "description" | "primary" | "secondary" | "title"
>;

const DialogContext = React.createContext<{
  setModal: ((state: ModalState) => void) | null;
}>({
  setModal: null,
});

function DialogProvider({ children }: { children: React.ReactNode }) {
  const [modal, setModal] = React.useState<ModalState | null>();

  return (
    <DialogContext.Provider value={{ setModal }}>
      {children}
      <AlertDialog
        description={modal?.description ?? ""}
        onClose={() => setModal(null)}
        open={Boolean(modal)}
        primary={{ label: "" }}
        width={rem(272)}
        {...modal}
      />
    </DialogContext.Provider>
  );
}

export function useAddDialog() {
  const ctx = React.useContext(DialogContext);

  if (!ctx.setModal) {
    throw new Error("useDialog should be used below DialogProvider");
  }

  return ctx.setModal;
}

export default DialogProvider;
