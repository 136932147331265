import * as styles from "./page-title.css";

type TypePageTitleProps = {
  children: React.ReactNode;
  extra?: React.ReactNode;
};

const PageTitle = ({ children, extra }: TypePageTitleProps) => {
  return (
    <h1 className={styles.title}>
      {extra && <div className={styles.extraContainer}>{extra}</div>}
      {children}
    </h1>
  );
};

export default PageTitle;
