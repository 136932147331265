import { StateCreator } from "zustand";

import {
  ExternalSourceTypeEnum,
  TargetRegionEnum,
} from "../../graphql/__generated__/resolvers";
import useBusinessUserStore from "../business-user";
import { LiteAdInitialStatus, TypeCreateLiteAdStore } from "./lite-ad";
import liteAdMapper from "./lite-ad.mapper";
import { TypeLiteAdRegionFormSchema } from "./lite-ad.schema";
import liteAdService, {
  TypeLiteAdServiceMethodReturn,
} from "./lite-ad.service";

type Region = {
  childrenCount?: null | number;
  fullName?: null | string;
  id: string;
  level?: null | number;
  name?: null | string;
  name1?: null | string;
  name2?: null | string;
};

export type TypeRegionState = {
  baseRegionId?: null | string;
  baseRegionName?: null | string;
  /**
   * RANGE region 값
   */
  nearRegions: Region[];
  /**
   * 반경타겟팅 정보
   */
  radiusInfo?: {
    address?: null | string;
    distance?: null | number;
    latitude?: null | number;
    longitude?: null | number;
    supported?: boolean | null;
  } | null;
  /**
   * RANGE slider 값
   */
  regionDepth: number;
  /**
   * 초기 region 값을 가져왔는지 여부
   */
  regionInitialStatus: LiteAdInitialStatus;
  /**
   * target 페이지에서 region 페이지로 Push 실행 여부
   */
  regionPushed: boolean;
  regionType: TargetRegionEnum;
  /**
   * SELECT region 값
   */
  regions: Region[];
};

export type TypeRegionAction = {
  regionExternalSourceInit: (
    externalSourceId: string,
    externalSourceType: ExternalSourceTypeEnum,
  ) => Promise<void>;
  regionLiteAdInit: (
    query: TypeLiteAdServiceMethodReturn<"initStoreByLiteAdId">,
  ) => void;
  regionOutlinkInit: () => void;
  regionPageEnter: () => void;
  regionSetStatus: (status: LiteAdInitialStatus) => void;
  submitRegionForm: (state: TypeLiteAdRegionFormSchema) => void;
};

export const regionInitialState: TypeRegionState = {
  baseRegionId: null,
  baseRegionName: "",
  nearRegions: [],
  radiusInfo: null,
  regionDepth: 1,
  regionInitialStatus: "initial",
  regionPushed: false,
  regions: [],
  regionType: "SELECT",
};

export const createRegionSlice: StateCreator<
  TypeCreateLiteAdStore,
  [["zustand/devtools", never]],
  [],
  TypeRegionState & TypeRegionAction
> = (_, get) => ({
  ...regionInitialState,
  regionExternalSourceInit: async (externalSourceId, externalSourceType) => {
    const { placement, regionIds, regionSetStatus, setState } = get();
    const { getCurrentAdvertiser } = useBusinessUserStore.getState();
    const advertiser = getCurrentAdvertiser();

    regionSetStatus("loading");
    const { baseRegionQuery, regionByIdsQuery, regionQuery } =
      await liteAdService.initRegionByExternalSource({
        advertiserId: advertiser.id,
        externalSourceId,
        externalSourceType,
        placement,
        regionIds,
      });

    setState(
      liteAdMapper.toStateRegionExternalSourceInit(
        placement,
        baseRegionQuery,
        regionQuery,
        regionByIdsQuery,
      ),
      "regionExternalSourceInit",
    );
    regionSetStatus("success");
  },
  regionLiteAdInit: (query) => {
    const { regionSetStatus, setState } = get();
    regionSetStatus("loading");
    const response = liteAdService.initRegionByLiteAdId(query);
    setState(
      liteAdMapper.toStateRegionLiteAdInit(response),
      "regionLiteAdInit",
    );
    regionSetStatus("success");
  },
  regionOutlinkInit: async () => {
    const { regionSetStatus, setState } = get();
    regionSetStatus("loading");
    const response = await liteAdService.initRegionByUser();
    setState(
      {
        baseRegionId: response?.karrotUser?.baseRegion?._id,
        baseRegionName: response?.karrotUser?.baseRegion?.name,
        regionType: "ALL",
      },
      "regionOutlinkInit",
    );
    regionSetStatus("success");
  },
  regionPageEnter: () => {
    const { setState } = get();
    setState({ regionPushed: true }, "regoinPageEntered");
  },
  regionSetStatus: (status) => {
    const { setState } = get();
    setState({ regionInitialStatus: status }, "regionSetStatus");
  },
  submitRegionForm: (data) => {
    const { setState } = get();
    setState(data, "submitRegionForm");
  },
});
