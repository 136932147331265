import * as Sentry from "@sentry/react";
import { AppScreen, AppScreenProps } from "@stackflow/plugin-basic-ui";
import * as React from "react";
import { P, match } from "ts-pattern";

import { usePopToRoot } from "@/src/stackflow";

import ErrorResult from "../error-result";
import Responsive from "../responsive";
import Spinner from "../spinner";
import * as styles from "./page-layout.css";
import PageSubmitButton from "./page-submit-button";
import PageTitle from "./page-title";

const Root = (props: React.PropsWithChildren<AppScreenProps>) => {
  return (
    <Responsive
      desktop={<Desktop {...props} />}
      mobile={<Mobile {...props} />}
    />
  );
};

const Desktop = (props: React.PropsWithChildren<AppScreenProps>) => {
  const { appBar, children, ...rest } = props;
  return (
    <AppScreen {...rest}>
      <div className={styles.root}>{children}</div>
    </AppScreen>
  );
};

const Mobile = ({
  children,
  ...props
}: React.PropsWithChildren<AppScreenProps>) => {
  return (
    <AppScreen
      {...props}
      appBar={match(props.appBar)
        .with(P.nullish, () => undefined)
        .otherwise(() => ({
          ...props.appBar,
          renderRight: () => (
            <div className={styles.mobileRenderRightContainer}>
              {props.appBar?.renderRight?.()}
            </div>
          ),
        }))}
    >
      <div className={styles.root}>
        <div className={styles.scrollContainer}>{children}</div>
      </div>
    </AppScreen>
  );
};

const Loading = ({ lazy = true }: { lazy?: boolean }) => {
  const [show, setShow] = React.useState(lazy);

  React.useEffect(() => {
    if (show) {
      return;
    }

    // 초기 로딩은 300ms 이후에만 보여주도록 해요
    const timeout = setTimeout(() => setShow(true), 300);
    return () => clearTimeout(timeout);
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <div className={styles.loadingContainer}>
      <Spinner />
    </div>
  );
};

const Basic = ({
  children,
  fallback = <PageLayout.Loading />,
  loading = false,
  ...props
}: React.PropsWithChildren<AppScreenProps> & {
  fallback?: React.ReactNode;
  loading?: boolean;
}) => {
  const popToRoot = usePopToRoot();

  return (
    <Root {...props}>
      <Sentry.ErrorBoundary
        beforeCapture={(scope) => {
          scope.setTag("location", "page-layout.basic");
        }}
        fallback={({ resetError }) => {
          return (
            <ErrorResult
              errorMessage={
                "일시적으로 오류가 발생했어요.\n잠시 후 다시 시도해주세요."
              }
              onReset={() => {
                resetError();
                popToRoot();
              }}
              resetText="새로고침"
            />
          );
        }}
      >
        <React.Suspense fallback={fallback}>
          {match({ loading })
            .with({ loading: true }, () => fallback)
            .otherwise(() => children)}
        </React.Suspense>
      </Sentry.ErrorBoundary>
    </Root>
  );
};

export const PageLayout = {
  Basic,
  Loading,
  SubmitButton: PageSubmitButton,
  Title: PageTitle,
};
