import { TypeMeta } from "@/src/stackflow/event-plugins";

export const pageMeta: TypeMeta<"advertisements/:liteAdId"> = {
  event: {
    activityAlias: "detail_v1",
    eventParams: async ({ params }, commonEventParams, loaders) => {
      const variables = {
        advertiserId: commonEventParams.advertiser_query_id!,
        liteAdId: params.liteAdId!,
      };

      const [ad_id, business_account_id, post_id] = await Promise.all([
        loaders.liteAd.getLiteAdRealAdId(variables),
        loaders.liteAd.getLiteAdSourceOwnerId(variables),
        loaders.liteAd.getLiteAdMaterialSourceId(variables),
      ]);

      return {
        ad_id,
        advertiser_id: commonEventParams.advertiser_id,
        business_account_id,
        business_user_id: commonEventParams.business_user_id,
        post_id,
      };
    },
  },
};
